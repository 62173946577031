import styled from 'styled-components';

const ComponentWrapper = styled.div`

@media print {    
    display: none;  
}
`;

export default ComponentWrapper;
