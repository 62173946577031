import styled from 'styled-components';

const Wrapper = styled.div`
    @page {size: landscape}

    //background-color: grey;

    --lh: 1.4rem;
  --shf: 4.9406564584124654e-322;
  --max-lines: calc(100vh/var(--lh) );
  //  --max-lines: 3;
  
    display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical; 

`;

export default Wrapper;