import styled from 'styled-components';

const Shared = styled.div`
//margin: 0 8px 0 8px;
//width: ${props => props.screenWidth};
margin: 0 12px 0 4px;
flex: 1 1 auto;
//width: 33%;
 // -ms-flex: 33%; /* IE 10 */
 //flex: 30%;
/*
 @media screen and (max-width: 70px) {  
    width: 100%;  
}

 @media screen and (min-width: 70px) {  
    //width: 30%;
    flex: 31%;
}
*/
//DEV
//background-color: magenta;
width: ${props => props.boxColWidth ? props.boxColWidth : "30%"};

//max-width: 26.78vw;
`;

const BoxColumn = styled(Shared)`
display: ${props => props.show ? "block" : "none"};
`;

export const HiddenBoxColumn = styled(Shared)`
display: none;
`;

export default BoxColumn;