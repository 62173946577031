import React, { useState, useEffect } from "react";
//import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { SetBoxTitle } from '../../actions'

import Wrapper from "./Wrapper";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import BoxNotifier from "../BoxNotifier";

const BoxTitleV2 = React.memo((props) => {
    //Console
    //console.log("BoxTitleV2 rendered");

    //Props
    const { boxId } = props;

    //Selectors
    const selectBox = state => state.entities.boxes.byId[boxId];
    const selectBoxTitle = createSelector(
        selectBox,
        box => {
            return box ? box.title : "";
        }
    );

    //Use
    const dispatch = useDispatch();
    //const history = useHistory();

    //Consts
    const boxTitle = useSelector(selectBoxTitle);

    //State
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(boxTitle || ""))));

    //Keep state in sync
    /*
    useEffect(() => {
        if (editorState !== boxTitle)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(boxTitle))));
    }, [boxTitle]);
    */

    //Handlers
    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        let newBoxTitle = draftToHtml(convertToRaw(editorState.getCurrentContent())).toString().replace("<p>", "").replace("</p>", "");
        dispatch(SetBoxTitle(boxId, newBoxTitle));
    };

    return (
        <Wrapper print>
            <BoxNotifier />
            <Editor
                toolbarClassName="demo-toolbar-absolute"
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                defaultEditorState={boxTitle}
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
                toolbarHidden
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                        bold: { className: 'bordered-option-classname' },
                        italic: { className: 'bordered-option-classname' },
                        underline: { className: 'bordered-option-classname' },
                        strikethrough: { className: 'bordered-option-classname' },
                        code: { className: 'bordered-option-classname' },
                    },
                    blockType: {
                        className: 'bordered-option-classname',
                    },
                    fontSize: {
                        className: 'bordered-option-classname',
                    },
                    fontFamily: {
                        className: 'bordered-option-classname',
                    },
                }}
            />
        </Wrapper>
    );
});

export default BoxTitleV2;