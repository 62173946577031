import { combineReducers } from 'redux'

import a3s from './a3s'
import todos from './todos'
import boxes from './boxes'
import views from './views'
import contents from './contents'
import metas from './metas'
import pages from './pages'
import layouts from './layouts'
import files from './files'
import pagecontents from './pagecontents'
import viewpages from './viewpages'
import boxviews from './boxviews'
import boxviewpages from './boxviewpages'

export default combineReducers({
    a3s,
    metas,
    boxes,
    boxviews,
    boxviewpages,
    views,
    viewpages,
    pages,
    pagecontents,
    contents,

    layouts,
    files,
    todos,
})
