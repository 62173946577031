import React, { Component } from 'react';
import { IntlProvider } from 'react-intl'
import { createIntl, createIntlCache } from 'react-intl'
import messages_enUS from "./lang/en-US.json";
//import messages_nlBE from "./lang/nl-BE.json";

const cache = createIntlCache()
const intl = createIntl(
    {
        locale: 'nl',
        defaultLocale: 'en',
        messages: messages_enUS,
    },
    cache
)

class MetaHeader extends Component {
    constructor(props) {
        super(props);

        //console.log("MetaHeader - constructor called");
        this.meta = this.props.meta;        
    }

    handleMetaChange(i, e) {
        //console.log("MetaHeader - handleMetaChange called");
        var newMeta = this.meta;
        newMeta[i].value = e.target.value;

        //console.log(newMeta);
        this.props.onMetaChange(JSON.stringify(newMeta));
    }

    render() {
        //console.log("MetaHeader - render called");
        this.meta = this.props.meta;

        return (this.meta === undefined ? null :
            <IntlProvider messages={messages_enUS} locale="nl" defaultLocale="en">
                <div className="metaheader">
                    {
                        this.meta.map((meta, i) => {
                            if (i > 0) return null;

                            if (meta.value === "" && this.props.optimiseForPint)
                                return null;

                            return (
                                <div key={i} className="metaheaderBlock">
                                    <label className="metaheaderLabel">{intl.formatMessage({ id: meta.label })}</label>
                                    {this.props.optimiseForPint ?
                                        <label className="metaheaderValue">{meta.value}</label>
                                        :
                                        <input
                                            i={i}
                                            className={meta.label.includes("titleLabel") ? "metaheaderInputTitle" : "metaheaderInput"}
                                            type="text"
                                            placeholder={intl.formatMessage({ id: meta.placeholder })}
                                            value={meta.value}
                                            onChange={(e) => this.handleMetaChange(i, e)}
                                            style={{ width: (meta.value.length + 1) * 6.25 + 'px' }}
                                        />
                                    }
                                </div>
                            );
                        })
                    }
                </div>
                <div className="metaheader">
                    {
                        this.meta.map((meta, i) => {
                            if (i <= 0) return null;

                            if (meta.value === "" && this.props.optimiseForPint)
                                return null;

                            return (
                                <div key={i} className="metaheaderBlock">
                                    <label className="metaheaderLabel">{intl.formatMessage({ id: meta.label })}</label>
                                    {this.props.optimiseForPint ?
                                        <label className="metaheaderValue">{meta.value}</label>
                                        :
                                        <input
                                            i={i}
                                            className={meta.label.includes("titleLabel") ? "metaheaderInputTitle" : "metaheaderInput"}
                                            type="text"
                                            placeholder={intl.formatMessage({ id: meta.placeholder })}
                                            value={meta.value}
                                            onChange={(e) => this.handleMetaChange(i, e)}
                                            style={{ width: (meta.value.length + 1) * 8 + 'px' }}
                                        />
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </IntlProvider >
        );
    }
} 

export default MetaHeader;