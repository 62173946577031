import styled from 'styled-components';

const ContainerWrapper = styled.div`
    width: 250px;
    height: 200px;
    
    padding: 5px;     
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
    border: black;

    //Development
    background: lightgrey; 
`;

export default ContainerWrapper;
