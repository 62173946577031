import React, { useState, useEffect } from "react";
//import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { SetBoxGo } from '../../actions'

import ToggleContainer from "./ToggleContainer";
import ToggleButtonNoGo, { ToggleButtonGo } from "./ToggleButton";
import BoxNotifier from "../BoxNotifier";
import Wrapper from './Wrapper';

const GoNoGoToggleButton = React.memo((props) => {
    //Props
    const { boxId } = props;

    //Console
    //console.log("GoNoGoToggleButton rendered", boxId);

    //Selectors
    const selectBox = state => state.entities.boxes.byId[boxId];
    const selectBoxGo = createSelector(
        selectBox,
        box => {
            return box ? box.go : false;
        }
    );

    //Use
    const dispatch = useDispatch();
    //const history = useHistory();

    //Consts
    const boxGo = useSelector(selectBoxGo);

    //State
    const [stateGo, setStateGo] = useState(boxGo);

    //Keep state in sync
    useEffect(() => {
        if (stateGo !== boxGo)
            setStateGo(boxGo);
    }, [boxGo]);

    //Handlers
    function handleToggleGo() {
        setStateGo(!stateGo);
        dispatch(SetBoxGo(boxId, !boxGo));
    }

    return (
        <Wrapper>
            <BoxNotifier />
            <ToggleContainer onClick={handleToggleGo}>
                {stateGo ?
                    <ToggleButtonGo>Go</ToggleButtonGo> :
                    <ToggleButtonNoGo>No Go</ToggleButtonNoGo>

                }
            </ToggleContainer>
        </Wrapper>
    );
});

export default GoNoGoToggleButton;