import styled from 'styled-components';

const Wrapper = styled.div`

    //Make placeholder data appear
    [placeholder]:empty::before {
        content: attr(placeholder);
        color: lightblue; 
    }

    //Remove the placeholder as soon as the focus is set
    //[placeholder]:empty:focus::before {
    //    content: "";
    //}

    .edit {
        height: 98%;
        width: 98.5%;
        cursor: text;
        outline: -webkit-focus-ring-color auto 0px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 0px;
        overflow: hidden;

        //background-color: green;
    }
    .edit:active{
        cursor: move;
    }

    .edit:focus {
        //background-color: lightgray;
    }

@media screen { 
    border: dotted 1pt;
    position: relative;
    width: 1191pt;
    //width: 1440px;
    height: 842pt;
    //height: 748px;
    //zoom: calc((1191*0.99)/${props => props.screenWidth});
    //zoom: calc((842*0.99)/${props => props.screenHeight});    
    zoom: calc(${props => props.zoom});
    //zoom: 0.65; //${props => props.zoom};
}

@media print { 

    @page {
        size: A3 landscape
    }

    display: block;
    padding: 8px !important;
    height: 99vh;
    width: 99vw;

    visible: visible;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

`;

export default Wrapper;

/*
    visible: visible;
    -webkit-print-color-adjust: exact !important;
color - adjust: exact!important;
*/

/*
@media print {
    @page {
        size: A3 landscape
    }

    width: 1191pt;
    height: 842pt;
}
*/

/*
@media print {@page {size: A3 landscape} body { zoom: 90%; width:98%; height: 98%; overflow: hidden; font-size: 12pt; } h1 { font-size: 16pt; } h2 { font-size: 14pt; } }
*/