import styled from 'styled-components';

const BoxWrapper = styled.div`

display: ${props => props.show ? "block" : "none"};

@media screen {
    //width: ${props => props.boxWidth ? props.boxWidth : "450px"};
    min-width: 22.75vw;
    zoom: ${props => props.zoom ? props.zoom : 1};
    //ax-width: ${props => props.boxWidth};
}

//background-color: purple;
//padding: 0px 0px 16px 0px;
//width: 842pt;
//height: 595pt;

@media print {     
    height: 32.75vh;    
}


`;

export default BoxWrapper;

/*
@media print {
    width: 842pt;
    height: 595pt;
    //padding: 8pt;

    @page {
        size: A4 landscape;
        margin: 0.5cm;
    }
}

@media print {

    @page {
        size: A4 landscape;
        margin: 0.5cm;
    }
}

*/