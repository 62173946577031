import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: []
};

const views = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.SET_A3_NORMALIZED:
        return produce(state, draft => {
          draft.byId = {
            ...draft.byId, ...action.payload.views.byId
          };
          draft.allIds = [...new Set([...draft.allIds, ...action.payload.views.allIds])];
        });

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

      default:
        break;
    }
  });
}

export default views