import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Wrapper from './Wrapper';
import { selectCurrent } from '../../Selectors';
import OverviewContainerWrapper from './OverviewContainerWrapper';
import A3MugshotV2 from '../A3MugshotV2';
import { newA3, setA3id, getA3sFromApi, setCurrentA3, createEmptyA3 } from '../../actions'
import { Button, Grid } from '@material-ui/core';
import Title from './Title'
import SubTitle from './SubTitle'
import InputSlider from './InputSlider'
import FilterWrapper from './FilterWrapper'
import Left from './Left'
import Right from './Right'

import LogoutButton from '../LogoutButton';
import SignupButton from '../SignupButton';
import LoginButton from '../LoginButton';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import SearchBar from "material-ui-search-bar";

import { withStyles, makeStyles } from '@material-ui/core/styles';


function A3sV2(props) {
    //Props
    const { a3Id } = props;

    //Use
    const dispatch = useDispatch();
    const history = useHistory();

    //Selectors            
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth.user);
    const a3s = useSelector(state => state.entities.a3s);

    //const selectCurrentSelector = useMemo(makeSelectCurrentSelector)
    const current = useSelector(selectCurrent);

    //State    
    //const [boxTitle, setBoxTitle] = useState(props.boxTitle);
    //const [goNoGoSelected, setGoNoGoSelected] = useState(props.go);

    //Handlers    
    //TODO - Change from a history.push to a dispatch focusOnA3()
    const handleClickedA3 = (uuid) => {
        //dispatch(setA3id(uuid));
        //history.push("/A3/".concat(uuid));
    }

    const handleClickedNewA3 = () => {
        const { v4: uuidv4 } = require('uuid');
        const a3Id = uuidv4();
        //dispatch(setA3id(a3Id));
        //dispatch(newA3());

        //history.push("/A3/".concat(a3Id));
        dispatch(createEmptyA3(a3Id));
        dispatch(setCurrentA3(a3Id));
        history.push("/a3");
    }

    const handleClickedNewA3WithMockData = () => {
        const a3Id = "a3Id1";
        dispatch(setCurrentA3(a3Id));
        history.push("/mocka3");
    }

    const [searchValue, setSearchValue] = React.useState("");



    //Retrieve the A3s when this page loads and when the user logs in
    useEffect(() => {
        dispatch(getA3sFromApi());
    }, [user]);

    if (!isAuthenticated)
        return (
            <Wrapper>
                <Title>A3 app</Title>
                <br />
                <br />
                <label>Login or signup to get started!</label>
                <br />
                {isAuthenticated ? <div><LogoutButton /></div> : <div><LoginButton /> <SignupButton /></div>}
            </Wrapper >
        );
    return (
        <Wrapper>
            <Title>A3 Overview</Title>
            <SubTitle>Filter</SubTitle>

            <FilterWrapper>
                <Left>
                    <InputSlider />
                </Left>
                <Right>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <SearchBar
                        style={{ width: "300px", height: "30px" }}
                        value={searchValue}
                        onChange={(newValue) => setSearchValue(newValue)}
                    //onRequestSearch={() => doSomethingWith(this.state.value)}
                    />
                </Right>

            </FilterWrapper>

            <SubTitle>A3</SubTitle>
            <OverviewContainerWrapper>
                {isAuthenticated && a3s ?
                    a3s.allIds.map((id) => {
                        let a3 = a3s.byId[id];

                        if (!a3.title.toLowerCase().includes(searchValue.toLowerCase()))
                            return null;

                        if (a3.type !== "A3")
                            return null;

                        return (
                            <div
                                key={id}
                            //onClick={() => handleClickedA3(id)}
                            >
                                <A3MugshotV2
                                    key={id}
                                    a3Id={id}
                                    name={a3.title || a3.name}
                                //imageuuid="99ba0d5-0107-126f-156a-2d583c4b199"
                                />
                            </div>
                        )
                    })
                    : null
                }
            </OverviewContainerWrapper>

            <SubTitle>Template</SubTitle>
            <OverviewContainerWrapper>
                {isAuthenticated && a3s ?
                    a3s.allIds.map((id) => {
                        let a3 = a3s.byId[id];

                        if (!a3.title.toLowerCase().includes(searchValue.toLowerCase()))
                            return null;

                        if (a3.type !== "TEMPLATE")
                            return null;

                        return (
                            <div
                                key={id}
                            //onClick={() => handleClickedA3(id)}
                            >
                                <A3MugshotV2
                                    key={id}
                                    a3Id={id}
                                    name={a3.title || a3.name}
                                //imageuuid="99ba0d5-0107-126f-156a-2d583c4b199"
                                />
                            </div>
                        )
                    })
                    : null
                }
            </OverviewContainerWrapper>
        </Wrapper >
    );

}
export default A3sV2;


/*

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { newA3, setA3id, getA3sFromApi } from '../../actions'

import A3Mugshot from '../../components/A3Mugshot';
import "./Overview.css";
import OverviewContainerWrapper from './OverviewContainerWrapper';

const Overview = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    //const { user, isAuthenticated } = useAuth0();

    const user = useSelector(state => state.auth.user);
    const a3s = useSelector(state => state.a3s);

    //Retrieve the A3s when this page loads and when the user logs in
    useEffect(() => {
        dispatch(getA3sFromApi());
    }, [user]);


    //TODO - Change from a history.push to a dispatch focusOnA3()
    const handleA3Clicked = (uuid) => {
        //dispatch(setA3id(uuid));
        //history.push("/A3/".concat(uuid));
    }

    const handleClickNewA3 = () => {
        const { v4: uuidv4 } = require('uuid');
        const a3Id = uuidv4();
        dispatch(setA3id(a3Id));
        dispatch(newA3());

        history.push("/A3/".concat(a3Id));
    }

    return (
        <div>
            <h1>A3 Overview</h1>
            <div onClick={() => handleClickNewA3()} >
                <button>New A3</button>
            </div>
            <OverviewContainerWrapper>
                {a3s ?
                    a3s.allIds.map((id) => {
                        let a3 = a3s.byId[id];

                        return (
                            <div
                                key={id}
                                onClick={() => handleA3Clicked(id)} >
                                <A3Mugshot
                                    key={id}
                                    a3Id={id}
                                    name={a3.name}
                                //imageuuid="99ba0d5-0107-126f-156a-2d583c4b199"
                                />
                            </div>
                        )
                    })
                    : null
                }
            </OverviewContainerWrapper>
        </div>
    );
}

export default Overview;


*/
