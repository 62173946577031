import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import LayoutToPrint from './LayoutToPrint';


import { FaFileDownload, FaFolderOpen, FaSave, FaPrint, FaBook } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

import { useAuth0 } from "@auth0/auth0-react";
import { saveA3ToApi, saveTemplateToApi, saveA3Image, setA3 } from './actions';

const handleSaveToPC = (jsonData, files) => {
	const fileData = JSON.stringify({
		...jsonData,
		files: files
	});
	//const fileData = firstData.files = files;
	const blob = new Blob([fileData], { type: "text/plain" });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	const filename = jsonData.meta[0].value || "a3";
	link.download = filename.concat(".json");
	link.href = url;
	link.click();
}

const Header = (props) => {
	const dispatch = useDispatch();

	const files = useSelector(state => state.a3.files);
	const fileInput = useRef(null);
	const componentRef = useRef();

	const handleLoadFromPC = () => {
		//console.log("handleLoadFromPC called");
		fileInput.current.click();
	}

	const onChangeFile = (event) => {
		event.stopPropagation();
		event.preventDefault();

		if (event && event.target && event.target.files && event.target.files[0]) {
			let file = event.target.files[0];
			var reader = new FileReader();
			reader.readAsText(file);
			reader.onload = function () {
				var result = reader.result;

				//Remove the files from the result
				let a3 = JSON.parse(result);
				if (a3 && a3.files && a3.files.length) {
					for (var i = 0; i <= a3.files.length; i++) {
						if (a3.files[i])
							dispatch(saveA3Image(a3.files[i].uuid, a3.files[i].data))
					}

					delete a3.files;
				}

				dispatch(setA3(JSON.stringify(a3)));
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
			};

		}
	}

	const handleSaveA3ToApi = () => {
		dispatch(saveA3ToApi())
	}

	const handleSaveTemplateToApi = () => {
		dispatch(saveTemplateToApi())
	}

	const { isAuthenticated } = useAuth0();

	return (
		<div className="row">
			<div>
				<IconContext.Provider value={{ color: '#98c83b' }} >

					<FaFileDownload style={{ width: '35px', height: '35px', padding: '3px 3px 3px 3px' }} onClick={() => handleSaveToPC(props.data, files)} />

					<FaFolderOpen style={{ width: '35px', height: '35px', padding: '3px 3px 3px 3px' }} onClick={() => handleLoadFromPC()} />
					<input type="file" id="file" ref={fileInput} style={{ display: "none" }} onChange={(event) => onChangeFile(event)} />

					{isAuthenticated ? <FaSave style={{ width: '35px', height: '35px', padding: '3px 3px 3px 3px' }} onClick={() => handleSaveA3ToApi()} /> : null}
					{isAuthenticated ? <FaBook style={{ width: '35px', height: '35px', padding: '3px 3px 3px 3px' }} onClick={() => handleSaveTemplateToApi()} title="Save template" /> : null}

					<ReactToPrint
						trigger={() => {
							// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
							// to the root node of the returned component as it will be overwritten.
							return <FaPrint style={{ width: '35px', height: '35px', padding: '3px 3px 3px 3px' }} />;
						}}
						content={() => componentRef.current}
					/>

					<div style={{ display: "none" }}>
						<LayoutToPrint data={props.data} ref={componentRef} />
					</div>

				</IconContext.Provider>
			</div>
		</div>
	);
}

export default Header;
