import styled from "styled-components";

export default styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;

   img {    
    width: auto;
    vertical-align: bottom;
}
`;

