import styled from 'styled-components';

const PopupWrapper = styled.div`
    margin: auto;
    width: 50%;    
    padding: 10px;
    text-align: center;

    //Development
    //background: green;
`;

export default PopupWrapper;
