import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Wrapper from './Wrapper';
import { selectCurrent } from '../../Selectors';
import BoxGridV2 from '../BoxGridV2';


function A3V2(props) {
    //Props
    const { a3Id, screenWidth, screenHeight } = props;

    //Use
    const dispatch = useDispatch();
    const history = useHistory();

    //Selectors    
    const current = useSelector(selectCurrent);

    //Var & Const
    let a3IdToDisplay = a3Id ? a3Id : current.a3Id

    //Handlers    
    document.documentElement.style.setProperty("--myfontsize", current.fontSizeCKEditor)

    //Check
    /*
    console.log("A3V2 called");
    console.log("a3Id", a3Id);
    console.log("current.a3Id", current.a3Id);
    console.log("a3IdToDisplay", a3IdToDisplay);
    */

    return (
        <Wrapper>
            <BoxGridV2
                a3Id={a3IdToDisplay}
                screenWidth={screenWidth}
                screenHeight={screenHeight}
            />
        </Wrapper>
    );

}
export default A3V2;
