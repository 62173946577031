import styled from 'styled-components';

const MetaTitleContent = styled.div`
    position: absolute;
    top: calc(25pt + var(--offset-alvance-print-top));        
    left: calc(25pt + var(--offset-alvance-print-left));
    width: 478pt;
    height: 20pt;
    line-height: 17pt;

    font-size: 15pt;
    font-family: Arial;
    //font-weight: bold;
    padding-top: 1.25pt;
    padding-left: calc(8pt + (6 * 5pt));
    
    
`;

export default MetaTitleContent;
