//import { normalize, denormalize } from 'normalizr';
//import * as schema from './schema';
import Ajv from "ajv"
import { API } from "./types";
import { SET_ARTICLE_DETAILS, FETCH_ARTICLE_DETAILS } from "./types";
import { SET_A3S, FETCH_A3S } from "./types";
import { SET_A3ID, FETCH_A3, SET_A3 } from "./types";
import { SET_A3IMAGE, FETCH_A3IMAGE, SAVE_A3IMAGE } from "./types";
import { ADD_PAGE_TO_BOX, STORE_LAYOUT } from "./types";
import {
  ADD_VIEW_TO_BOX,
  ADD_TODO_TO_A3,
  SAVE_A3_TO_API,
  SAVE_TEMPLATE_TO_API,
  SET_TITLE_OF_BOX,
  ADD_PAGE_TO_VIEW,
} from "./types";
import * as Types from "./types";
import _ from "lodash";
import { Editor } from "react-draft-wysiwyg";

export function auth0User(user) {
  return { type: "AUTH_SET_USER", user: user };
}

export function auth0IsAuthenticated(isAuthenticated) {
  return { type: "AUTH_SET_ISAUTHENTICATED", isAuthenticated: isAuthenticated };
}

export function fetchArticleDetails() {
  //console.log("fetchArticleDetails called");
  return apiAction({
    //url: "https://api.myjson.com/bins/19dtxc",
    url: "http://localhost:7071/api/geta3list?email=mark.volders@thinka3.com",
    onSuccess: setArticleDetails,
    onFailure: handleFailurefetchArticleDetails,
    label: FETCH_ARTICLE_DETAILS
  });
}

function handleFailurefetchArticleDetails() {
  return { type: "FETCH_ARTICLE_DETAILS_FAILURE" };
}

function setArticleDetails(data) {
  //console.log("setArticleDetails called");

  return {
    type: SET_ARTICLE_DETAILS,
    payload: data.json
  };
}

export function newA3() {
  //console.log("fetchA3s called");
  return apiAction({
    method: "POST",
    url: process.env.REACT_APP_API_URL.concat("/api/gettemplatev2"),

    onSuccess: setA3FromApi,
    onFailure: handleFailurefetchNewA3,
    label: FETCH_A3S //TODO: This needs to be corrected as it is wrong but it seems to work (for now) => Change to "FETCH_TEMPLATE"
  });
}

function handleFailurefetchNewA3() {
  return { type: "NEW_A3_FAILURE" };
}

export function fetchA3s(email) {
  return apiAction({
    method: "POST",
    url: process.env.REACT_APP_API_URL.concat("/api/geta3list"),
    data: {
      email: email,
    },
    onSuccess: handleSuccesFectchA3s,
    onFailure: handleFailureFectchA3s,
    label: FETCH_A3S
  });
}

function handleSuccesFectchA3s(data) {
  return {
    type: SET_A3S,
    payload: data.json
  };
}

function handleFailureFectchA3s(error) {
  return { type: "SAVE_A3S_FAILURE", error: error };
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => { },
  onFailure = () => { },
  label = "",
  headersOverride = null
}) {

  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride
    }
  };
}

export function setA3id(a3id) {
  //console.log("setA3id called");
  return {
    type: SET_A3ID,
    payload: a3id,
    a3Id: a3id,
  };
}

export function saveA3V2ToApi() {
  return apiAction({
    label: Types.SAVE_A3V2_TO_API,
    onSuccess: handleSuccesSaveA3ToApi,
    onFailure: handleFailureSaveA3ToApi,
  });
}


export function saveA3ToApi() {
  return apiAction({
    label: SAVE_A3_TO_API,
    onSuccess: handleSuccesSaveA3ToApi,
    onFailure: handleFailureSaveA3ToApi,
  });
}

function handleSuccesSaveA3ToApi(data) {
  return { type: "SET_A3ID", a3Id: data.uuid };
}

function handleFailureSaveA3ToApi(error) {
  return { type: "SAVE_A3_FAILURE", error: error };
}

export function saveTemplateToApi() {
  return apiAction({
    label: SAVE_TEMPLATE_TO_API,
    onSuccess: handleSuccesSaveTemplateToApi,
    onFailure: handleFailureSaveTemplateToApi,
  });
}

function handleSuccesSaveTemplateToApi() {
  return { type: "SAVE_TEMPLATE_SUCCESS" };
}

function handleFailureSaveTemplateToApi() {
  return { type: "SAVE_TEMPLATE_FAILURE" };
}

export function fetchA3(a3id, email) {

  return apiAction({
    method: "POST",
    url: process.env.REACT_APP_API_URL.concat("/api/getA3"),
    data: {
      email: email,
      uuid: a3id,
    },
    onSuccess: setA3FromApi,
    onFailure: handleFailureFetchA3,
    label: FETCH_A3
  });
}

function handleFailureFetchA3() {
  return { type: "FETCH_A3_FAILURE" };
}


function setA3FromApi(data) {
  if (!data || !data.json)
    return { type: "ERROR_SET_A3" };

  console.log("Before :", JSON.parse(data.json));
  try {
    //const demoJSON = "{    \"id\": \"123\",      \"author\": {      \"id\": \"1\",        \"name\": \"Paul\"    },    \"title\": \"My awesome blog post\",      \"comments\": [        {          \"id\": \"324\",          \"commenter\": {            \"id\": \"2\",            \"name\": \"Nicole\"          }        }      ]  }";
    //let parsedJson = JSON.parse(demoJSON);
    let parsedJson = { "id": data.uuid, ...JSON.parse(data.json) }


    console.log("parsedJson :", parsedJson);
    var GenerateSchema = require('generate-schema')
    var gSchema = GenerateSchema.json('a3', parsedJson);

    console.log("After: gSchema", gSchema);
    let avjSchema = { "$id": "http://json-schema.org/draft-04/schema#", ...gSchema }
    //let avjSchema = gSchema;
    console.log("After: avjSchema", avjSchema);

    const { loadSchemas, normalize } = require('json-schema-normalizer')

    loadSchemas([avjSchema])

    const normalizedData = normalize('a3', parsedJson)
    console.log("normalized response: ", normalizedData);
  }
  catch (error) {
    console.log(error);
  }

  return {
    type: SET_A3,
    data: data.json,
    payload: data.json
  };
}

export function setA3(data) {
  if (!data || data === "undefined")
    return { type: "ERROR_SET_A3" };

  return {
    type: SET_A3,
    payload: data
  };
}

export function fetchA3Image(uuid, email) {
  //console.log("fetchA3 called: ".concat("http://localhost:7071/api/getA3?email=mark.volders@thinka3.com&uuid=".concat(a3id)));
  //const a3id = useSelector(state => state.a3.a3id);

  if (!uuid || !email)
    return { type: "ERROR_FETCH_A3IMAGE" };

  return apiAction({
    method: "POST",
    url: process.env.REACT_APP_API_URL.concat("/api/getImage"),
    data: {
      email: email,
      uuid: uuid,
    },
    onSuccess: setA3ImageFromApi,
    onFailure: handleFailureFetchA3Image,
    label: FETCH_A3IMAGE
  });
}

function handleFailureFetchA3Image() {
  return { type: "FETCH_A3IMAGE_FAILURE" };
}

function setA3ImageFromApi(data) {
  //console.log("setA3ImageFromApi called");
  //console.log(data);

  if (!data || !data.uuid || !data.json)
    return { type: "ERROR_SET_A3IMAGE" };

  return {
    type: SET_A3IMAGE,
    payload:
    {
      fileId: data.uuid,
      uuid: data.uuid,
      data: data.json,
    }
  };
}

export function saveA3Image(uuid, image) {
  if (!uuid)
    return { type: "ERROR_SAVE_A3IMAGE_NO_ID" };

  if (!image)
    return { type: "ERROR_SAVE_A3IMAGE_NO_DATA" };

  return apiAction({
    data: {
      uuid: uuid,
      image: image,
    },
    label: SAVE_A3IMAGE,
    onSuccess: handleSuccessSaveA3Image,
    onFailure: handleFailureSaveA3Image,
  });
}

function handleSuccessSaveA3Image(data) {
  return {
    type: "SUCCES_SAVE_A3IMAGE",
    payload: data
  };
}

function handleFailureSaveA3Image(error) {
  return { type: "SAVE_A3IMAGE_FAILURE", error: error };
}

///////////////

export const addPageToBox = (boxId) => ({
  type: ADD_PAGE_TO_BOX,
  boxId: boxId,
  payload: {
    "Sort": 1,
    "Page": "Single",
    "Content": [{
      "Type": "Text",
      "Value": "New Page"
    }]
  }
})

export const storeLayout = (layout, boxId, pageId) => ({
  type: STORE_LAYOUT,
  layout: layout,
  boxId: boxId,
  pageId: pageId,
  payload: {
    "Sort": 1,
    "Page": "Single",
    "Content": [{
      "Type": "Text",
      "Value": "New Page"
    }]
  }
})

/////////

export function addViewToBox(boxId) {
  //We'll keep the viewId equal to the boxId for now as we don't want to use multiple views just yet
  //const { v4: uuidv4 } = require('uuid');
  //const viewId = uuidv4(); 
  const viewId = boxId

  return {
    type: ADD_VIEW_TO_BOX,
    boxId: boxId,
    viewId: viewId,
    viewType: "DEFAULT",
    activePage: "",
  }
}

export function addPageToView(boxId, viewId) {
  const { v4: uuidv4 } = require('uuid');
  const pageId = uuidv4();
  let pageType = "SINGLE"; //SINGLE first for the carrousel. Next is RRGL

  return {
    type: ADD_PAGE_TO_VIEW,
    boxId: boxId,
    viewId: viewId,
    pageId: pageId,
    pageType: pageType,
    layouts: { lg: gridLayout() },
    activeContent: "",
  }
}

function gridLayout() {
  return _.map(_.range(0, 4), function (item, i) {
    return {
      x: (i * 12) % 24,
      y: 0,
      w: 12,
      h: 9,
      i: i.toString(),
      static: false
    };
  });
}


export function addTodoToA3ViaText(text) {
  const { v4: uuidv4 } = require('uuid');
  let todoId = uuidv4();

  //We can't add an empty todo
  if (!text)
    return { type: "ERROR_ADD_TODO_TO_A3" };

  return {
    type: ADD_TODO_TO_A3,
    todoId: todoId,
    text: text,
    completed: false,
    payload: {
      "todoId": todoId,
      "text": text,
      "completed": false
    }
  };
}

export function addTodoToA3(a3Id, todoId, text, completed) {
  const { v4: uuidv4 } = require('uuid');

  //We can't add an empty todo
  if (!text)
    return { type: "ERROR_ADD_TODO_TO_A3" };

  //Fill in a new todoId if this would be missing
  if (!todoId)
    todoId = uuidv4();

  return {
    type: ADD_TODO_TO_A3,
    a3Id: a3Id,
    todoId: todoId,
    text: text,
    completed: completed,
    payload: {
      "todoId": todoId,
      "text": text,
      "completed": completed
    }
  };
}


/////////////

let nextTodoId = 0
export const initTodo = () => ({
  type: 'INIT_TODO',
  id: nextTodoId++,
})

export const addTodo = text => ({
  type: 'ADD_TODO',
  id: nextTodoId++,
  text
})

export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
})

export const toggleTodo = id => ({
  type: 'TOGGLE_TODO',
  id
})

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const addImage = (imageId, data) => ({
  type: 'ADD_IMAGE',
  id: imageId,
  data
})

export const addA3 = (a3id, data) => ({
  type: 'ADD_A3',
  a3id: a3id,
  data
})

export const addA3s = (payload) => ({
  type: 'ADD_A3S',
  payload
})

export const fetchA3ListFromApi = (email) => ({
  type: 'FETCH_A3LIST_FROM_API',
  email
})

export const setTitleOfBox = (boxId, title) => ({
  type: SET_TITLE_OF_BOX,
  boxId: boxId,
  title: title
})

export const SetBoxTitle = (boxId, title) => ({
  type: SET_TITLE_OF_BOX,
  boxId: boxId,
  title: title
})

export const setContentValue = (value, contentId) => ({
  type: Types.SET_VALUE_OF_CONTENT,
  contentId,
  value
})

///////////////

export function getA3sFromApi() {
  return apiAction({
    label: Types.GET_A3S_FROM_API,
    onSuccess: handleSuccesGetA3sFromApi,
    onFailure: handleFailureGetA3sFromApi,
  });
}

function handleSuccesGetA3sFromApi(data) {
  //console.log("data.payload", data.payload);
  return { type: "SET_A3S_NORMALIZED", payload: data.payload.a3s };
}

function handleFailureGetA3sFromApi(error) {
  return { type: "GET_A3S_FROM_API_FAILURE", error: error };
}

export function removeA3FromApi(a3Id) {
  return apiAction({
    label: Types.REMOVE_A3_FROM_API,
    data: {
      a3Id,
    },
    onSuccess: handleSuccesRemoveA3FromApi,
    onFailure: handleFailureRemoveA3FromApi,
  });
}

function handleSuccesRemoveA3FromApi(data) {
  return { type: "REMOVE_A3_FROM_API_SUCCES", data };
}

function handleFailureRemoveA3FromApi(error) {
  return { type: "REMOVE_A3_FROM_API_FAILURE", error: error };
}

export function addBoxToA3(a3Id, title) {
  const { v4: uuidv4 } = require('uuid');
  const boxId = uuidv4();

  return {
    type:
      Types.ADD_BOX_TO_A3,
    boxId: boxId,
    a3Id: a3Id,
    go: false,
    title: title
  };
}

export function setLayoutOfPage(layout, pageId) {
  const { v4: uuidv4 } = require('uuid');

  return {
    type: Types.SET_LAYOUT_OF_PAGE,
    layoutId: uuidv4(),
    layout,
    pageId
  };
}

export function setCompactTypeOfPage(compactType, pageId) {
  const { v4: uuidv4 } = require('uuid');

  return {
    type: Types.SET_COMPACTTYPE_OF_PAGE,
    compactType,
    pageId,
    layoutId: uuidv4()
  };
}

export function removeBoxError() {
  return {
    type: Types.REMOVE_ERROR_BOXES
  }
}

export function removeA3FromState(a3Id) {
  return {
    type: Types.REMOVE_A3_FROM_STATE,
    a3Id
  }
}

export function setCurrentA3(a3Id) {
  return {
    type: Types.SET_CURRENT_A3,
    a3Id
  }
}

export function increaseFontSizeCKEditor() {
  return {
    type: Types.INCREASE_FONTSIZE_CKEDITOR
  }
}

export function decreaseFontSizeCKEditor() {
  return {
    type: Types.DECREASE_FONTSIZE_CKEDITOR
  }
}

export function resetFontSizeCKEditor() {
  return {
    type: Types.RESET_FONTSIZE_CKEDITOR
  }
}

export function setFontSizeCKEditor(fontSize) {
  return {
    type: Types.SET_FONTSIZE_CKEDITOR,
    fontSize
  }
}

export function createEmptyA3(a3Id) {
  const { v4: uuidv4 } = require('uuid');
  var randomWords = require('random-words');
  let randomTitle = randomWords({
    exactly: 1, wordsPerString: 2, formatter: (word, index) => {
      return index === 0 ? word.slice(0, 1).toUpperCase().concat(word.slice(1)) : word;
    }
  })[0].concat(" A3");

  const boxId1 = uuidv4()
  const boxId2 = uuidv4()
  const boxId3 = uuidv4()
  const boxId4 = uuidv4()
  const boxId5 = uuidv4()
  const boxId6 = uuidv4()
  const boxId7 = uuidv4()
  const boxId8 = uuidv4()
  const boxId9 = uuidv4()

  const contentId1 = uuidv4()
  const contentId2 = uuidv4()
  const contentId3 = uuidv4()
  const contentId4 = uuidv4()
  const contentId5 = uuidv4()
  const contentId6 = uuidv4()
  const contentId7 = uuidv4()
  const contentId8 = uuidv4()
  const contentId9 = uuidv4()

  return {
    type: Types.CREATE_EMPTY_A3,
    a3: {
      [a3Id]: {
        "id": a3Id,
        "template": "Local",
        "version": "0.0.5",
        "name": randomTitle,
        "title": randomTitle,
        "type": "A3"
      }
    },
    box1: {
      [boxId1]: {
        "id": boxId1,
        "a3Id": a3Id,
        "name": "BOX1",
        "title": "Box 1",
        "go": false,
        "sort": 1
      }
    },
    box2: {
      [boxId2]: {
        "id": boxId2,
        "a3Id": a3Id,
        "name": "BOX2",
        "title": "Box 2",
        "go": false,
        "sort": 2
      }
    },
    box3: {
      [boxId3]: {
        "id": boxId3,
        "a3Id": a3Id,
        "name": "BOX3",
        "title": "Box 3",
        "go": false,
        "sort": 3
      }
    },
    box4: {
      [boxId4]: {
        "id": boxId4,
        "a3Id": a3Id,
        "name": "BOX4",
        "title": "Box 4",
        "go": false,
        "sort": 4
      }
    },
    box5: {
      [boxId5]: {
        "id": boxId5,
        "a3Id": a3Id,
        "name": "BOX5",
        "title": "Box 5",
        "go": false,
        "sort": 5
      }
    },
    box6: {
      [boxId6]: {
        "id": boxId6,
        "a3Id": a3Id,
        "name": "BOX6",
        "title": "Box 6",
        "go": false,
        "sort": 6
      }
    },
    box7: {
      [boxId7]: {
        "id": boxId7,
        "a3Id": a3Id,
        "name": "BOX7",
        "title": "Box 7",
        "go": false,
        "sort": 7
      }
    },
    box8: {
      [boxId8]: {
        "id": boxId8,
        "a3Id": a3Id,
        "name": "BOX8",
        "title": "Box 8",
        "go": false,
        "sort": 8
      }
    },
    box9: {
      [boxId9]: {
        "id": boxId9,
        "a3Id": a3Id,
        "name": "BOX9",
        "title": "Box 9",
        "go": false,
        "sort": 9
      }
    },
    content1: {
      [contentId1]: {
        "id": contentId1,
        "boxId": boxId1,
        "type": "editor",
        "value": ""
      }
    },
    content2: {
      [contentId2]: {
        "id": contentId2,
        "boxId": boxId2,
        "type": "editor",
        "value": ""
      }
    },
    content3: {
      [contentId3]: {
        "id": contentId3,
        "boxId": boxId3,
        "type": "editor",
        "value": ""
      }
    },
    content4: {
      [contentId4]: {
        "id": contentId4,
        "boxId": boxId4,
        "type": "editor",
        "value": ""
      }
    },
    content5: {
      [contentId5]: {
        "id": contentId5,
        "boxId": boxId5,
        "type": "editor",
        "value": ""
      }
    },
    content6: {
      [contentId6]: {
        "id": contentId6,
        "boxId": boxId6,
        "type": "editor",
        "value": ""
      }
    },
    content7: {
      [contentId7]: {
        "id": contentId7,
        "boxId": boxId7,
        "type": "editor",
        "value": ""
      }
    },
    content8: {
      [contentId8]: {
        "id": contentId8,
        "boxId": boxId8,
        "type": "editor",
        "value": ""
      }
    },
    content9: {
      [contentId9]: {
        "id": contentId9,
        "boxId": boxId9,
        "type": "editor",
        "value": ""
      }
    },
    allBoxIds: [boxId1, boxId2, boxId3, boxId4, boxId5, boxId6, boxId7, boxId8, boxId9],
    allContentIds: [contentId1, contentId2, contentId3, contentId4, contentId5, contentId6, contentId7, contentId8, contentId9],
    a3Id
  }
}

export function AddEditorContentToBox(boxId) {
  const { v4: uuidv4 } = require('uuid');
  const contentId = uuidv4()

  return {
    type: Types.ADD_EDITOR_CONTENT_TO_BOX,
    boxId,
    contentId,
    content: {
      [contentId]: {
        "id": contentId,
        "boxId": boxId,
        "type": "editor",
        "value": ""
      }
    }
  }
}

export function AddRandomImageContentToBox(boxId) {
  const { v4: uuidv4 } = require('uuid');
  const contentId = uuidv4()

  return {
    type: Types.ADD_RANDOMIMAGE_CONTENT_TO_BOX,
    boxId,
    contentId,
    content: {
      [contentId]: {
        "id": contentId,
        "boxId": boxId,
        "type": "image",
        "localUrl": "",
        "remoteUrl": "https://picsum.photos/1027/".concat(Math.floor(1000 + Math.random() * 501).toString())
      }
    }
  }
}

export function AddImageContentToBox(boxId, localUrl) {
  const { v4: uuidv4 } = require('uuid');
  const contentId = uuidv4()

  return {
    type: Types.ADD_CONTENT_TO_BOX,
    boxId,
    contentId,
    content: {
      [contentId]: {
        "id": contentId,
        "boxId": boxId,
        "type": "image",
        "localUrl": localUrl,
        "remoteUrl": ""
      }
    }
  }
}

export function AddContentToBox(boxId, contentId, content) {

  return {
    type: Types.ADD_CONTENT_TO_BOX,
    boxId,
    contentId,
    content
  }
}

export function RemoveContentFromBox(boxId, contentId) {

  return {
    type: Types.REMOVE_CONTENT_FROM_BOX,
    boxId,
    contentId
  }
}

export function SetGoToLastPage(boxId, value) {

  return {
    type: Types.SET_GOTOLASTPAGE,
    boxId,
    value
  }
}

export function SetRemoveActivePage(boxId, value) {

  return {
    type: Types.SET_REMOVEACTIVEPAGE,
    boxId,
    value
  }
}

export function SetGoToPage(boxId, value) {

  return {
    type: Types.SET_GOTO_PAGE,
    boxId,
    value
  }
}

export function SetFocusOnBox(boxId) {

  return {
    type: Types.SET_FOCUS_ON_BOX,
    boxId
  }
}

export function RemoveFocusFromBox() {

  return {
    type: Types.REMOVE_FOCUS_FROM_BOX
  }
}

export function ResetToInitialState() {

  return {
    type: Types.RESET_TO_INITIALSTATE
  }
}

export function SetBoxGo(boxId, go) {
  return {
    type: Types.SET_BOX_GO,
    boxId,
    go
  }
}

export function SetSetting(type, value) {
  return {
    type: type,
    value
  }
}

export function loadA3V2FromApi() {
  return apiAction({
    label: Types.LOAD_A3V2_FROM_API,
    onSuccess: handleSuccesGetA3V2FromApi,
    onFailure: handleFailureGetA3V2FromApi,
  });
}

function handleSuccesGetA3V2FromApi(data) {
  //console.log("data.payload", data.payload);
  return { type: "SET_A3_NORMALIZED", payload: data.payload };
}

function handleFailureGetA3V2FromApi(error) {
  return { type: "LOAD_A3V2_FROM_API_FAILURE", error: error };
}

export function sendKeepAliveToApi() {
  return apiAction({
    label: Types.KEEP_ALIVE,
    onSuccess: handleSuccesSendKeepAliveToApi,
    onFailure: handleFailureSendKeepAliveToApi,
  });
}

function handleSuccesSendKeepAliveToApi(data) {
  return { type: "KEEP_ALIVE_SUCCES", payload: data.payload };
}

function handleFailureSendKeepAliveToApi(error) {
  return { type: "KEEP_ALIVE_FAILURE", error: error };
}

export function setMetaValue(id, value) {
  return {
    type: Types.SET_META_VALUE,
    id,
    value
  }
}

export function setContentValueV2(id, value) {
  return {
    type: Types.SET_CONTENT_VALUE,
    id,
    value
  }
}

export function loadNewA3FromTemplate(newA3Id, templateId) {
  return apiAction({
    label: Types.NEW_A3_FROM_TEMPLATE_API,
    data: {
      newA3Id,
      templateId
    },
    onSuccess: handleSuccesGetA3V2FromApi,
    onFailure: handleFailureLoadNewwA3FromTemplate,
  });
}

function handleFailureLoadNewwA3FromTemplate(error) {
  return { type: "NEW_A3_FROM_TEMPLATE_API_FAILURE", error: error };
}

export function shareA3(a3Id, level, right, email) {
  return apiAction({
    label: Types.SHARE_A3,
    data: {
      a3Id,
      level,
      right,
      email
    },
    onSuccess: handleSuccesShareA3,
    onFailure: handleFailureShareA3,
  });
}

function handleSuccesShareA3(data) {
  return { type: "SHARE_A3_SUCCES", payload: data.payload };
}

function handleFailureShareA3(error) {
  return { type: "SHARE_A3_FAILURE", error: error };
}

export function setValuesForPageContent(pagecontentId, pageId, contentId, x, y, width, height, sort) {
  return {
    type: Types.SET_VALUES_FOR_PAGECONTENT,
    pagecontentId,
    pageId,
    contentId,
    x,
    y,
    width,
    height,
    sort
  }
}

export function getDbInfoFromApi() {
  return apiAction({
    label: Types.GET_DB_INFO,
    onSuccess: handleSuccesGetDbInfoFromApi,
    onFailure: handleFailureGetDbInfoFromApi,
  });
}

function handleSuccesGetDbInfoFromApi(data) {
  return { type: Types.SET_DB, payload: data.payload };
}

function handleFailureGetDbInfoFromApi(error) {
  return { type: "GET_DB_INFO_FAILURE", error: error };
}

export function setA3Title(a3Id, title) {
  return { type: Types.SET_A3_TITLE, a3Id, title };
}