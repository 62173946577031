import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import BoxV2 from '../../components/BoxV2';

const MockBox = () => {
    const { v4: uuidv4 } = require('uuid');
    const boxId1 = "mockBoxId3"
    const boxId2 = "mockBoxId4"

    return (
        <ComponentWrapper>
            <BoxV2
                key={uuidv4()}
                boxId={boxId1}
            />
            <BoxV2
                key={uuidv4()}
                boxId={boxId2}
            />
            <BoxV2
                key={uuidv4()}
                boxId={boxId2}
            />
            <BoxV2
                key={uuidv4()}
                boxId={boxId2}
            />
        </ComponentWrapper>
    );

}

export default MockBox;
