import React, { useMemo, useEffect, useState } from 'react';
//import { useHistory } from 'react-router-dom';
//import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { stripHtml } from "string-strip-html";
import sanitizeHtml from "sanitize-html";

import BoxV2 from '../BoxV2';
import PrintGridBox from '../PrintGridBox';
import BoxColumn from './BoxColumn';
import BoxRow from './BoxRow';
import Title from './Title'
import Wrapper from './Wrapper'

import { setA3Title, getA3sFromApi } from '../../actions'

import {
    makeSelectBoxesSelectorByA3Id,
    selectCurrent
} from '../../Selectors';

import { useSnackbar } from 'notistack';
import ContentEditable from 'react-contenteditable';

const BoxGridV2 = React.memo((props) => {
    //Props
    const { a3Id, print, screenWidth, screenHeight } = props;

    //Use
    //const dispatch = useDispatch();
    //const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    //Selectors    
    ///No cache selector
    const current = useSelector(selectCurrent);
    const selectBoxes = (state, a3Id) => state.entities.boxes.allIds
        .filter(id => id)
        .map(id => state.entities.boxes.byId[id])
        .filter(box => box)
        .filter(box => box.a3Id.toLowerCase() === a3Id.toLowerCase());
    const boxes = useSelector((state) => selectBoxes(state, a3Id))


    ///Cached selector
    //const selectBoxesSelector = useMemo(makeSelectBoxesSelectorByA3Id, [a3Id])    
    //const boxes = useSelector((state) => selectBoxesSelector(state, a3Id))

    const sanitizeConf = {
        allowedTags: [],
        allowedAttributes: {}
    };

    //Use
    const dispatch = useDispatch();

    //State    
    //const [boxTitle, setBoxTitle] = useState(props.boxTitle);
    //const [goNoGoSelected, setGoNoGoSelected] = useState(props.go);

    //Handlers
    const boxesPerColumn = 3;
    const cols = [0, 1, 2];

    //Checks
    //console.log("a3Id", a3Id);    
    //console.log("boxes", boxes);
    //console.log("BoxGridV2 called", a3Id);

    //Error handling
    if (!a3Id)
        enqueueSnackbar("Error in BoxGrid: A3Id missing", {
            variant: 'error',
            preventDuplicate: true
        });

    const [boxColWidth, setBoxColWidth] = useState("100%");

    const a3 = useSelector(state => state.entities.a3s.byId[a3Id]);

    let a3Title = "";
    if (a3 && a3.title)
        a3Title = a3.title;

    useEffect(() => {
        setBoxColWidth(screenWidth < 769 && screenWidth > 0 ? "100%" : "30%");
    }, [screenWidth])

    //Quickfix: This should be filled in when the new a3Id is returned by the API
    useEffect(() => {
        if (!a3 || (a3 && !a3.title))
            dispatch(getA3sFromApi());

    }, [])

    return (
        <Wrapper>
            { a3Title ?
                <Title>
                    <ContentEditable
                        className="edit"
                        html={a3Title}
                        //placeholder="Title: What are you talking about?"
                        onChange={evt => dispatch(setA3Title(a3Id, sanitizeHtml(evt.target.value, sanitizeConf)))}
                    />
                </Title>
                : null
            }
            <BoxRow>
                {
                    cols.map(col => {
                        //By default, show all of the columns so the grid is displayed
                        let showBoxColumn = true;

                        //If the focusBoxId is filled out, don't show anything by default as we want to zoom into the focusBox
                        if (!print && current.focusOnBox && current.boxId.length > 0) {
                            //If the current boxId is found, only set the showBoxColumn to false to avoid a blank screen
                            [...boxes]
                                .map(box => {
                                    if (current.focusOnBox && current.boxId.length > 0 && box && box.id && box.id === current.boxId) {
                                        showBoxColumn = false;
                                    }
                                    return null;
                                })
                        }

                        //Detect if this BoxColumn contains the focusBox
                        if (!showBoxColumn) {
                            [...boxes]
                                .splice(col * boxesPerColumn, boxesPerColumn)
                                .map(box => {
                                    if (current.focusOnBox && current.boxId.length > 0 && box && box.id && box.id === current.boxId) {
                                        showBoxColumn = true;
                                    }
                                    return null;
                                })
                        }

                        return (
                            <BoxColumn key={col} show={showBoxColumn} boxColWidth={boxColWidth}>
                                {
                                    [...boxes]
                                        .splice(col * boxesPerColumn, boxesPerColumn)
                                        .map(box => {
                                            let showBox = ((current.focusOnBox && current.boxId.length > 0 && box && box.id && box.id === current.boxId) || (!current.focusOnBox) || print) ? true : false;
                                            return (

                                                false ?
                                                    <PrintGridBox
                                                        key={box.id}
                                                        boxId={box.id}
                                                        show={showBox}
                                                    />
                                                    :
                                                    <BoxV2
                                                        key={box.id}
                                                        boxId={box.id}
                                                        show={showBox}
                                                        print={print}
                                                        screenWidth={screenWidth}
                                                        screenHeight={screenHeight}
                                                    />

                                            );
                                        })
                                }
                            </BoxColumn>
                        )
                    })
                }
            </BoxRow >
        </Wrapper>
    );

});

export default BoxGridV2;
