import produce from 'immer';
import {
  AUTH_SET_USER,
  AUTH_SET_ISAUTHENTICATED,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
};

const auth = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AUTH_SET_USER:
        draft.user = action.user;
        break;

      case AUTH_SET_ISAUTHENTICATED:
        draft.isAuthenticated = action.isAuthenticated;
        break;
    }
  });
}

export default auth