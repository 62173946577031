import styled from 'styled-components';

const Wrapper = styled.div`

@media screen { 
    display: none;
}

@media print { 
    display: block;
    padding: 8px !important;
    height: 100vh;
    width: 99vw;

    visible: visible;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

`;

export default Wrapper;

/*
    visible: visible;
    -webkit-print-color-adjust: exact !important;
color - adjust: exact!important;
*/

/*
@media print {
    @page {
        size: A3 landscape
    }

    width: 1191pt;
    height: 842pt;
}
*/

/*
@media print {@page {size: A3 landscape} body { zoom: 90%; width:98%; height: 98%; overflow: hidden; font-size: 12pt; } h1 { font-size: 16pt; } h2 { font-size: 14pt; } }
*/