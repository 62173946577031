import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import A3V2 from '../../components/A3V2';

const MockBoxA3 = () => {
    const a3Id = "a3Id1"

    return (
        <ComponentWrapper>
            <A3V2
            //a3Id={a3Id}
            />
        </ComponentWrapper>
    );

}

export default MockBoxA3;
