import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import A3sV2 from '../../components/A3sV2';

const MockBoxA3s = () => {

    return (
        <ComponentWrapper>
            <A3sV2 />
        </ComponentWrapper>
    );

}

export default MockBoxA3s;
