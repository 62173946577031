import styled from 'styled-components';

const Wrapper = styled.div`
min-width: 200px;
height: 36px;
background-color: #dae136;
display: flex;
align-items: center;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
//filter: drop-shadow(30px 10px 4px #4444dd);
`;

export default Wrapper;
