import React from 'react';

import Wrapper from './Wrapper';
import Left from './Left';
import Right from './Right';
import ResizeIcon from './ResizeIcon';

import GoNoGoToggleButton from '../GoNoGoToggleButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPlusCircle, faMinus, faArrowLeft, faArrowRight, faImage } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux';
import { SetGoToPage, AddEditorContentToBox, SetGoToLastPage, AddImageContentToBox, SetRemoveActivePage, SetFocusOnBox, RemoveFocusFromBox, saveA3Image, AddContentToBox } from '../../actions'

import BoxTitleV2 from '../BoxTitleV2';
import RemoveFocusIcon from './RemoveFocusIcon';
import SetFocusIcon from './SetFocusIcon';
import RemovePageIcon from './RemovePageIcon';


const BoxHeaderV2 = React.memo((props) => {
    const {
        boxId,
        hideFocusOption,
        hideGoNoGo,
        hideUnfocusOption,
        hideNewPageOption,
        hideNewEditorPageOption,
        hideNewImagePageOption,
        hideRemovePageOption,
        hideArrowsOption,
        hidePageCarrouselOptions
    } = props;

    //Use
    const dispatch = useDispatch();

    //Selectors    


    //State
    let fileUploader;


    //Handlers


    const handleClickAddEditorPageToBox = (boxId) => {
        dispatch(SetGoToLastPage(boxId, true));
        dispatch(AddEditorContentToBox(boxId))
    }

    const handleClickAddImagePageToBox = () => {
        fileUploader.click();
    }

    const handleChangeFile = (event) => {
        //Retrieve the file
        event.stopPropagation();
        event.preventDefault();

        const file = event && event.target && event.target.files ? event.target.files[0] : false;

        if (file) {
            let localUrl = file ? window.URL.createObjectURL(file) : "";

            const { v4: uuidv4 } = require('uuid');
            const contentId = uuidv4()

            let content = {
                [contentId]: {
                    "id": contentId,
                    "boxId": boxId,
                    "type": "image",
                    "localUrl": localUrl,
                    "remoteUrl": process.env.REACT_APP_API_URL.concat("/api/getImage?uuid=").concat(contentId).concat("&email=").concat("api@thinka3.com")
                }
            }

            dispatch(AddContentToBox(boxId, contentId, content))
            dispatch(SetGoToPage(boxId, "LAST"));

            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                let base64 = reader.result;
                dispatch(saveA3Image(contentId, base64));
            }
        }

    }

    const handleClickPreviousPage = (boxId) => {
        dispatch(SetGoToPage(boxId, "PREV"));
    }

    const handleClickNextPage = (boxId) => {
        dispatch(SetGoToPage(boxId, "NEXT"));
    }

    //

    //Check
    //if (!boxes && !boxes.length === 1)
    //  return null;

    return (

        <Wrapper print>
            <Left>
                <BoxTitleV2
                    boxId={boxId}
                />
            </Left>
            {hideArrowsOption && hideNewEditorPageOption && hideNewImagePageOption && hideFocusOption && hideUnfocusOption && hideGoNoGo && hideNewPageOption ? null :
                <Right>
                    <input type="file" id="file" ref={ref => (fileUploader = ref)} style={{ display: "none" }} onChange={(event) => handleChangeFile(event)} />
                    {hidePageCarrouselOptions || hideArrowsOption ? null :
                        <ResizeIcon className="no-print">
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                title="Slide left"
                                color="white"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '1px 1px 1px 1px'
                                }}
                                onClick={() => handleClickPreviousPage(boxId)}
                            />
                        </ResizeIcon>
                    }
                    {hidePageCarrouselOptions || hideArrowsOption ? null :
                        <ResizeIcon className="no-print">
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                title="Slide right"
                                color="white"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '1px 1px 1px 1px'
                                }}
                                onClick={() => handleClickNextPage(boxId)}
                            />
                        </ResizeIcon>
                    }
                    {hidePageCarrouselOptions || hideNewEditorPageOption ? null :
                        <ResizeIcon className="no-print">
                            <FontAwesomeIcon
                                icon={faPlus}
                                title="Add a text page"
                                color="white"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '1px 1px 1px 1px'
                                }}
                                onClick={() => handleClickAddEditorPageToBox(boxId)}
                            />
                        </ResizeIcon>
                    }
                    {hidePageCarrouselOptions || hideNewImagePageOption ? null :
                        <ResizeIcon className="no-print">
                            <FontAwesomeIcon
                                icon={faImage}
                                title="Add an image page"
                                color="white"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '1px 1px 1px 1px'
                                }}
                                onClick={() => handleClickAddImagePageToBox(boxId)}
                            />
                        </ResizeIcon>
                    }
                    {hidePageCarrouselOptions || hideRemovePageOption ? null :
                        <RemovePageIcon
                            boxId={boxId}
                        />
                    }
                    {hideFocusOption ? null :
                        <SetFocusIcon
                            boxId={boxId}
                        />
                    }
                    {hideUnfocusOption ? null :
                        <RemoveFocusIcon />
                    }
                    {hideGoNoGo ? null :
                        <GoNoGoToggleButton
                            boxId={boxId}
                        />
                    }
                </Right>
            }
        </Wrapper>
    );
});

export default BoxHeaderV2;

/*
const handleClickAddPageToBox = (boxId) => {
        // if (!boxViews)
        //    dispatch(addViewToBox(boxId));

        //dispatch(addPageToView(boxId, boxId));
    }
--------------

                    {hideNewPageOption ? null :
                        <ResizeIcon>
                            <FontAwesomeIcon
                                icon={faPlus}
                                color="white"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    padding: '1px 1px 1px 1px'
                                }}
                                onClick={() => handleClickAddPageToBox(boxId)}
                            />
                        </ResizeIcon>
                    }

*/

