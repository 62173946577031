import React, { useRef, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from 'react-elastic-carousel';
import CarrouselItem from './CarrouselItem';

import leftarrow from './img/left-arrow.png';
import rightarrow from './img/right-arrow.png';
import PageLayout from './components/PageLayout';

const breakPoints = [
    { width: 1, itemsToShow: 1 }
];

const PageCarousel = (props) => {
    //General
    const { boxId, viewId } = props;
    const dispatch = useDispatch();

    /*
    console.log(useSelector(state => state.a3.boxes
        .find(box => box.boxId === boxId).views
        .find(view => view.viewId === viewId)
    ));

    //Selectors
    //const selectView = state => state.a3.boxes.views.filter(view => view.viewId === viewId)[0];
    const selectBox = state => state.a3.boxes.find(box => box.boxId === boxId);
    const selectView = state => state.a3.boxes.find(box => box.boxId === boxId).views.find(view => view.viewId === viewId);

    const selectViews = createSelector(
        selectBox,
        box => box.views
    );
    const selectPages = createSelector(
        selectView,
        view => view.pages
    );


    const views = useSelector(selectViews);
    //const pages = useSelector(selectPages);
    console.log(views);
    //console.log(pages);
*/

    //Component

    //let activeIndex = 0;
    let data = props.content;
    let carousel;// = useRef();

    function handleCarrouselItemChange(value, i) {
        var newData = data;
        newData[i].type = "editor";
        newData[i].value = value;

        props.onBoxCarrouselChange(JSON.stringify(newData));
    }

    useEffect(() => {
        carousel.goTo(3);
    }, [carousel]);


    function handleClick() {
        //console.log(carousel);
        carousel.goTo(3);
    }

    return (
        <div className="content">
            <div>
                <img src={leftarrow} onClick={() => carousel.slidePrev()} height='35px' alt="Slide Carrousel left"></img>
                <img src={rightarrow} onClick={() => carousel.slideNext()} height='35px' alt="Slide Carrousel right"></img>
                <img src={rightarrow} onClick={() => handleClick()} height='35px' alt="Slide Carrousel right"></img>
                <hr />
            </div>
            <div>
                <Carousel
                    key="1"
                    ref={ref => (carousel = ref)}
                    showArrows={false}
                    breakPoints={breakPoints} >
                    {data.map((content, i) => {
                        const boxId = "mockBoxId".concat(i);
                        const viewId = "mockViewId".concat(i);
                        const pageId = "pageViewId".concat(i);

                        return (

                            <CarrouselItem
                                className="carrouselItem"
                                key={i}
                                i={i}
                                focusOnBox={props.focusOnBox}
                                type={content.type}
                                data={content.value}
                                id={i}
                                onCarrouselItemChange={handleCarrouselItemChange}
                            />

                            /*
                                                        <PageLayout
                                                            boxId={boxId}
                                                            viewId={viewId}
                                                            pageId={pageId}
                                                        />
                            */
                        )
                    })}
                </Carousel>

            </div>


        </div>
    )

}

export default PageCarousel;
