import styled from 'styled-components';

const Right = styled.div`
//height: 50px;
//display: inline-block;
//float: right;
padding-top: 10px;
padding-right: 10px;
align-content: top;
vertical-align: top;
//margin: auto;
display: flex;
//align-items: top;
//background-color:green;
`;

export default Right;
