import styled from 'styled-components';

const StartDateLabel = styled.div`
    position: absolute;
    top: calc(119pt + var(--offset-alvance-print-top));        
    left: calc(44.75pt + var(--offset-alvance-print-left));
    width: 67pt;
    height: 19.5pt;
    line-height: 18.1pt;
    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
    font-size: 9.15pt;
    font-family: Arial;
    //font-weight: bold;
    padding-left: 1.5pt;
    transform: rotate(90deg);
    
`;

export default StartDateLabel;
