import React, { useState } from 'react';
import PropTypes from "prop-types";

import Wrapper from './Wrapper';
import Left from './Left';
import Right from './Right';
import ResizeIcon from './ResizeIcon';

import BoxTitle from '../BoxTitle';
import GoNoGoToggleButton from '../GoNoGoToggleButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt, faCompressArrowsAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
//import { focusOnBox } from '../';
import { addViewToBox, addPageToView } from '../../actions'
import { createSelector } from 'reselect';

function BoxHeader(props) {
    const {
        onBoxTitleChange,
        onGoNoGoChange,
        boxId,
        onFocusBoxChange,
        hideFocusOption,
        hideGoNoGo,
        hideUnfocusOption,
        hideNewPageOption,
    } = props;

    const [boxTitle, setBoxTitle] = useState(props.boxTitle);
    const [goNoGoSelected, setGoNoGoSelected] = useState(props.go);
    const dispatch = useDispatch();

    function handleToggleSelected() {
        if (onGoNoGoChange)
            onGoNoGoChange(goNoGoSelected);

        setGoNoGoSelected(!goNoGoSelected);
    }

    function handleBoxTitleChange(value) {
        if (onBoxTitleChange)
            onBoxTitleChange(value);

        setBoxTitle(value);
    }

    function handleResizeIconClicked() {
        //console.log(boxId);
        onFocusBoxChange(boxId);
        //dispatch(focusOnBox(boxId));
    }

    const selectBox = state => state.a3.boxes.filter(box => box.boxId === boxId)[0];
    const selectBoxViews = createSelector(
        selectBox,
        box => box.views
    );
    const boxViews = useSelector(selectBoxViews);

    const handleClickAddPageToBox = (boxId) => {
        if (!boxViews)
            dispatch(addViewToBox(boxId));

        dispatch(addPageToView(boxId, boxId));
    }

    return (
        <Wrapper>
            <Left>
                <BoxTitle
                    value={boxTitle}
                    onBoxTitleChange={handleBoxTitleChange}
                    boxId={boxId}
                />
            </Left>
            {hideFocusOption && hideUnfocusOption && hideGoNoGo && hideNewPageOption ? null :
                <Right>
                    {hideNewPageOption ? null :
                        <ResizeIcon>
                            <FontAwesomeIcon
                                icon={faPlus}
                                color="white"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    padding: '3px 3px 3px 3px'
                                }}
                                onClick={() => handleClickAddPageToBox(boxId)}
                            />
                        </ResizeIcon>
                    }
                    {hideFocusOption ? null :
                        <ResizeIcon>
                            <FontAwesomeIcon
                                icon={faExpandArrowsAlt}
                                color="white"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    padding: '3px 3px 3px 3px'
                                }}
                                onClick={() => handleResizeIconClicked()}
                            />
                        </ResizeIcon>
                    }
                    {hideUnfocusOption ? null :
                        <ResizeIcon>
                            <FontAwesomeIcon
                                icon={faCompressArrowsAlt}
                                color="white"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    padding: '3px 3px 3px 3px'
                                }}
                                onClick={() => handleResizeIconClicked()}
                            />
                        </ResizeIcon>
                    }
                    {hideGoNoGo ? null :
                        <GoNoGoToggleButton
                            selected={goNoGoSelected}
                            toggleSelected={handleToggleSelected}
                        />
                    }
                </Right>
            }
        </Wrapper>
    );
}

BoxHeader.propTypes = {
    boxTitle: PropTypes.string,
    onBoxTitleChange: PropTypes.func,
    goNoGoSelected: PropTypes.bool,
    onGoNoGoChange: PropTypes.func,
};

export default BoxHeader;