import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: left;
  align-items: top;
  width: 100%;
  
  min-height: 165px;
  max-height: 80%;
  max-width: 100%;
  height: props.size.height
  //background-color: lightgrey;
  //color: #fff;
  //margin: 0 15px;
  //font-size: 4em;
`;

