import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: [],
  errors: []
};

const boxes = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.ADD_BOX_TO_A3:
        return produce(state, draft => {

          draft.byId = {
            ...draft.byId, [action.a3Id]: {
              "id": action.a3Id,
              "title": action.title,
            }
          };

          draft.allIds.push(action.a3Id);

        });

      case Types.SET_TITLE_OF_BOX:
        return produce(state, draft => {
          if (draft.byId[action.boxId])
            draft.byId[action.boxId].title = action.title
          else {
            draft.errors.push({ reason: `Error in ${action.type}. BoxId "${action.boxId}" is unknown in state` })
          }
        });

      case Types.SET_BOX_GO:
        return produce(state, draft => {
          if (draft.byId[action.boxId])
            draft.byId[action.boxId].go = action.go
          else {
            draft.errors.push({ reason: `Error in ${action.type}. BoxId "${action.boxId}" is unknown in state` })
          }
        });

      case Types.REMOVE_ERROR_BOXES:
        return produce(state, draft => {
          draft.errors.shift();
        });

      case Types.CREATE_EMPTY_A3:

        draft.byId = {
          ...draft.byId, ...action.box1, ...action.box2, ...action.box3, ...action.box4, ...action.box5, ...action.box6, ...action.box7, ...action.box8, ...action.box9
        };

        draft.allIds = [...draft.allIds, ...action.allBoxIds];
        break;

      case Types.SET_A3_NORMALIZED:
        //console.log("action.payload.boxes", action.payload.boxes);
        return produce(state, draft => {

          delete draft.byId;
          draft.byId = {
            ...draft.byId, ...action.payload.boxes.byId
          };

          draft.allIds = [...new Set([...draft.allIds, ...action.payload.boxes.allIds])];

          //action.payload.boxes.all
          //const boxesById = draft.allIds.map(id => draft.byId[id]);
          //
          /*
          if (action.payload.boxes)
            [...action.payload.boxes.allIds].map(id => {
              const { title, a3Id, go, sort } = action.payload.boxes.byId[id];

              const newBox = {
                id,
                title, a3Id, go, sort
              };

              console.log("before draft.byId", draft.byId);
              console.log("before draft.byId[id]", draft.byId[id]);
              delete draft.byId[id];
              //draft.byId[id] = newBox;
              console.log("after draft.byId[id]", draft.byId[id]);
            })

          return draft;
          */
        });

      case Types.RESET_TO_INITIALSTATE:
        return initialState;
    }
  });
}

export default boxes