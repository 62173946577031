import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import Layout2 from './Layout2';
import messages from './messages';
import './styles/App.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux';
import { auth0IsAuthenticated, auth0User, sendKeepAliveToApi, getDbInfoFromApi } from '../src/actions'
import { SnackbarProvider } from 'notistack';
import GlobalStyle from './GlobalStyle';
import { useMousePosition } from "./useMousePosition";
//import Moment from 'react-moment';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { selectCurrent } from './Selectors';

import 'semantic-ui-css/semantic.min.css'

//Make sure logging is active before anything else is launched
import LogRocket from 'logrocket';
import { Button } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('z6mjxh/think-a3');
}
function LanguageProvider() {
  const [locale, setLocale] = useState('en');

  const { user, isAuthenticated } = useAuth0();
  const position = useMousePosition();

  //Store the user data in redux
  const useAuth0Data = (user, isAuthenticated) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(auth0IsAuthenticated(isAuthenticated));
      if (isAuthenticated) {
        dispatch(auth0User(user));

        if (process.env.NODE_ENV === 'production') {
          //Pass the information to LogRocket just once
          LogRocket.identify(user.email, {
            name: user.name,
            email: user.email,

            // Add your own custom user variables here, ie:
            //subscriptionType: 'pro'
          });
        }
      }
    }, [isAuthenticated, user, dispatch]);
  }

  useAuth0Data(user, isAuthenticated);

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  //Pass the relevant information to css
  const current = useSelector(selectCurrent);

  //Keep the backend alive when active  
  const [nextKeepAlive, setNextKeepAlive] = useState(moment());

  const useKeepAlive = (position, nextKeepAlive) => {
    const dispatch = useDispatch();

    useEffect(() => {
      if (moment() > nextKeepAlive) {
        dispatch(sendKeepAliveToApi());
        setNextKeepAlive(moment().add(30, 'm'));
        dispatch(getDbInfoFromApi());
      }

    }, [position, nextKeepAlive, dispatch]);
  }

  useKeepAlive(position, nextKeepAlive);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
    >
      <GlobalStyle
        whiteColor
        focusOnBox={current.focusOnBox}
      />
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key) => (
          <Button onClick={onClickDismiss(key)}>
            Dismiss
          </Button>
        )}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout2 setLocale={setLocale} />
        </ThemeProvider>
      </SnackbarProvider>

    </IntlProvider>
  );
}

export default LanguageProvider;
