import React, { useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Carousel from 'react-elastic-carousel';

import PageCarrouselWrapper from './PageCarrouselWrapper';

import { makeSelectContentsSelector, selectCurrent } from '../../Selectors';

import Item from './Item';
import PrintItem from './PrintItem';

import ImageWrapper from './ImageWrapper';
import { SetGoToPage, setContentValue, AddImageContentToBox, SetGoToLastPage, RemoveContentFromBox, SetRemoveActivePage } from '../../actions';

import DefaultItem from './DefaultItem';
import EditorItem from './EditorItem';

import { createSelector } from 'reselect';
import ItemSwitch from './ItemSwitch';


const PageCarouselV2 = React.memo((props) => {
    //Props
    const { boxId } = props;
    const viewId = "";
    const pageId = "";
    const filterForImagesOnly = false;

    //Console
    //console.log("PageCarouselV2 rendered", boxId);

    //Use
    const dispatch = useDispatch();

    //Selectors           
    const selectContents = state => state.entities.contents.allIds.map(id => state.entities.contents.byId[id]);
    const makeSelectContentIdsSelector = () => createSelector(
        selectContents,
        (_, boxId) => boxId,
        (_, __, viewId) => viewId,
        (_, __, ___, pageId) => pageId,
        (_, __, ___, ____, filterForImagesOnly) => filterForImagesOnly,
        (contents, boxId, viewId, pageId, filterForImagesOnly) => {
            let contentsToFilter = contents

            if (boxId && boxId.length && boxId.length > 0)
                contentsToFilter = contentsToFilter.filter(content => content.boxId === boxId)

            if (viewId && viewId.length && viewId.length > 0)
                contentsToFilter = contentsToFilter.filter(content => content.viewId === viewId)

            if (pageId && pageId.length && pageId.length > 0)
                contentsToFilter = contentsToFilter.filter(content => content.pageId === pageId)

            if (filterForImagesOnly)
                contentsToFilter = contentsToFilter.filter(content => content.type === "image")

            return contentsToFilter.map(content => content.id)
        }
    );
    const selectContentsSelector = useMemo(makeSelectContentIdsSelector, [boxId, viewId, pageId, filterForImagesOnly])
    const contentIds = useSelector((state) => selectContentsSelector(state, boxId, viewId, pageId, filterForImagesOnly))
    const current = useSelector(selectCurrent);

    //const selectContentIds = createSelector(state => state.entities.contents.allIds);



    // Const & Var
    const { v4: uuidv4 } = require('uuid');
    const breakPoints = [
        { width: 1, itemsToShow: 1 }
    ];
    let carousel;// = useRef();
    //const fileInput = useRef(null);

    //Handlers    

    useEffect(() => {
        if (current.boxId === boxId && carousel) {

            if (current.gotopage === "NEXT") {
                dispatch(SetGoToPage(boxId, ""));
                carousel.slideNext();
            }

            if (current.gotopage === "PREV") {
                dispatch(SetGoToPage(boxId, ""));
                carousel.slidePrev();
            }

            if (current.gotopage === "LAST") {
                dispatch(SetGoToPage(boxId, ""));
                carousel.goTo(999);
            }

            if (current.gotopage === "FIRST") {
                dispatch(SetGoToPage(boxId, ""));
                carousel.goTo(0);
            }

            if (current.gotolastpage) {
                dispatch(SetGoToLastPage(boxId, false));
                carousel.goTo(999);
            }

            if (current.removeactivepage && contentIds[carousel.state.activePage]) {
                dispatch(SetRemoveActivePage(boxId, false))
                dispatch(RemoveContentFromBox(boxId, contentIds[carousel.state.activePage]))
            }

        }
    }, [current]);

    //Check
    //console.log("contents", contents)

    //Special
    let multiplier = 1;

    if (current.focusOnBox)
        multiplier = 3.5;

    return (
        <PageCarrouselWrapper>

            {
                contentIds.map(contentId => {
                    return (
                        <ItemSwitch
                            key={contentId}
                            boxId={boxId}
                            contentId={contentId}
                            multiplier={multiplier}
                            print={true}

                        />
                    )
                })

            }

        </PageCarrouselWrapper >
    );

});

export default PageCarouselV2;

/*


*/

/*
                <Item>
                    <PageLayoutV2
                        boxId="mockBoxId1"
                        viewId="mockViewId1"
                        pageId="mockPageId1"
                        filterForImagesOnly={true}
                    //onLayoutChange={handleLayoutChange}
                    />
            </Item>

            <Image src={content.localUrl || content.remoteUrl || content.value} fluid />



                                <Item>
                    <CKEditor
                        editor={BalloonEditor}
                        data=""//{this.props.data}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            //console.log( 'Editor is ready to use!', editor );
                        }}
                        config={{
                            fontSize_defaultLabel: '88px'
                        }}
                        onChange={(event, editor) => {
                            //const data = editor.getData();
                            //this.props.onCKEditorItemChange(data);
                            //console.log( { event, editor, data } );
                        }}
                        onBlur={(event, editor) => {
                            //console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                            //console.log( 'Focus.', editor );
                        }}
                    />
                </Item>
                <Item>Demo Item 2</Item>


                ------------------

                                        //Quality checks
                        /*
                        let canUseLocalUrl = true;

                        if (content.type === "image") {
                            if ((content.localUrl && content.localUrl.length > 0) || (content.remoteUrl && content.remoteUrl.length > 0) || (content.value && content.value.length > 0)) {
                                const axios = require('axios');

                                // Make a request for a user with a given ID
                                axios.get(content.localUrl || content.remoteUrl || content.value)
                                    .then(function (response) {
                                        // handle success
                                    })
                                    .catch(function (error) {
                                        // handle error
                                        canUseLocalUrl = false;
                                        enqueueSnackbar("Error: Image not available. Removing the reference.", {
                                            variant: 'error',
                                            preventDuplicate: true
                                        });
                                    });

                            }
                        }
                */

