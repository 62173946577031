import React, { useState, useRef, useEffect } from 'react';

import Aside from './containers/Aside';
import Main from './Main';

function Layout2({ setLocale }) {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? 'ar' : 'en');
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const myref = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (width !== myref.current.offsetWidth)
      setWidth(myref.current.offsetWidth)

    if (height !== myref.current.offsetHeight)
      setHeight(myref.current.offsetHeight)

    const handleResize = () => {
      if (width !== myref.current.offsetWidth)
        setWidth(myref.current.offsetWidth)

      if (height !== myref.current.offsetHeight)
        setHeight(myref.current.offsetHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myref.current]);

  return (
    <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`} ref={myref}>
      <Aside
        image={false}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />

      <Main
        screenWidth={width}
        screenHeight={height}
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        rtl={rtl}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleRtlChange={handleRtlChange}
        handleImageChange={handleImageChange}
      />

    </div>
  );
}

export default Layout2;
