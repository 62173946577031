import styled from 'styled-components';

const PageCarrouselWrapper = styled.div`
    //background-color: green;

`;

export default PageCarrouselWrapper;

/*

    height:335px;
    max-height: 335px;
    min-width: 250px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    font-size: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #dae136;
    //color: #3385FF;
    //transition:  1s ease-in-out;

*/

/*
@media print {
    //display: show;
    //background-size: 100% auto;
    //align-content: stretch;

    //border - bottom - left - radius: 5px;
    //border - bottom - right - radius: 5px;
    //border: 1px solid #dae136;

    padding 8px;
    //background: blue;
    //margin: 0px 0px 16px 0px;
}

    @media print {
        width: 842pt;
        height: 520pt;

    @page {
        size: A4 landscape;
    }
*/