import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: []
};

const pagecontents = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.SET_A3_NORMALIZED:
        return produce(state, draft => {
          draft.byId = {
            ...draft.byId, ...action.payload.pagecontents.byId
          };
          draft.allIds = [...new Set([...draft.allIds, ...action.payload.pagecontents.allIds])];
        });
      /*
            case Types.ADD_CONTENT_TO_BOX:
            case Types.ADD_IMAGE_CONTENT_TO_BOX:
            case Types.ADD_RANDOMIMAGE_CONTENT_TO_BOX:
            case Types.ADD_EDITOR_CONTENT_TO_BOX:
              draft.byId = {
                ...draft.byId, ...action.content
              };
      
              draft.allIds = [...draft.allIds, action.contentId];
              break;
      */

      //SET_WIDTH_HEIGHT_FOR_PAGE_CONTENT

      case Types.SET_VALUES_FOR_PAGECONTENT:
        return produce(state, draft => {
          if (draft.byId[action.pagecontentId]) {
            draft.byId[action.pagecontentId].pageId = action.pageId;
            draft.byId[action.pagecontentId].contentId = action.contentId;
            draft.byId[action.pagecontentId].x = action.x;
            draft.byId[action.pagecontentId].y = action.y;
            draft.byId[action.pagecontentId].width = action.width;
            draft.byId[action.pagecontentId].height = action.height;
            draft.byId[action.pagecontentId].sort = action.sort;
          }
          else {
            let newPageContent = {};
            newPageContent.id = action.pagecontentId;
            newPageContent.pageId = action.pageId;
            newPageContent.contentId = action.contentId;
            newPageContent.x = action.x;
            newPageContent.y = action.y;
            newPageContent.width = action.width;
            newPageContent.height = action.height;
            newPageContent.sort = action.sort;

            draft.byId = {
              ...draft.byId,
              [action.pagecontentId]: newPageContent
            };

            draft.allIds = [...new Set([...draft.allIds, action.pagecontentId])];
          }
        });

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

      default:
        break;
    }
  });
}

export default pagecontents