import styled from 'styled-components';

const Box6Content = styled.div`
    position: absolute;
    top: calc(492pt + var(--offset-alvance-print-top));    
    left: calc(302pt + var(--offset-alvance-print-left));
    width: 554.5pt;
    height: 218.5pt;
    line-height: 7.5pt;
    
    font-size: 6.75pt;
    font-family: Arial;
    
    padding-left: 3pt;    
    padding-top: 10pt;   
    
    overflow: hidden;
    
    p {
        font-size: 6.75pt;
    }

    table{
        position: absolute;
        top: 12pt;
        left: 5pt;
        max-width: 545.5pt;    
        max-height: 200pt;    
        table-layout:fixed;
        width: 100% !important;
        height: 100% !important;
        //zoom: 0.7;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transform: scaleX(1) scaleY(1);
    }

    tr:first-child td {
        border: 0.5pt solid black;
        font-weight: 900;
        font-size: 7pt;
        text-align: center;    
        height: 1pt;      
    }

    tr{        
        border: 0.5pt solid black;        
    }

    td{        
        border: 0.5pt solid black;        
    }  
`;

export default Box6Content;
