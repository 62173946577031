import React from "react";
import { useDispatch } from 'react-redux';

import { SetRemoveActivePage } from '../../actions'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from '@fortawesome/free-solid-svg-icons'

import Icon from "./Icon";

const RemovePageIcon = React.memo((props) => {
    //Console
    //console.log("RemoveFocusIcon rendered");

    //Props
    const { boxId } = props;

    //Use
    const dispatch = useDispatch();

    //Handlers
    function handleClickRemovePage() {
        dispatch(SetRemoveActivePage(boxId, true))
    }

    return (
        <Icon className="no-print">
            <FontAwesomeIcon
                icon={faMinus}
                title="Remove active page"
                color="white"
                style={{
                    width: '20px',
                    height: '20px',
                    padding: '1px 1px 1px 1px'
                }}
                onClick={() => handleClickRemovePage(boxId)}
            />
        </Icon>

    );
});

export default RemovePageIcon;