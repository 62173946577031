import styled from 'styled-components';

const Wrapper = styled.div`
min-width: 200px;
height: 36px;
//height: 2.5vh;
background-color: var(--boxheader-background-color);
display: flex;
align-items: center;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
//filter: drop-shadow(30px 10px 4px #4444dd);

//color: ${props => (props.print ? 'black' : 'white')};


`;

export default Wrapper;
