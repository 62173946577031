import styled from 'styled-components';

const MetaCoachContent = styled.div`
    position: absolute;
    top: calc(76pt + var(--offset-alvance-print-top));        
    left: calc(837pt + var(--offset-alvance-print-left));
    width: 196.5pt;
    height: 20.25pt;
    line-height: 17pt;
    
    font-size: 10pt;
    font-family: Arial;
    
    padding-top: 2pt;
    padding-left: 37pt;
        
`;

export default MetaCoachContent;
