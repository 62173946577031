import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Article from "../../components/Article";
import Clap from "../../components/Clap";
import { fetchArticleDetails, fetchA3s } from "../../actions";

const StyledApp = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;

  aside {
    min-width: 35vh;
    display: flex;
    justify-content: flex-end;
  }
  main {
    flex: 1 0 350px;
    ${"" /* not responsive */} padding: 0 5rem;
  }
`;

class FakeMediumApp extends Component {
  //state = {};
  componentDidMount() {
    //this.props.fetchArticleDetails();
    this.props.fetchA3s();
  }
  render() {
    //console.log(this.props.isLoadingData);
    //console.log(this.props.data);

    const { title, subtitle, paragraphs } = this.props.data;
    const countTotal = this.props.data.numberOfRecommends;

    return (
      <StyledApp>
        <aside>
          <Clap countTotal={countTotal} />
        </aside>
        <main>
          {this.props.isLoadingData ? (
            "Loading . . ."
          ) : (
              <Article
                title={title}
                subtitle={subtitle}
                paragraphs={paragraphs}
              />
            )}
        </main>
      </StyledApp>
    );
  }
}

const mapStateToProps = ({ data = {}, isLoadingData = false }) => ({
  data,
  isLoadingData
});

const mapDispatchToProps = (dispatch, ownProps) => {
  //console.log("mapDispatchToProps called")
  //return { fetchArticleDetails }
}

export default connect(
  mapStateToProps,
  {
    fetchA3s,
    //fetchArticleDetails
  }
  //{ fetchArticleDetails, mapDispatchToProps }
)(FakeMediumApp);
