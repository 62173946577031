import styled from 'styled-components';

const BoxRow = styled.div`

padding: 5px 0px 0px 0px;

flex-direction: col;
//background-color: green;

  //display: -ms-flexbox; /* IE 10 */
  display: flex;
  //-ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;


    content: "";
  //display: table;
  clear: both;

  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    
    }      
  
`;

export default BoxRow;

/*
      visible: visible;
    -webkit-print-color-adjust: exact !important;
  color - adjust: exact!important;
* /

/*
@media (min-width: 900px) {
    //display: block;
    flex-direction: row;
    width: 100%;
}
*/