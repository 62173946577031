import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: []
};

const files = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.RESET_TO_INITIALSTATE:
        return initialState;
    }
  });
}

export default files