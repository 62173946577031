import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAuth0 } from "@auth0/auth0-react";

import { setA3id, fetchA3, setA3, saveA3Image } from './actions'

import Layout from './Layout';
import hotkeys from 'hotkeys-js';

const A3 = (props) => {
	const verboseLog = false;
	const vLog = s => { if (verboseLog) console.log(s); }

	const dispatch = useDispatch();
	const a3v2 = useSelector(state => state.a3);

	const { user, isAuthenticated } = useAuth0();
	const [focusBox, setfocusBox] = React.useState(-1);
	//const [a3id, setA3Id] = useState(props.a3id);
	//const [a3, setA3] = useState([]);
	const a3 = useSelector(state => state.a3.data);

	//Save the a3id to state
	useEffect(() => {
		if (!a3v2 || !a3v2.a3id || a3v2.a3id !== props.a3id) {
			dispatch(setA3id(props.a3id));

			/*if (isAuthenticated)
				dispatch(fetchA3(props.a3id, user.email));*/
		}
	}, [props.a3id, user, dispatch, a3v2, isAuthenticated]);

	//Fetch the a3 with a3id
	useEffect(() => {
		if (a3v2.a3id !== "undefined")
			if (a3v2.a3id)
				if (a3v2.a3id.length)
					if (a3v2.data !== "undefined")
						if (!a3v2.data.length)
							if (isAuthenticated)
								dispatch(fetchA3(a3v2.a3id, user.email));

	}, [a3v2, isAuthenticated, user, dispatch]);

	function getBase64(file) {
		vLog("A3 - getBase64 called");
		var reader = new FileReader();
		reader.readAsText(file);
		reader.onload = function () {
			var result = reader.result;

			//Remove the files from the result
			let a3 = JSON.parse(result);
			if (a3 && a3.files && a3.files.length) {
				for (var i = 0; i <= a3.files.length; i++) {
					if (a3.files[i])
						dispatch(saveA3Image(a3.files[i].uuid, user.email, a3.files[i].data))
				}

				delete a3.files;
			}

			dispatch(setA3(JSON.stringify(a3)));
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const handleFileFromPC = (file) => {
		getBase64(file)
	}

	function handleLayoutChange(param) {
		vLog("A3 - handleLayoutChange called");

		var newJson = JSON.parse(param);
		//setA3(JSON.stringify(newJson));
		dispatch(setA3(JSON.stringify(newJson)));
	}

	function handleTitleChange(param) {
		vLog("A3 - handleTitleChange called");

		var newJson = JSON.parse(a3);
		newJson.title = param;
		setA3(JSON.stringify(newJson));

		//console.log(newJson);
	}

	function handleSponsorChange(param) {
		vLog("A3 - handleSponsorChange called");
		var newJson = JSON.parse(a3);
		newJson.sponsor = param;
		setA3(JSON.stringify(newJson));
	}

	function handleProcessOwnerChange(param) {
		vLog("A3 - handleProcessOwnerChange called");
		var newJson = JSON.parse(a3);
		newJson.processOwner = param;
		setA3(JSON.stringify(newJson));
	}

	function addCkEditorItemToCarrousel(i) {
		vLog("A3 - addCkEditorItemToCarrousel called");
		//console.log(i);

		var newJson = JSON.parse(a3);
		//console.log(newJson.box[i].content);
		newJson.box[i].content.push({ 'value': 'New box', 'type': 'editor' });
		//setA3(JSON.stringify(newJson));

		dispatch(setA3(JSON.stringify(newJson)));

	}

	const handleSaveToPC = jsonData => {
		vLog("A3 - handleSaveToPC called");

		const fileData = JSON.stringify(jsonData);
		const blob = new Blob([fileData], { type: "text/plain" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = JSON.parse(jsonData).title.concat(".json");
		link.href = url;
		link.click();
	}

	function handleFocusBoxChange(fb) {
		vLog("A3 - handleFocusBoxChange called");

		focusBox !== fb ? setfocusBox(fb) : setfocusBox(-1);
	}

	hotkeys('ctrl+s, f1, f2, f3, f4, f5, f6, f7, f8, f9', function (event, handler) {
		// Prevent the default refresh event under WINDOWS system
		event.preventDefault()
		switch (handler.key) {
			case 'ctrl+s': handleSaveToPC(a3);
				break;
			case 'f1': focusBox !== 0 ? setfocusBox(0) : setfocusBox(-1);
				break;
			case 'f2': focusBox !== 1 ? setfocusBox(1) : setfocusBox(-1);
				break;
			case 'f3': focusBox !== 2 ? setfocusBox(2) : setfocusBox(-1);
				break;
			case 'f4': focusBox !== 3 ? setfocusBox(3) : setfocusBox(-1);
				break;
			case 'f5': focusBox !== 4 ? setfocusBox(4) : setfocusBox(-1);
				break;
			case 'f6': focusBox !== 5 ? setfocusBox(5) : setfocusBox(-1);
				break;
			case 'f7': focusBox !== 6 ? setfocusBox(6) : setfocusBox(-1);
				break;
			case 'f8': focusBox !== 7 ? setfocusBox(7) : setfocusBox(-1);
				break;
			case 'f9': focusBox !== 8 ? setfocusBox(8) : setfocusBox(-1);
				break;
			default: ;

		}

	});
	/*
		React.useEffect(() => {
	
			if (isAuthenticated && (a3 === null || a3.length === 0)) {
				axios.post(process.env.REACT_APP_API_URL.concat('/api/getA3'), {
					email: user.email,
					uuid: a3id,
				}, {
					'Access-Control-Allow-Origin': '*',
				})
					.then(function (response) {
						//console.log(response);                        
						//setIsLoaded(true);
						setA3(response.data.json);
						//setUuid(response.data.uuid);
					})
					.catch(function (error) {
						console.log(error);
						//setIsLoaded(true);
						//setError(error);
					})
					.then(function () {
						// always executed
					});
			}
		}, []);
	*/
	return (
		<div>
			{a3 !== "undefined" && a3 && a3.length && a3.length > 0 ?
				<Layout
					a3id={props.a3id}
					data={JSON.parse(a3)}
					focusBox={focusBox}
					onFocusBoxChange={handleFocusBoxChange}
					onLayoutChange={handleLayoutChange}
					onTitleChange={handleTitleChange}
					onSponsorChange={handleSponsorChange}
					onProcessOwnerChange={handleProcessOwnerChange}
					onAddCkEditorItemToCarrousel={addCkEditorItemToCarrousel}
					onFileFromPC={handleFileFromPC}
				/>
				: null
			}
		</div>
	);
}

export default A3;