import styled from 'styled-components';

const Logo = styled.div`
    position: absolute;
    top: calc(57pt + var(--offset-alvance-print-top));        
    left: calc(50pt + var(--offset-alvance-print-left));
    width: 1100.5pt;    // 1185pt;//
    height: 709pt;      // 836pt; //

    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
     
`;

export default Logo;
