export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const FETCH_ARTICLE_DETAILS = "FETCH_ARTICLE_DETAILS";
export const SET_ARTICLE_DETAILS = "SET_ARTICLE_DETAILS";

export const FETCH_A3S = "FETCH_A3S";
export const SET_A3S = "SET_A3S";

export const SET_A3ID = "SET_A3ID";
export const FETCH_A3 = "FETCH_A3";
export const SET_A3 = "SET_A3";

export const FETCH_A3IMAGE = "FETCH_A3IMAGE";
export const SET_A3IMAGE = "SET_A3IMAGE";
export const SAVE_A3IMAGE = "SAVE_A3IMAGE";

export const GET_A3S_FROM_API = "GET_A3S_FROM_API";
export const SET_A3S_NORMALIZED = "SET_A3S_NORMALIZED";

export const NEW_A3 = "NEW_A3";
export const NEW_A3_FROM_EMPTY = "NEW_A3_FROM_EMPTY";
export const NEW_A3_FROM_API_TEMPLATE = "NEW_A3_FROM_API_TEMPLATE";

export const SAVE_TEMPLATE_TO_API = "SAVE_TEMPLATE_TO_API";

export const LOAD_A3_FROM_API = "LOAD_A3_FROM_API";
export const SAVE_A3_TO_API = "SAVE_A3_TO_API";

export const ADD_PAGE_TO_BOX = "ADD_PAGE_TO_BOX";
export const STORE_LAYOUT = "STORE_LAYOUT";

export const SET_ACTIVE_META_OF_A3 = "SET_ACTIVE_META_OF_A3";
export const ADD_META_TO_A3 = "ADD_META_TO_A3";
export const UPDATE_META_OF_A3 = "UPDATE_META_OF_A3";
export const REMOVE_META_FROM_A3 = "REMOVE_META_OF_A3";

export const SET_ACTIVE_BOX_OF_A3 = "SET_ACTIVE_BOX_OF_A3";
export const ADD_BOX_TO_A3 = "ADD_BOX_TO_A3"; //Done - Normalised
export const UPDATE_BOX_OF_A3 = "UPDATE_BOX_OF_A3";
export const REMOVE_BOX_FROM_A3 = "REMOVE_BOX_OF_A3";

export const SET_TITLE_OF_BOX = "SET_TITLE_OF_BOX"; //Done
export const SET_ACTIVE_VIEW_OF_BOX = "SET_ACTIVE_VIEW_OF_BOX";
export const ADD_VIEW_TO_BOX = "ADD_VIEW_TO_BOX"; //Done - Light version for 1 view only
export const UPDATE_VIEW_OF_BOX = "UPDATE_VIEW_OF_BOX";
export const REMOVE_VIEW_FROM_BOX = "REMOVE_VIEW_FROM_BOX";
export const ADD_CONTENT_TO_BOX = "ADD_CONTENT_TO_BOX";
export const UPDATE_CONTENT_ON_BOX = "UPDATE_CONTENT_ON_BOX";
export const REMOVE_CONTENT_FROM_BOX = "REMOVE_CONTENT_FROM_BOX";
export const REMOVE_ERROR_BOXES = "REMOVE_ERROR_BOXES"; //Done

export const SET_ACTIVE_PAGE_OF_VIEW = "SET_ACTIVE_PAGE_OF_VIEW";
export const ADD_PAGE_TO_VIEW = "ADD_PAGE_TO_VIEW";
export const SET_LAYOUT_OF_PAGE = "SET_LAYOUT_OF_PAGE";
export const UPDATE_PAGE_OF_VIEW = "UPDATE_PAGE_OF_VIEW";
export const REMOVE_PAGE_FROM_VIEW = "REMOVE_PAGE_FROM_VIEW";

export const SET_ACTIVE_CONTENT_ON_PAGE = "SET_ACTIVE_CONTENT_ON_PAGE";
export const ADD_CONTENT_TO_PAGE = "ADD_CONTENT_TO_PAGE";
export const UPDATE_CONTENT_ON_PAGE = "UPDATE_CONTENT_ON_PAGE";
export const REMOVE_CONTENT_FROM_PAGE = "REMOVE_CONTENT_FROM_PAGE";
export const SET_COMPACTTYPE_OF_PAGE = "SET_COMPACTTYPE_OF_PAGE";

export const ADD_FILE_TO_A3 = "ADD_FILE_TO_A3";
export const UPDATE_FILE_OF_A3 = "UPDATE_FILE_OF_A3";
export const REMOVE_FILE_FROM_A3 = "REMOVE_FILE_FROM_A3";

export const ADD_TODO_TO_A3 = "ADD_TODO_TO_A3";
export const UPDATE_TODO_OF_A3 = "UPDATE_TODO_OF_A3";
export const REMOVE_TODO_FROM_A3 = "REMOVE_TODO_FROM_A3";

export const AUTH_SET_USER = "AUTH_SET_USER";
export const AUTH_SET_ISAUTHENTICATED = "AUTH_SET_ISAUTHENTICATED";

export const REMOVE_A3_FROM_API = "REMOVE_A3_FROM_API";

export const SET_CURRENT_A3 = "SET_CURRENT_A3";
export const CREATE_EMPTY_A3 = "CREATE_EMPTY_A3";
export const REMOVE_A3_FROM_STATE = "REMOVE_A3_FROM_STATE";

export const INCREASE_FONTSIZE_CKEDITOR = "INCREASE_FONTSIZE_CKEDITOR";
export const DECREASE_FONTSIZE_CKEDITOR = "DECREASE_FONTSIZE_CKEDITOR";
export const SET_FONTSIZE_CKEDITOR = "SET_FONTSIZE_CKEDITOR";
export const RESET_FONTSIZE_CKEDITOR = "RESET_FONTSIZE_CKEDITOR";

export const SET_VALUE_OF_CONTENT = "SET_VALUE_OF_CONTENT";
export const SET_VALUE_OF_CONTENT_LATEST = "SET_VALUE_OF_CONTENT_LATEST";
export const ADD_EDITOR_CONTENT_TO_BOX = "ADD_EDITOR_CONTENT_TO_BOX";
export const ADD_IMAGE_CONTENT_TO_BOX = "ADD_IMAGE_CONTENT_TO_BOX";
export const ADD_RANDOMIMAGE_CONTENT_TO_BOX = "ADD_RANDOMIMAGE_CONTENT_TO_BOX";
export const SET_GOTOLASTPAGE = "SET_GOTOLASTPAGE";
export const SET_REMOVEACTIVEPAGE = "SET_REMOVEACTIVEPAGE";
export const SET_GOTO_PAGE = "SET_GOTO_PAGE";
export const SET_FOCUS_ON_BOX = "SET_FOCUS_ON_BOX";
export const REMOVE_FOCUS_FROM_BOX = "REMOVE_FOCUS_FROM_BOX";
export const RESET_TO_INITIALSTATE = "RESET_TO_INITIALSTATE";
export const SET_BOX_GO = "SET_BOX_GO";

export const SAVE_A3V2_TO_API = "SAVE_A3V2_TO_API"
export const LOAD_A3V2_FROM_API = "LOAD_A3V2_FROM_API";
export const SET_A3_NORMALIZED = "SET_A3_NORMALIZED";

export const KEEP_ALIVE = "KEEP_ALIVE";
export const SET_META_VALUE = "SET_META_VALUE";
export const SET_CONTENT_VALUE = "SET_CONTENT_VALUE";
export const NEW_A3_FROM_TEMPLATE_API = "NEW_A3_FROM_TEMPLATE_API";
export const SHARE_A3 = "SHARE_A3";
export const SET_VALUES_FOR_PAGECONTENT = "SET_VALUES_FOR_PAGECONTENT";

export const GET_DB_INFO = "GET_DB_INFO";
export const SET_DB = "SET_DB";

export const SET_A3_TITLE = "SET_A3_TITLE";