import styled from 'styled-components';

const Box = styled.div`
    position: absolute;
    top: calc(${props => props.topOffset} + var(--offset-alvance-print-top));    
    left: calc(${props => props.leftOffset} + var(--offset-alvance-print-left));
    width: ${props => props.width};
    height: ${props => props.height};
    line-height: 7.5pt;
    border-top: 3pt solid black;
    border-left: 3pt solid black;
    border-right: 3pt solid black;
    border-bottom: 3pt solid black;
    font-size: 6.75pt;
    font-family: Arial;
    //font-weight: bold;
    text-decoration: underline;
    padding-top: 1pt;    
    padding-left: 2pt;    

`;

export default Box;
