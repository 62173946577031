import styled from 'styled-components';

const BoxWrapper = styled.div`

@media print {    
    justify-content: center;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    padding: 8pt;
    page-break-after: always;    
}


`;

export default BoxWrapper;

/*
@media print {
    width: 842pt;
    height: 595pt;
    //padding: 8pt;

    @page {
        size: A4 landscape;
        margin: 0.5cm;
    }
}

@media print {

    @page {
        size: A4 landscape;
        margin: 0.5cm;
    }
}

*/