import produce from 'immer';
import * as Types from "../actions/types";

const initialFonSize = "8px";

const initialState = {
  "tabId": "",
  "a3Id": "",
  "metaId": "",
  "boxId": "",
  "viewId": "",
  "pageId": "",
  "fontSizeCKEditor": initialFonSize,
  "gotolastpage": false,
  "removeactivepage": false,
  "gotopage": "",
  "focusOnBox": false,
  "db": ""
};

const fontSizeJumps = 1;

const current = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_CURRENT_A3:
        draft.a3Id = action.a3Id;
        break;

      case Types.INCREASE_FONTSIZE_CKEDITOR:
        draft.fontSizeCKEditor = (parseInt(draft.fontSizeCKEditor.replace("px", "")) + fontSizeJumps).toString().concat("px")
        break;

      case Types.DECREASE_FONTSIZE_CKEDITOR:
        let newSize = parseInt(draft.fontSizeCKEditor.replace("px", ""))
        //newSize = newSize - 3 <= 1 ? 1 : newSize - 3
        newSize = newSize - fontSizeJumps;
        draft.fontSizeCKEditor = newSize.toString().concat("px")
        break;

      case Types.SET_FONTSIZE_CKEDITOR:
        draft.fontSizeCKEditor = action.fontSize.toString().concat("px")
        break;

      case Types.RESET_FONTSIZE_CKEDITOR:
        draft.fontSizeCKEditor = initialFonSize;
        break;

      case Types.SET_GOTOLASTPAGE:
        draft.gotolastpage = action.value;
        draft.boxId = action.boxId;
        break;

      case Types.SET_REMOVEACTIVEPAGE:
        draft.removeactivepage = action.value;
        draft.boxId = action.boxId;
        break;

      case Types.SET_GOTO_PAGE:
        draft.gotopage = action.value;
        draft.boxId = action.boxId;
        break;

      case Types.SET_FOCUS_ON_BOX:
        draft.focusOnBox = true;
        draft.boxId = action.boxId;
        break;

      case Types.REMOVE_FOCUS_FROM_BOX:
        draft.focusOnBox = false;
        draft.boxId = "";
        break;

      case Types.CREATE_EMPTY_A3:
        draft.focusOnBox = false;
        draft.boxId = "";
        break;

      case Types.SET_DB:
        draft.db = action.payload;
        break;

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

      default:
        break;
    }
  });
}

export default current