import styled from 'styled-components';

const Wrapper = styled.div`
    //width: ${props => props.boxWidth};
    height:81%;        
    overflow: hidden;
    padding: 5px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #dae136;
    background-size: 100% auto;
    margin: 0px 0px 16px 0px;    
`;

export default Wrapper;

/*
    //height: 28vh;
    //height: calc(25vw * (595/842));
    //min-height: 250px;
    //max - height: 335px;
    //min - width: 250px;
    overflow: hidden;
    padding: 5px;
    //font - size: 25px;
    border - bottom - left - radius: 5px;
    border - bottom - right - radius: 5px;
    border: 1px solid #dae136;
    //color: #3385FF;
    //transition:  1s ease-in-out;
    //display: flex;
    background-size: 100% auto;

    //justify-content: center;
    //align-items: center;

    //Development
    //background-color: green;
    margin: 0px 0px 16px 0px;
*/