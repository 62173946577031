import styled from 'styled-components';

const MetaPlantLabel = styled.div`
    position: absolute;
    top: calc(57pt + var(--offset-alvance-print-top));        
    left: calc(647.5pt + var(--offset-alvance-print-left));
    width: 190.25pt;
    height: 20pt;
    line-height: 17pt;
    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
    font-size: 10pt;
    font-family: Arial;
    font-weight: bold;
    padding-top: 1pt;
    padding-left: 1.5pt;
    
    
`;

export default MetaPlantLabel;
