import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import BoxGridV2 from '../../components/BoxGridV2';

const MockBoxGrid = () => {
    const a3Id = "a3Id1"

    return (
        <ComponentWrapper>
            <BoxGridV2
                a3Id={a3Id}
            />
        </ComponentWrapper>
    );

}

export default MockBoxGrid;
