import { createSelector } from 'reselect';
//import { state } from './reducers';

const selectGlobal = state => state.global;
const selectA3sState = state => state.a3s;

const selectRouter = state => state.router;

const makeSelectLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.loading,
    );

const makeSelectError = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.error,
    );

const makeSelectLocation = () =>
    createSelector(
        selectRouter,
        routerState => routerState.location,
    );

const selectA3s = () =>
    createSelector(
        selectA3sState,
        a3State => a3State,
    );

const selectBoxes = state => state.entities.boxes.allIds.map(id => state.entities.boxes.byId[id]);
const makeSelectBoxesSelector = () => createSelector(
    selectBoxes,
    (_, boxId) => boxId,
    (boxes, boxId) => {
        let boxesToFilter = boxes.filter(box => box)

        if (boxId && boxId.length && boxId.length > 0)
            boxesToFilter = boxesToFilter.filter(box => box.id.toLowerCase() === boxId.toLowerCase())
        //boxesToFilter = boxesToFilter.filter(box => box.boxId === boxId)


        return boxesToFilter
    }
);

const makeSelectBoxesSelectorByA3Id = () => createSelector(
    selectBoxes,
    (_, a3Id) => a3Id,
    (boxes, a3Id) => {
        let boxesToFilter = boxes.filter(box => box);

        if (a3Id && a3Id.length && a3Id.length > 0)
            boxesToFilter = boxesToFilter.filter(box => box.a3Id.toLowerCase() === a3Id.toLowerCase())
        else
            boxesToFilter = []

        return boxesToFilter
    }
);

const selectCurrent = state => state.current;
const makeSelectCurrentSelector = () => createSelector(
    selectCurrent
);

const selectContents = state => state.entities.contents.allIds.map(id => state.entities.contents.byId[id]);
const makeSelectContentsSelector = () => createSelector(
    selectContents,
    (_, boxId) => boxId,
    (_, __, viewId) => viewId,
    (_, __, ___, pageId) => pageId,
    (_, __, ___, ____, filterForImagesOnly) => filterForImagesOnly,
    (contents, boxId, viewId, pageId, filterForImagesOnly) => {
        let contentsToFilter = contents

        if (boxId && boxId.length && boxId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.boxId.toLowerCase() === boxId.toLowerCase())

        if (viewId && viewId.length && viewId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.viewId.toLowerCase() === viewId.toLowerCase())

        if (pageId && pageId.length && pageId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.pageId.toLowerCase() === pageId.toLowerCase())

        if (filterForImagesOnly)
            contentsToFilter = contentsToFilter.filter(content => content.type === "image")

        return contentsToFilter
    }
);


export {
    selectBoxes,
    makeSelectBoxesSelector,
    makeSelectBoxesSelectorByA3Id,
    selectCurrent,
    makeSelectCurrentSelector,
    selectContents,
    makeSelectContentsSelector,

    selectGlobal,
    selectA3s,
    makeSelectLoading,
    makeSelectError,
    makeSelectLocation,
};
