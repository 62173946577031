import styled from 'styled-components';

const BoxGo = styled.div`
    position: absolute;
    top: calc(${props => props.topOffset} + var(--offset-alvance-print-top));    
    left: calc(57pt + var(--offset-alvance-print-left));
    
    height: 30px;
    width: 30px;    
    background-color: ${props => props.go ? "lightgreen" : "white"} ;

    border: 1pt solid black;
    border-color: ${props => props.go ? "darkgreen" : "black"} ;
    border-radius: 50%;
    display: inline-block;

    padding-left: 6.25pt;    
    padding-top: 2.5pt;    

    font-size: 14pt;
    font-family: Arial;
`;

export default BoxGo;
