import styled from 'styled-components';

const ImageWrapper = styled.div`
    width: 100%;    
    height: 100%;        
    
    background-image: url("${props => props.src}");    
    background-size: contain;
    background-repeat: no-repeat;        
    background-position: center center;        
    position:absolute;     
    float: center;
    bottom:0;
    //right:0;       
    //zoom:75%;  



}
`;

export default ImageWrapper;

/*
            @media print {

    page-break-before: auto;
    page -break-after: auto;
    page -break-inside: avoid;
    }
*/
