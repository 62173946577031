import styled from 'styled-components';

const PrintSinglePage = styled.div`
  @media print {
    padding: 8px !important;
    page-break-after: always;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */  
    page-break-inside: avoid; /* or 'auto' */
  }


`;

export default PrintSinglePage;
