import styled from 'styled-components';

const Left = styled.div`
    //display: inline-block;
    flex-grow: 1;
    padding: 5px 0 0 10px;

`;

export default Left;
