///import { call, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import { put, all, throttle } from 'redux-saga/effects'

function* setLastContentValue(action) {
    //yield delay(5000)
    //console.log("SET_VALUE_OF_CONTENT", action.value)
    yield put({
        type: "SET_VALUE_OF_CONTENT",
        contentId: action.contentId,
        value: action.value
    })
}

function* watchInput() {
    yield throttle(1000, 'SET_VALUE_OF_CONTENT_SAGA', setLastContentValue)
    //yield takeLatest('SET_VALUE_OF_CONTENT_SAGA', setLastContentValue)
}

export default function* rootSaga() {
    yield all([
        watchInput()
    ])
}