import styled from 'styled-components';

const Box2Content = styled.div`
    position: absolute;
    top: calc(276pt + var(--offset-alvance-print-top));    
    left: calc(88pt + var(--offset-alvance-print-left));
    width: 214pt;
    height: 216.5pt;
    line-height: 7.5pt;

    font-size: 6.75pt;
    font-family: Arial;

    background: transparent;
    
    padding-left: 3pt;    
    padding-top: 10pt;    

    p {
        font-size: 6.75pt;
    }

    table{
        //display: table; 
        max-width: 490pt;    
        max-height: 200pt;    
        table-layout:fixed;
        width: 100% !important;
        height: 100% !important;
        //zoom: 0.7;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transform: scaleX(1) scaleY(1);
    }

    tr:first-child td {
        border: 0.5pt solid black;
        font-weight: 900;
        font-size: 7pt;
        text-align: center;        
    }

    tr{        
        border: 0.5pt solid black;        
    }

    td{        
        border: 0.5pt solid black;        
    }             

    .rnd:focus{        
        border: 1pt dotted black;
    }

    .rnd:hover{
        border: 1pt dotted black;
    }

    .rndImage:focus{        
        background-color: lightgrey;        
    }

    .rndImage:hover{
        background-color: lightgrey;        
    }

`;

export default Box2Content;
