import { createStore, applyMiddleware, compose } from "redux";
import throttle from 'lodash/throttle';
import { loadState, saveState } from '../localStorage';
import rootReducer from "../reducers";
import apiMiddleware from "../middleware/api";
import LogRocket from 'logrocket';
import createSagaMiddleware from 'redux-saga';
import initialState from './InitialState_V0.0.4.json';
import rootSaga from '../sagas';
/*
const addLoggingToDispatch = (store) => {
    /* eslint-disable no-console * /  //Fix this comment before launching
    const rawDispatch = store.dispatch;
    if (!console.group) {
        return rawDispatch;
    }

    return (action) => {
        console.group(action.type);
        console.log('%c prev state', 'color: gray', store.getState());
        console.log('%c action', 'color: blue', action);
        const returnValue = rawDispatch(action);
        console.log('%c next state', 'color: green', store.getState());
        console.groupEnd(action.type);
        return returnValue;
    };
    /* eslint-enable no-console * /  //Fix this comment before launching
};
*/

const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
const middlewares = [sagaMiddleware, apiMiddleware];

//Don't waste the logrocket sessions on dev
if (process.env.NODE_ENV === 'production') {
    middlewares.push(LogRocket.reduxMiddleware());
}

const configureStore = () => {

    let persistedState = loadState();
    //persistedState = initialState;

    const store = createStore(
        rootReducer,
        persistedState,
        compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    )

    //Not required when the REDUX_DEVTOOLS are active
    /*
    if (process.env.NODE_ENV !== 'production') {
        store.dispatch = addLoggingToDispatch(store);
    }
    */

    // Extensions
    store.runSaga = sagaMiddleware.run(rootSaga);
    const action = type => store.dispatch({ type })

    store.subscribe(throttle(() => {
        saveState({
            entities: store.getState().entities,
            current: store.getState().current,
        });
    }, 10000));

    window.store = store;

    return store;
};

export default configureStore;

//Example from Boilerplate which might be useful in the future


/*
/**
 * Create the store with dynamic reducers


import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
//import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
    let composeEnhancers = compose;
    const reduxSagaMonitorOptions = {};

    // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
    /* istanbul ignore next * /
    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        /* eslint-disable no-underscore-dangle * /
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

        // NOTE: Uncomment the code below to restore support for Redux Saga
        // Dev Tools once it supports redux-saga version 1.x.x
        // if (window.__SAGA_MONITOR_EXTENSION__)
        //   reduxSagaMonitorOptions = {
        //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
        //   };
        /* eslint-enable * /
    }

    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const enhancers = [applyMiddleware(...middlewares)];

    const store = createStore(
        createReducer(),
        initialState,
        composeEnhancers(...enhancers),
    );

    // Extensions
    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {}; // Reducer registry
    store.injectedSagas = {}; // Saga registry

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next * /
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(store.injectedReducers));
        });
    }

    return store;
}

*/