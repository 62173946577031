import React from 'react';
import BoxCarousel from "../BoxCarousel";
import PageCarousel from '../PageCarrousel';
import BoxHeader from '../components/BoxHeader';
import { useSelector, useDispatch } from 'react-redux';

import { setTitleOfBox } from '../actions';

function Box(props) {
    const { box, onAddCkEditorItemToCarrousel, onBoxFocus, onBoxChange, focusOnBox, i, showBoxHeader, optimiseForPint } = props;
    const dispatch = useDispatch();

    let data = box;
    const boxId = data.boxId;
    const boxFromState = useSelector(state => state.a3.boxes.filter(box => box.boxId === boxId))[0];


    function handleGoNoGoChange(event) {
        //console.log("Box - handleGoNoGoChange called");
        //console.log(data.gonogo);

        var newData = data;
        newData.gonogo = data.gonogo === undefined ? true : !data.gonogo;

        onBoxChange(JSON.stringify(newData));
    }

    function handleResizeBox() {
        //console.log("Box - handleResizeBox called");

        onBoxFocus(i);
    }

    function handleBoxTitleChange(value) {
        //dispatch(setTitleOfBox(boxId, value));

        //Legacy
        var newData = data;
        newData.title = value;
        onBoxChange(JSON.stringify(newData));
    }

    function handleBoxCarrouselChange(value) {
        //console.log("Box - handleBoxCarrouselChange called");
        //console.log(data);

        var newData = data;
        newData.content = JSON.parse(value);

        onBoxChange(JSON.stringify(newData));
    }

    function addCkEditorItemToCarrousel() {
        //console.log("Box - addCkEditorItemToCarrousel called");
        //console.log(data);
        //console.log(props.i);
        onAddCkEditorItemToCarrousel(i);
    }

    const titleId = "titleA".concat(i);
    const titleData = data.title;
    const content = data.content;

    return (
        <div id={boxId} className={focusOnBox ? "content-box-gray-large" : "content-box-gray"}>
            <BoxHeader
                key={boxId}
                boxId={boxId}
                hideGoNoGo={false}
                go={!data.gonogo}
                onGoNoGoChange={handleGoNoGoChange}
                //boxTitle={titleData}
                boxTitle={boxFromState.title}
                onBoxTitleChange={handleBoxTitleChange}
                //onFocusBoxChange={handleFocusBoxChange}
                hideFocusOption={true}
                hideUnfocusOption={true}
            />
            <PageCarousel
                content={content}
                onAddCkEditorItemToCarrousel={addCkEditorItemToCarrousel}
                onBoxCarrouselChange={handleBoxCarrouselChange}
                onResizeBox={handleResizeBox}
                showBoxHeader={showBoxHeader}
                optimiseForPint={optimiseForPint}
                focusOnBox={focusOnBox}

                boxId={boxId}
                viewId={boxId}
            />

            <div>&nbsp;</div>
        </div>
    );

}
export default Box;

/*
<PageCarousel />
*/

/*
            <BoxCarousel
                content={content}
                onAddCkEditorItemToCarrousel={addCkEditorItemToCarrousel}
                onBoxCarrouselChange={handleBoxCarrouselChange}
                onResizeBox={handleResizeBox}
                showBoxHeader={showBoxHeader}
                optimiseForPint={optimiseForPint}
                focusOnBox={focusOnBox}
            />
*/