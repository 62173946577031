import styled from 'styled-components';

const MetaSponsorContent = styled.div`
    position: absolute;
    top: calc(77pt + var(--offset-alvance-print-top));        
    left: calc(170pt + var(--offset-alvance-print-left));
    width: 234pt;
    height: 20pt;
    line-height: 15pt;

    font-size: 10pt;
    font-family: Arial;
    //font-weight: bold;
    padding-top: 1.75pt;
    padding-left: calc(1pt + (8 * 6pt));
    
`;

export default MetaSponsorContent;
