import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @page {   
      size: landscape;   
      margin: 0mm;                
    }

    .ck.ck-balloon-panel {      
      position: absolute !important;
      top: 43.5px !important;
      left: calc(100vw - 650px) !important;
    
  }
   
    @media print {
      .no-print{
        display: none;
      }

      body {              
        /*padding:0 !important;     */
        margin:0 !important;                  
      }

          main {        
        padding: 8px !important;
        //height: 100vh;    
        //font-size: calc(2.67vmin );

    }
    
    }

      body {
    //background-color: ${props => (props.whiteColor ? 'yellow' : 'green')};
  }

  :root {
  --color1: #dae136;
  --boxheader-background-color: var(--color1);
  --color3: blue;

  --myfontsize: -6px;
  --cols: ${props => (props.focusOnBox ? 1 : 3)};

  --minimum-font-size-screen-p: 7px;
  --maximum-font-size-screen-p: 50px;
  --default-font-size-screen-p: calc(2.67vmin/var(--cols));
  --correction-font-size-screen-p: var(--myfontsize);

  --minimum-font-size-screen-h4: 8px;
  --maximum-font-size-screen-h4: 52px;
  --default-font-size-screen-h4: calc( 2.75vmin/var(--cols));
  --correction-font-size-screen-h4: var(--myfontsize);

  --minimum-font-size-screen-h3: 9px;
  --maximum-font-size-screen-h3: 54px;
  --default-font-size-screen-h4: calc( 3vmin/var(--cols));
  --correction-font-size-screen-h4: var(--myfontsize);

   --minimum-font-size-screen-h2: 10px;
  --maximum-font-size-screen-h2: 56px;
  --default-font-size-screen-h2: calc( 3.5vmin/var(--cols));
  --correction-font-size-screen-h2: var(--myfontsize);

  --minimum-font-size-screen-h1: 11px;
  --maximum-font-size-screen-h1: 58px;
  --default-font-size-screen-h1: calc(4vmin/var(--cols));
  --correction-font-size-screen-h1: var(--myfontsize);

  --offset-alvance-print-left: 3pt;   //-48pt; //
  --offset-alvance-print-top: 0pt;    //-54pt; //
  --resize-alvance-print-width: 1; 
  --resize-alvance-print-height: 1; 
}
`;

export default GlobalStyle;
/*
background-color: #dae136;
*/
