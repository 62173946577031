// inspired by https://leanpub.com/redux-book
import axios from "axios";
import { API } from "../actions/types";
import { accessDenied, apiError, apiStart, apiEnd } from "../actions/api";
import * as Types from "../actions/types";

const apiMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type !== API)
    return null;

  let {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onFailure,
    label,
    headers
  } = action.payload;

  const auth = getState().auth; //Retrieve the user info so we can perform the checks below.

  let enhancedData = data;
  if (auth.isAuthenticated)
    enhancedData = { ...data, email: auth.user.email } //If the user is authenticated, add the user data to the call so we don't have to do this for each call.
  //else
  //return null; //If the user is not authenticated, stop the call here as we can't get a succesful response 

  if (label) {
    dispatch(apiStart(label));
  }

  switch (label) {

    case Types.REMOVE_A3_FROM_API:
      method = "GET";
      url = process.env.REACT_APP_API_URL.concat("/api/removeA3");

      if (auth.isAuthenticated) {

        enhancedData = {
          email: auth.user.email,
          a3Id: data.a3Id,
        };
      }
      else
        return null;

      break;

    case Types.SHARE_A3:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/shareA3");

      if (auth.isAuthenticated) {
        enhancedData = {
          grantEmail: auth.user.email,
          shareEmail: data.email,
          level: data.level,
          right: data.right,
          a3Id: data.a3Id
        };
      }
      else
        return null;

      break;

    case Types.KEEP_ALIVE:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/keepAlive");

      if (auth.isAuthenticated) {
        enhancedData = {
          email: auth.user.email,
        };
      }
      else
        return null;

      break;
    
    case Types.GET_DB_INFO:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/dbinfo");

      if (auth.isAuthenticated) {
        enhancedData = {
          email: auth.user.email,
        };
      }
      else
        return null;

      break;      

    case Types.GET_A3S_FROM_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/getA3s");

      if (auth.isAuthenticated) {
        enhancedData = {
          email: auth.user.email,
        };
      }
      else
        return null;

      break;

    case Types.SAVE_A3_TO_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/setA3");
      /*
            console.log("State: ", getState());
            console.log("Denormalized state: ", denormalize(getState(), schema. ))
      */
      if (auth.isAuthenticated) {
        const a3 = getState().a3;

        enhancedData = {
          email: auth.user.email,
          uuid: a3.a3Id,
          json: a3.data,
        };
      }
      else
        return null;

      break;

    case Types.SAVE_TEMPLATE_TO_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/setTemplate");

      if (auth.isAuthenticated) {
        const a3 = getState().a3;
        enhancedData = {
          email: auth.user.email,
          json: a3.data,
        };
      }
      else
        return null;

      break;

    case Types.SAVE_A3IMAGE:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/setImage");
      //data is already enhanced with email so need to do this here again
      break;

    case Types.LOAD_A3V2_FROM_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/loada3fromdb");

      if (auth.isAuthenticated) {
        const a3Id = getState().current.a3Id;

        enhancedData = {
          email: auth.user.email,
          a3Id: a3Id,
        };
      }
      else
        return null;

      break;

    case Types.NEW_A3_FROM_TEMPLATE_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/newa3fromtemplate");

      if (auth.isAuthenticated) {
        const a3Id = getState().current.a3Id;

        enhancedData = {
          email: auth.user.email,
          a3Id: a3Id,
          templateId: data.templateId
        };
      }
      else
        return null;

      break;

    //

    case Types.SAVE_A3V2_TO_API:
      method = "POST";
      url = process.env.REACT_APP_API_URL.concat("/api/savea3todb");

      if (auth.isAuthenticated) {
        const a3Id = getState().current.a3Id;
        const boxesById = getState().entities.boxes.allIds.map(id => getState().entities.boxes.byId[id]);

        let boxTable = {
          columns: [],
          rows: []
        };

        [...boxesById]
          .filter(box => box) //Remove unidentified
          .filter(box => box.a3Id === a3Id)
          .map(box => {
            boxTable.rows.push([
              box.id,
              box.a3Id,
              box.name,
              box.title,
              box.go,
              box.sort
            ]);
          })

        //Make sure there are not duplicate values by forcing all id to lowercase. Duplicate ids will cause errors when inserting into the database
        const contentAllIds = [...new Set(getState().entities.contents.allIds.filter(id => id).map(id => id))];
        const contentsById = [...new Set(contentAllIds.filter(id => id).map(id => getState().entities.contents.byId[id]))];

        let contentTable = {
          columns: [],
          rows: []
        };

        [...contentsById]
          .filter(content => content)
          //.filter(content => boxesById.includes(content.boxId))
          .map(content => {
            contentTable.rows.push([
              content.id,
              content.boxId,
              content.type,
              content.value,
              content.localUrl,
              content.remoteUrl
            ]);
          })

        //Make sure there are not duplicate values by forcing all id to lowercase. Duplicate ids will cause errors when inserting into the database
        const metaAllIds = [...new Set(getState().entities.metas.allIds.filter(id => id).map(id => id))];
        const metasById = [...new Set(metaAllIds.filter(id => id).map(id => getState().entities.metas.byId[id]))];

        let metaTable = {
          columns: [],
          rows: []
        };

        [...metasById]
          .filter(meta => meta)
          .filter(meta => meta.a3Id === a3Id)
          .map(meta => {
            metaTable.rows.push([
              meta.id,
              meta.a3Id,
              meta.name,
              meta.type,
              meta.label,
              meta.placeholder,
              meta.value
            ]);
          })

        //Make sure there are not duplicate values by forcing all id to lowercase. Duplicate ids will cause errors when inserting into the database
        const pagecontentAllIds = [...new Set(getState().entities.pagecontents.allIds.filter(id => id).map(id => id))];
        const pagecontentsById = [...new Set(pagecontentAllIds.filter(id => id).map(id => getState().entities.pagecontents.byId[id]))];

        let pagecontentTable = {
          columns: [],
          rows: []
        };

        [...pagecontentsById]
          .filter(pagecontent => pagecontent)
          //.filter(content => boxesById.includes(content.boxId))
          .map(pagecontent => {
            pagecontentTable.rows.push([
              pagecontent.id,
              pagecontent.pageId,
              pagecontent.contentId,
              pagecontent.x,
              pagecontent.y,
              pagecontent.width,
              pagecontent.height,
              pagecontent.sort
            ]);
          })

        console.log("JSON.stringify(pagecontentTable): ", JSON.stringify(pagecontentTable))

        //Make sure there are not duplicate values by forcing all id to lowercase. Duplicate ids will cause errors when inserting into the database
        const a3AllIds = [...new Set(getState().entities.a3s.allIds.filter(id => id).map(id => id))];
        const a3sById = [...new Set(a3AllIds.filter(id => id).map(id => getState().entities.a3s.byId[id]))];

        //console.log("a3AllIds", a3AllIds);
        //console.log("a3sById", a3sById);

        let a3Table = {
          columns: [],
          rows: []
        };

        [...a3sById]
          .filter(a3 => a3)
          .filter(a3 => a3.id === a3Id)
          .map(a3 => {
            a3Table.rows.push([
              a3.id,
              a3.name,
              a3.title,
              a3.type,
              a3.creationDateTime,
              a3.updateDateTime
            ]);
          })

        console.log("JSON.stringify(a3Table): ", JSON.stringify(a3Table))

        enhancedData = {
          email: auth.user.email,
          a3Id: a3Id,
          a3Table: JSON.stringify(a3Table),
          boxTable: JSON.stringify(boxTable),
          pagecontentTable: JSON.stringify(pagecontentTable),
          contentTable: JSON.stringify(contentTable),
          metaTable: JSON.stringify(metaTable)
        };
      }
      else
        return null;

      break;

    default:

      break;
  }

  //Make sure that we select POST as default when the params are long
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: enhancedData
    })
    .then(({ data }) => {
      //console.log("API success", data);
      dispatch(onSuccess(data));
    })
    .catch(error => {
      console.log("API error", error);
      dispatch(apiError(error));
      dispatch(onFailure(error));

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label));
      }
    });
};

export default apiMiddleware;
