import styled from 'styled-components';

const PageGridColumn = styled.div`
    width: 95%;
    flex: 40%;
  

    //Development
    background-color: magenta;
    padding: 5px;
    margin: 5px;
`;

export default PageGridColumn;
