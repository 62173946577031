import styled from 'styled-components';

const Wrapper = styled.div`
    font-size: clamp(18px, 4vmin, 28px);
    width: 99.5%;

    [placeholder]:empty::before {
        content: attr(placeholder);
        color: lightgrey; 
    }

    //border: 0;

    .edit {
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 10px;

        outline: -webkit-focus-ring-color auto 0px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 0px;
        overflow: hidden;

        :focus{
            border: 1px dotted black;
            margin-left: 4px;
            margin-top: 9px;
            margin-bottom: 9px;
        }

        :hover{
            border: 1px dotted black;
            margin-left: 4px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
    }

    @media print {      
        display: none;
      }


`;

export default Wrapper;