import styled from 'styled-components';

const BoxRow = styled.div`
//display: flex;
//align-items: center;
//min-width: 450px;
//flex-wrap:wrap;
//overflow-y:hidden;
padding: 5px 0px 0px 0px;
//justify-content: space-between;
flex-direction: col;
//background-color: green;

  //display: -ms-flexbox; /* IE 10 */
  display: flex;
  //-ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;


    content: "";
  //display: table;
  clear: both;  

`;

export default BoxRow;

/*
@media (min-width: 900px) {
    //display: block;
    flex-direction: row;
    width: 100%;
}
*/