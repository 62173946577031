import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';

import PageLayoutContent from "./PageLayoutContent";
import Wrapper from "./Wrapper";
import "./styles.css";

import produce from 'immer';
import { setLayoutOfPage, setCompactTypeOfPage } from '../../actions';

const selectContents = state => state.entities.contents.allIds.map(id => state.entities.contents.byId[id]);
const makeSelectContentsSelector = () => createSelector(
    selectContents,
    (_, boxId) => boxId,
    (_, __, viewId) => viewId,
    (_, __, ___, pageId) => pageId,
    (_, __, ___, ____, filterForImagesOnly) => filterForImagesOnly,
    (contents, boxId, viewId, pageId, filterForImagesOnly) => {
        let contentsToFilter = contents

        if (boxId && boxId.length && boxId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.boxId === boxId)

        if (viewId && viewId.length && viewId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.viewId === viewId)

        if (pageId && pageId.length && pageId.length > 0)
            contentsToFilter = contentsToFilter.filter(content => content.pageId === pageId)

        if (filterForImagesOnly)
            contentsToFilter = contentsToFilter.filter(content => content.type === "image")

        return contentsToFilter
    }
);

const selectLayouts = state => state.entities.layouts.allIds.map(id => state.entities.layouts.byId[id]);
const makeSelectLayoutsSelector = () => createSelector(
    selectLayouts,
    (_, boxId) => boxId,
    (_, __, viewId) => viewId,
    (_, __, ___, pageId) => pageId,
    (layouts, boxId, viewId, pageId) => {
        let layoutsToFilter = layouts.filter(layout => layout)

        //if (boxId && boxId.length && boxId.length > 0)
        //    layoutsToFilter = layoutsToFilter.filter(layout => layout.boxId === boxId)

        //if (viewId && viewId.length && viewId.length > 0)
        //   layoutsToFilter = layoutsToFilter.filter(layout => layout.viewId === viewId)

        if (pageId && pageId.length && pageId.length > 0)
            layoutsToFilter = layoutsToFilter.filter(layout => layout.pageId === pageId)

        return layoutsToFilter
    }
);

const PageLayoutV2 = (props) => {
    const { onLayoutChange, boxId, viewId, pageId, filterForImagesOnly } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    //Selectors
    //const user = useSelector(state => state.auth.user);
    const selectContentsSelector = useMemo(makeSelectContentsSelector, [boxId, viewId, pageId, filterForImagesOnly])
    const contents = useSelector((state) => selectContentsSelector(state, boxId, viewId, pageId, filterForImagesOnly))

    const selectLayoutsSelector = useMemo(makeSelectLayoutsSelector, [boxId, viewId, pageId])
    const layouts = useSelector((state) => selectLayoutsSelector(state, boxId, viewId, pageId))

    function handleLayoutChange(layout, layouts) {
        //console.log("layouts", layouts);
        //props.onLayoutChange(layouts, pageId)

        dispatch(setLayoutOfPage(layouts, pageId));
    }

    function handleCompactTypeChange(compactType) {
        dispatch(setCompactTypeOfPage(compactType, pageId));
    }

    return (
        <Wrapper>
            {layouts.length > 0 ? layouts.filter(layout => layout.innerLayout !== undefined).map(layout =>
                <PageLayoutContent
                    key={layout.layoutId}
                    onLayoutChange={handleLayoutChange}
                    onCompactTypeChange={handleCompactTypeChange}
                    contentComponents={[]}
                    contentObjects={contents}
                    layouts={JSON.parse(layout.innerLayout)}
                />
            )
                : <PageLayoutContent
                    key={pageId}
                    onLayoutChange={handleLayoutChange}
                    onCompactTypeChange={handleCompactTypeChange}
                    contentComponents={[]}
                    contentObjects={contents}
                />
            }
        </Wrapper>
    );

}

export default PageLayoutV2
