import styled from 'styled-components';

const StartDateContent = styled.div`
    position: absolute;
    top: calc(180pt + var(--offset-alvance-print-top));        
    left: calc(49.75pt + var(--offset-alvance-print-left));
    width: 57pt;
    height: 19.5pt;
    line-height: 18.1pt;
    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
    font-size: 9.15pt;
    font-family: Arial;
    //font-weight: bold;
    padding-left: 1.5pt;
    transform: rotate(90deg);
    text-align: center;
    
    div:focus{
        transform: rotate(-90deg);
        background-color: white;
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

    div:not(:focus){            
        background-color: white;    
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

`;

export default StartDateContent;
