import React from "react";
import { useSelector } from 'react-redux';
import PrintContent from './PrintContent';

const PrintImageBasedOnID = (props) => {
    const { imageId } = props;
    const imageData = useSelector(state => state.a3.files
        .filter(file => file.uuid === imageId)
        .map(image => image.data));

    if (!imageData)
        return null

    return (
        <PrintContent>
            <img
                key={imageId}
                alt={imageId}
                src={imageData}
            />
        </PrintContent>
    )
};

export default PrintImageBasedOnID;