import React from 'react';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { FaHeart, FaBars } from 'react-icons/fa';
import reactLogo from './assets/logo.svg';
import App from './App';
import RibbonMenu from './components/RibbonMenu';
import Topbar from './components/Topbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Main = ({
  collapsed,
  toggled,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  screenWidth,
  screenHeight
}) => {
  const intl = useIntl();

  return (
    <main>
      <RibbonMenu
        handleToggleSidebar={handleToggleSidebar}
        collapsed={collapsed}
        toggled={toggled}
      />
      <App
        screenWidth={screenWidth}
        screenHeight={screenHeight}
      />
    </main>
  );
};

export default Main;

/*
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <header>

      </header>

      <App />

      <footer>
        <small>
          © 2021 made with <FaHeart style={{ color: 'red' }} /> by ThinkA3.com{' '}
        </small>
        <br />
      </footer>
*/