import styled from 'styled-components';

const ToggleContainer = styled.div`
width: 94px;
background-color: white;
//background-color: grey;
cursor: pointer;
user-select: none;
border-radius: 3px;
padding: 2px;
height: 30px;
position: relative;
`;

export default ToggleContainer;
