import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ImageItem from "./ImageItem";
import CKEditorItem from "./CKEditorItem";

import ImageItemFromApi from './ImageItemFromApi';

const CarrouselItem = (props) => {
    const verboseLog = false;
    const vLog = s => { if (verboseLog) console.log(s); }

    const { type, i, data, id, focusOnBox, onCarrouselItemChange } = props;
    const dispatch = useDispatch();
    const files = useSelector(state => state.a3.files);

    function handleCKEditorItemChange(value) {
        onCarrouselItemChange(value, i);
    }

    switch (type) {
        case "Base64ImageFromApi":
        case "Base64Image": return (
            <ImageItem
                classname="imageItem"
                uuid={data}
                data={data}
                id={id}
            />
        );

        default: return (
            <CKEditorItem
                focusOnBox={focusOnBox}
                data={data}
                id={id}
                onCKEditorItemChange={handleCKEditorItemChange}
            />
        );
    }
}

export default CarrouselItem;