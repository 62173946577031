import React, { Component } from 'react';
import Box from "./Box";
import Header from "./Header";
import MetaHeader from "./MetaHeader";
//import hotkeys from 'hotkeys-js';

class Layout extends Component {
    constructor(props) {
        super(props);

        //console.log("Layout - constructor called");

        this.data = this.props.data;
        this.focusBox = this.props.focusBox;

        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleSponsorChange = this.handleSponsorChange.bind(this);
        this.handleProcessOwnerChange = this.handleProcessOwnerChange.bind(this);
        this.addCkEditorItemToCarrousel = this.addCkEditorItemToCarrousel.bind(this);
        this.handleBoxChange = this.handleBoxChange.bind(this);
        this.handleBoxFocus = this.handleBoxFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMetaChange = this.handleMetaChange.bind(this);
    }

    handleSubmit(files) {
        this.props.onFileFromPC(files);
    }

    handleBoxChange(value) {
        //console.log("Layout - handleBoxChange called");
        //console.log(value);

        var newData = this.data;
        newData.box[this.props.i] = JSON.parse(value);

        this.props.onLayoutChange(JSON.stringify(newData));
    }

    handleMetaChange(value) {
        //console.log("Layout - handleMetaChange called");
        //console.log(value);

        
        var newData = this.data;
        newData.meta = JSON.parse(value);

        this.props.onLayoutChange(JSON.stringify(newData));
        
    }

    addCkEditorItemToCarrousel(newBox) {
        //console.log("Layout - addCkEditorItemToCarrousel called");
        //console.log(newBox);
        this.props.onAddCkEditorItemToCarrousel(newBox);
    }

    handleLayoutChange(e) {
        this.props.onLayoutChange(e.target.value);
    }

    handleTitleChange(e) {
        this.props.onTitleChange(e);
    }

    handleProcessOwnerChange(e) {
        //console.log("Layout - handleProcessOwnerChange called");
        this.props.onProcessOwnerChange(e);
    }

    handleSponsorChange(e) {
        this.props.onSponsorChange(e);
    }

    handleBoxFocus(i) {
        //console.log("Layout - handleBoxFocus called");
        //console.log(i);
        this.props.onFocusBoxChange(i);
    }

    render() {
        //console.log("Layout - render called");
        this.data = this.props.data;
        //console.log(this.data);
        //console.log(this.data.meta);

        if (this.data === null)
            return null;

        return (
            <div>
                <Header key="header1"
                    data={this.data}
                    onFileFromPC={this.handleSubmit}
                    a3id={this.props.a3id}
                />

                <MetaHeader key="metaHeader1"
                    data={this.data}
                    meta={this.data.meta}
                    optimiseForPint={false}
                    onTitleChange={this.handleTitleChange}
                    onSponsorChange={this.handleSponsorChange}
                    onProcessOwnerChange={this.handleProcessOwnerChange}
                    onMetaChange={this.handleMetaChange}
                />

                {this.props.focusBox === -1 ?
                    <div className="row">
                        <div id="column1" className="column">
                            {
                                this.data.box.map((box, i) => {
                                    return (i >= 0 && i < 3 ?
                                        <div key={i}>
                                            <Box key={i} i={i} box={box}
                                                focusOnBox={false}
                                                onAddCkEditorItemToCarrousel={this.addCkEditorItemToCarrousel}
                                                onBoxChange={this.handleBoxChange}
                                                onBoxFocus={this.handleBoxFocus}
                                                showBoxHeader={true}
                                                optimiseForPint={false}
                                            />
                                        </div> : <div key={i}></div>
                                    );
                                })
                            }
                        </div>
                        <div id="column2" className="column">
                            {
                                this.data.box.map((box, i) => {
                                    return (i >= 3 && i < 6 ?
                                        <div key={i}>
                                            <Box key={i} i={i} box={box}
                                                focusOnBox={false}
                                                onAddCkEditorItemToCarrousel={this.addCkEditorItemToCarrousel}
                                                onBoxChange={this.handleBoxChange}
                                                onBoxFocus={this.handleBoxFocus}
                                                showBoxHeader={true}
                                                optimiseForPint={false}
                                            />
                                        </div> : <div key={i}></div>
                                    );
                                })
                            }
                        </div>
                        <div id="column3" className="column">
                            {
                                this.data.box.map((box, i) => {
                                    return (i >= 6 && i < 9 ?
                                        <div key={i}>
                                            <Box key={i} i={i} box={box}
                                                focusOnBox={false}
                                                onAddCkEditorItemToCarrousel={this.addCkEditorItemToCarrousel}
                                                onBoxChange={this.handleBoxChange}
                                                onBoxFocus={this.handleBoxFocus}
                                                showBoxHeader={true}
                                                optimiseForPint={false}
                                            />
                                        </div> : <div key={i}></div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div id="column" className="column">
                            {
                                this.data.box.map((box, i) => {
                                    return (i === this.props.focusBox ?
                                        <div key={i}>
                                            <Box key={i} i={i} box={box}
                                                focusOnBox={true}
                                                onAddCkEditorItemToCarrousel={this.addCkEditorItemToCarrousel}
                                                onBoxChange={this.handleBoxChange}
                                                onBoxFocus={this.handleBoxFocus}
                                                showBoxHeader={true}
                                                optimiseForPint={false}
                                            />
                                        </div> : <div key={i}></div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Layout;