import styled from 'styled-components';

const Box6 = styled.div`
    position: absolute;
    top: calc(492pt + var(--offset-alvance-print-top));    
    left: calc(302pt + var(--offset-alvance-print-left));
    width: 554.5pt;
    height: 218.5pt;
    line-height: 7.5pt;
    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
    font-size: 6.75pt;
    font-family: Arial;
    //font-weight: bold;
    text-decoration: underline;
    padding-top: 1pt;    
    padding-left: 2pt;    

`;

export default Box6;
