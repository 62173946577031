import styled from 'styled-components';

const Right = styled.div`
//height: 50px;
//display: inline-block;
//float: right;
padding: 0 4px 0 0;
//vertical-align: middle;
//margin: auto;
display: flex;
align-items: center;
//background-color:green;
`;

export default Right;
