import React from "react";
import { useDispatch } from 'react-redux';

import { SetFocusOnBox } from '../../actions'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import Icon from "./Icon";

const SetFocusIcon = React.memo((props) => {
    //Console
    //console.log("RemoveFocusIcon rendered");

    //Props
    const { boxId } = props;

    //Use
    const dispatch = useDispatch();

    //Handlers
    function handleClickSetFocus() {
        dispatch(SetFocusOnBox(boxId));
    }

    return (
        <Icon className="no-print">
            <FontAwesomeIcon
                icon={faExpandArrowsAlt}
                color="white"
                style={{
                    width: '20px',
                    height: '20px',
                    padding: '1px 1px 1px 1px'
                }}
                onClick={() => handleClickSetFocus()}
            />
        </Icon>

    );
});

export default SetFocusIcon;