import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import { fetchA3s, newA3, setLayoutOfPage } from '../../actions'

import A3Mugshot from '../../components/A3Mugshot';

import ComponentWrapper from './ComponentWrapper';
import PageGrid from '../../components/PageGrid';
import AddRemoveLayout from '../../components/AddRemoveLayout';
import PageLayoutV2 from '../../components/PageLayoutV2';
import MyResponsiveGrid from '../../components/MyResponsiveGrid';


const MockPageLayout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, isAuthenticated } = useAuth0();

    function handleLayoutChange(layouts, pageId) {
        console.log("Layout change called!!");
        //dispatch(setLayoutOfPage(layouts, pageId));
    }

    const [showImagesFor1, setShowImagesFor1] = useState(false);

    return (
        <ComponentWrapper>
            <button onClick={() => setShowImagesFor1(!showImagesFor1)} >Toggle images for 1</button>

            <PageLayoutV2
                boxId="mockBoxId1"
                viewId="mockViewId1"
                pageId="mockPageId1"
                filterForImagesOnly={showImagesFor1}
                onLayoutChange={handleLayoutChange}
            />

            <PageLayoutV2
                boxId="mockBoxId2"
                viewId="mockViewId2"
                pageId="mockPageId2"
                filterForImagesOnly={showImagesFor1}
                onLayoutChange={handleLayoutChange}
            />

        </ComponentWrapper>
    );

}

export default MockPageLayout;

/*

    const [showImagesFor1, setShowImagesFor1] = useState(false);
    const [showImagesFor2, setShowImagesFor2] = useState(false);
    const [showImagesFor3, setShowImagesFor3] = useState(false);

    return (
        <ComponentWrapper>
            <button onClick={() => setShowImagesFor1(!showImagesFor1)} >Toggle images for 1</button>
            <button onClick={() => setShowImagesFor2(!showImagesFor2)} >Toggle images for 2</button>
            <button onClick={() => setShowImagesFor3(!showImagesFor3)} >Toggle images for 3</button>
            <PageLayout
                boxId="mockBoxId1"
                viewId="mockViewId1"
                pageId="mockPageId1"
                filterForImagesOnly={showImagesFor1}
                onLayoutChange={handleLayoutChange}
            />
            <PageLayout
                boxId="mockBoxId2"
                viewId="mockViewId2"
                pageId="mockPageId2"
                filterForImagesOnly={showImagesFor2}
                onLayoutChange={handleLayoutChange}
            />
            <PageLayout
                boxId="mockBoxId3"
                viewId=""
                pageId=""
                filterForImagesOnly={false}
                onLayoutChange={handleLayoutChange}
            />
        </ComponentWrapper>
    );
*/