import styled from 'styled-components';

const OverviewContainerWrapper = styled.div`
    width: 100%;    
    cursor: pointer;
    //user-select: none;
    border-radius: 3px;
    padding: 5px;
    //height: 1024px;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    //Development
    //background-color: yellow;
`;

export default OverviewContainerWrapper;
