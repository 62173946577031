import styled from 'styled-components';

const MetaProcesOwnerLabel = styled.div`
    position: absolute;
    top: calc(30pt + var(--offset-alvance-print-top));        
    left: calc(905pt + var(--offset-alvance-print-left));
    width: 192pt;
    height: 20pt;
    line-height: 13pt;

    font-size: 10pt;
    font-family: Arial;

    padding-top: 2.5pt;
    padding-left: 86pt;
    
    
`;

export default MetaProcesOwnerLabel;
