import produce from 'immer';
import {
  SET_A3ID,
  API_START,
  API_END,
  FETCH_A3,
  SET_A3,
  FETCH_A3IMAGE,
  SET_A3IMAGE,
  SAVE_A3IMAGE,
  ADD_PAGE_TO_BOX,
  ADD_TODO_TO_A3,
  SAVE_A3_TO_API,
  SET_TITLE_OF_BOX,
  ADD_VIEW_TO_BOX,
  ADD_PAGE_TO_VIEW,
} from "../actions/types";

import initialStateA3 from "./InitialStateA3_V0.0.4.json"

/*
const initialState = {
  a3id: "",
  data: {},
  files: [],
  isLoadingData: false,
};
*/

const a3 = (state = initialStateA3, action) => {
  //console.log("actions from a3");
  let outcome = state;

  if ((action.type === API_START || action.type === API_END) && (action.payload !== FETCH_A3 || action.payload !== FETCH_A3IMAGE || action.payload !== SAVE_A3IMAGE || action.payload !== SAVE_A3_TO_API))
    return state;

  switch (action.type) {
    case SET_A3ID:
      return produce(state, draft => {
        draft.a3Id = action.a3Id;
        draft.a3id = action.a3Id; //Legacy
      });

    case SET_A3IMAGE:
      const index = state.files.findIndex(file => file.uuid !== action.payload.uuid)

      if (index > 0) {
        //Update the existing list with the result
        //console.log("A3Image found. Updating existing");
        const newArray = [...state.files];
        newArray[index] = action.payload
        outcome = {
          ...state,
          files: newArray,
        }
      }
      else {
        //Insert the new value
        //console.log("A3Image not found. Inserting new");
        outcome = {
          ...state,
          files: [...state.files, action.payload]
        }
      }

      return outcome;

    case SET_A3:
      //console.log("SET_A3 called")
      //console.log(action);
      if (action.payload !== "undefined")
        outcome = {
          ...state,
          metas: JSON.parse(action.payload).meta,
          boxes: JSON.parse(action.payload).box,
          data: action.payload || {} //Added the second parameter as the payload can be empty which removes the data field from state which leads to undefined errors.
        }
      return outcome;

    case API_START:
      if (action.payload === FETCH_A3 || action.payload === FETCH_A3IMAGE || action.payload !== SAVE_A3IMAGE || action.payload !== SAVE_A3_TO_API) {
        return {
          ...state,
          isLoadingData: true
        };
      }
      break;

    case API_END:
      if (action.payload === FETCH_A3 || action.payload === FETCH_A3IMAGE || action.payload !== SAVE_A3IMAGE || action.payload !== SAVE_A3_TO_API) {
        return {
          ...state,
          isLoadingData: false
        };
      }
      break;

    case ADD_PAGE_TO_BOX:
      /*
      console.log(action.boxId);
      let boxes = state.box.filter(box => box.boxId === action.boxId)      
      console.log(box);
      //box.views = action.payload;
      const newArray = [...state.box];
      console.log(newArray);
      newArray[2] = [...box];
      console.log(newArray);
      //box.push({ views: JSON.parse(action.payload) });
      outcome = {
        ...state,
        box: [...newArray, ...boxes]
      }
      */
      /*
      console.log(pageIndex);
      if (pageIndex > 0) {
        const newArray = [...state.box];
        newArray[pageIndex] = action.payload
        outcome = {
          ...state.box,
          views: newArray,
        }
      }
      else {
        //Insert the new value
        //console.log("A3Image not found. Inserting new");
        outcome = {
          ...state,
          views: [...state.box, action.payload]
        }
      }

      /*
      outcome = {
        ...state,
        views: [...state.views, {
          "Sort": 1,
          "Page": "Single",
          "Content": [{
            "Type": "Text",
            "Value": "New Page"
          }],
        }]
      }*/
      return outcome;

    case ADD_TODO_TO_A3:
      return produce(state, draft => {
        draft.todos.push(
          {
            "todoId": action.todoId,
            "text": action.text,
            "completed": action.completed
          }
        );
      });

    case SET_TITLE_OF_BOX:
      return produce(state, draft => {
        const boxIndex = draft.boxes.findIndex(box => box.boxId === action.boxId)
        if (boxIndex !== -1)
          draft.boxes[boxIndex].title = action.title
      });

    case ADD_VIEW_TO_BOX:
      return produce(state, draft => {
        const boxIndex = draft.boxes.findIndex(box => box.boxId === action.boxId)
        if (boxIndex !== -1) {

          if (!draft.boxes[boxIndex].views)
            draft.boxes[boxIndex].views = [];

          draft.boxes[boxIndex].views.push(
            {
              "viewId": action.viewId,
              "type": action.viewType,
              "activePage": action.activePage,
              "pages": []
            }
          )

          draft.boxes[boxIndex].activeView = action.viewId;
        }
      });

    case ADD_PAGE_TO_VIEW:
      return produce(state, draft => {
        const boxIndex = draft.boxes.findIndex(box => box.boxId === action.boxId)
        if (boxIndex !== -1) {
          const viewIndex = draft.boxes[boxIndex].views.findIndex(view => view.viewId === action.viewId)
          if (viewIndex !== -1) {

            if (!draft.boxes[boxIndex].views[viewIndex].pages)
              draft.boxes[boxIndex].views[viewIndex].pages = [];

            draft.boxes[boxIndex].views[viewIndex].pages.push(
              {
                "pageId": action.pageId,
                "type": action.pageType,
                "layouts": action.layouts,
                "activeContent": action.activeContent,
                "contents": []
              }
            );

            //Make the new page also the active page as the user will want to get started immediately
            draft.boxes[boxIndex].views[viewIndex].activePage = action.pageId;
          }
        }
      });


    default:
      return state;
  }
}

export default a3