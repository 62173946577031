import styled from 'styled-components';

const Box1 = styled.div`
    position: absolute;
    top: calc(95.25pt + var(--offset-alvance-print-top));    
    left: calc(87pt + var(--offset-alvance-print-left));
    width: 216pt;
    height: 180.75pt;
    line-height: 7.5pt;
    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
    font-size: 6.75pt;
    font-family: Arial;
    //font-weight: bold;
    text-decoration: underline;
    padding-left: 1.5pt;    

`;

export default Box1;
