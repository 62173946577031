import React, { useState } from 'react';
import PropTypes from "prop-types";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Wrapper from './Wrapper';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { setTitleOfBox } from '../../actions';


function BoxTitle(props) {
    const { value, onBoxTitleChange, boxId } = props;
    const dispatch = useDispatch();

    const selectBox = state => state.a3.boxes.filter(box => box.boxId === boxId)[0];
    const selectBoxTitle = createSelector(
        selectBox,
        box => box.title
    );
    const boxTitle = useSelector(selectBoxTitle);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(boxTitle || ""))));

    const onEditorStateChange = editorState => {
        setEditorState(editorState);

        const newTitle = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        dispatch(setTitleOfBox(boxId, newTitle));

        //Legacy
        onBoxTitleChange(newTitle);
    };

    return (
        <Wrapper>
            <Editor
                toolbarClassName="demo-toolbar-absolute"
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                defaultEditorState={boxTitle}
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
                toolbarHidden
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                        bold: { className: 'bordered-option-classname' },
                        italic: { className: 'bordered-option-classname' },
                        underline: { className: 'bordered-option-classname' },
                        strikethrough: { className: 'bordered-option-classname' },
                        code: { className: 'bordered-option-classname' },
                    },
                    blockType: {
                        className: 'bordered-option-classname',
                    },
                    fontSize: {
                        className: 'bordered-option-classname',
                    },
                    fontFamily: {
                        className: 'bordered-option-classname',
                    },
                }}
            />
        </Wrapper>
    );
}

BoxTitle.propTypes = {
    value: PropTypes.string,
    onBoxTitleChange: PropTypes.func
};

export default BoxTitle;