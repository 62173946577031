import styled from "styled-components";

const Inner = styled.div`
/*
   position:  absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   text-align: left;
   justify-content: center;
   align-items: center;  
   */
`;

export default Inner;
