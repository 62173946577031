const todos = (state = [], action) => {
  switch (action.type) {
    case 'INIT_TODO':
      if (!state.length) {
        return [
          ...state,
          {
            id: action.id,
            text: "Something to do...",
            completed: false
          }
        ]
      }
      else
        return [...state,]

    case 'ADD_TODO':
      return [
        ...state,
        {
          id: action.id,
          text: action.text,
          completed: false
        }
      ]

    case 'TOGGLE_TODO':
      return state.map(todo =>
        (todo.id === action.id)
          ? { ...todo, completed: !todo.completed }
          : todo
      )
    default:
      return state
  }
}

export default todos