import styled from 'styled-components';

const Shared = styled.div`
font-size: 12px;
line-height: 10px;
font-weight: bold;
cursor: pointer;
color: white;
padding: 8px 12px;
//border-radius: 18px;
//box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
min-width: 46px;
display: flex;
justify-content: center;
align-items: center;
width: 48px;
min-width: unset;
border-radius: 3px;
box-sizing: border-box;
//box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
position: absolute;
transition: all 0.3s ease;
white-space: nowrap;
text-shadow: 2px 2px 2px #2e3336;
`;

const ToggleButtonNoGo = styled(Shared)`
background-color: #ed6123;
left: 44px;
`;

export const ToggleButtonGo = styled(Shared)`
background-color: #98c83b;
left: 2px;
`;

export default ToggleButtonNoGo;
