import React from "react";
import { useDispatch } from 'react-redux';

import { RemoveFocusFromBox } from '../../actions'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import Icon from "./Icon";

const RemoveFocusIcon = React.memo((props) => {
    //Console
    //console.log("RemoveFocusIcon rendered");

    //Props
    const { } = props;

    //Use
    const dispatch = useDispatch();

    //Handlers
    function handleClickRemoveFocus() {
        dispatch(RemoveFocusFromBox());
    }

    return (
        <Icon className="no-print">
            <FontAwesomeIcon
                icon={faCompressArrowsAlt}
                color="white"
                style={{
                    width: '20px',
                    height: '20px',
                    padding: '1px 1px 1px 1spx'
                }}
                onClick={() => handleClickRemoveFocus()}
            />
        </Icon>
    );
});

export default RemoveFocusIcon;