import React from "react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import SignupButton from "./SignupButton";

import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationButton = () => {    
  const { user, isAuthenticated } = useAuth0();

  return isAuthenticated ? <div><LogoutButton /></div> : <div><LoginButton /> <SignupButton /></div>;
};

export default AuthenticationButton;