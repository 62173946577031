import React from "react";
//import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useSnackbar } from 'notistack';

import { removeBoxError } from '../../actions'


const BoxNotifier = React.memo((props) => {
    //Console
    //console.log("BoxNotifier rendered");

    //Props
    const { boxId } = props;

    //Selectors
    const selectBoxErrors = state => state.entities.boxes.errors;
    const selectErrors = createSelector(
        selectBoxErrors,
        error => error
    );

    //Use
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    //Consts
    const boxErrors = useSelector(selectErrors);


    boxErrors.map(error => {
        enqueueSnackbar(error.reason, {
            variant: 'error',
            preventDuplicate: true
        });

        dispatch(removeBoxError());
    })

});

export default BoxNotifier;