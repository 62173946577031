import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: []
};

const boxviews = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.SET_A3_NORMALIZED:
        if (action.payload.boxviews) {
          return produce(state, draft => {
            draft.byId = {
              ...draft.byId, ...action.payload.boxviews.byId
            };
            draft.allIds = [...new Set([...draft.allIds, ...action.payload.boxviews.allIds])];
          });
        }

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

      default:
        break;
    }
  });
}

export default boxviews