import React from 'react';
import { useEffect, useState } from 'react';
//import { useState, useMemo } from 'react';
//import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import { useDispatch } from 'react-redux';

import BoxHeaderV2 from '../BoxHeaderV2';
import BoxWrapper from './BoxWrapper';
import Wrapper from './Wrapper';

import {
    // makeSelectBoxesSelector,
    selectCurrent
} from '../../Selectors';
import PageCarouselV2 from '../PageCarrouselV2';
import PageLayoutV2 from '../PageLayoutV2';
import PrintCarrousel from '../PrintCarrousel';

const BoxV2 = React.memo((props) => {
    //Props
    const { boxId, show, print, screenWidth, screenHeight } = props;

    //Console
    //console.log("BoxV2 rendered", boxId);

    //Use
    //const dispatch = useDispatch();
    //const history = useHistory();

    //Selectors    
    //const selectBoxesSelector = useMemo(makeSelectBoxesSelector, [boxId])
    //const boxes = useSelector((state) => selectBoxesSelector(state, boxId))
    const current = useSelector(selectCurrent);

    //State    
    //const [boxTitle, setBoxTitle] = useState(props.boxTitle);
    //const [goNoGoSelected, setGoNoGoSelected] = useState(props.go);
    //const { v4: uuidv4 } = require('uuid');

    //Handlers    
    const showPageCarousel = true;
    const showPageLayout = !showPageCarousel;

    const [availableScreenWidth, setAvailableScreenWidth] = useState(1024);
    const [availableScreenHeight, setAvailableScreenHeight] = useState(768);
    const [zoom, setZoom] = useState(1);
    const [boxWidth, setBoxWidth] = useState(1024);

    useEffect(() => {
        let availableScreenWidth = screenWidth < 769 ? screenWidth : screenWidth - 270;
        let availableScreenHeight = screenHeight * 1.25;
        availableScreenWidth = availableScreenWidth > availableScreenHeight ? availableScreenHeight : availableScreenWidth;

        let zoom = availableScreenWidth / 1620 > 1 ? 1 : availableScreenWidth / 1620;
        let boxWidth = current.focusOnBox ? availableScreenWidth : availableScreenWidth / 3;

        if (availableScreenHeight > 0) setAvailableScreenHeight(availableScreenHeight);
        if (availableScreenWidth > 0) setAvailableScreenWidth(availableScreenWidth);
        if (zoom > 0) setZoom(zoom);
        if (boxWidth > 0) setBoxWidth(boxWidth);
    }, [screenWidth, screenHeight])
    //console.log("zoom", zoom)

    return (
        <BoxWrapper
            show={show}
            screenWidth={availableScreenWidth}
            screenHeight={availableScreenHeight}
            zoom={zoom}
            boxWidth={boxWidth}
        >
            <BoxHeaderV2
                className="boxheader"
                key={boxId}
                boxId={boxId}
                hideFocusOption={current.focusOnBox}
                hideUnfocusOption={!current.focusOnBox}
                hideNewPageOption={true}
                hidePageCarrouselOptions={!showPageCarousel}
            />
            <Wrapper boxWidth={boxWidth}>
                {print ?
                    <PrintCarrousel
                        //key={boxId}
                        boxId={boxId}
                    //viewId="mockViewId1"
                    //pageId="mockPageId1"
                    //filterForImagesOnly={true}
                    /> : null
                }
                {!print && showPageCarousel ?
                    <PageCarouselV2
                        //key={boxId}
                        boxId={boxId}
                    //viewId="mockViewId1"
                    //pageId="mockPageId1"
                    //filterForImagesOnly={true}
                    /> : null
                }
                {showPageLayout ?
                    <PageLayoutV2
                        key={boxId}
                        boxId={boxId}
                    //viewId="mockViewId1"
                    //pageId="mockPageId1"
                    //filterForImagesOnly={true}
                    /> : null
                }
            </Wrapper>
        </BoxWrapper>
    );

});

export default BoxV2;
