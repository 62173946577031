import styled from 'styled-components';

const Wrapper = styled.div`
//background-color: #dae136;
flex: 1;
//color: white;
//text-shadow: 2px 2px 2px #2e3336;
font-size: 18px;
font-weight: bold;
width: auto;
padding: 4px 4px 4px 4px;
min-width: 0;
white-space: nowrap;
text-overflow: clip;
overflow: hidden;
height: 36px;
max-height: 36px;    
max-width: 260px;

//color: ${props => (props.print ? 'black' : 'white')};
/*
  ${({ print }) => print && `
    color: black;
  `}

    ${({ print }) => !print && `
    color: white;
    text-shadow: 2px 2px 2px #2e3336;
  `}
*/
  div:focus{    
    outline: -webkit-focus-ring-color auto 0px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 0px;
  }

  @media print{
      color: black;
  }

  @media screen {
      color: white;
    text-shadow: 2px 2px 2px #2e3336;
  }

`;

export default Wrapper;
