import styled from "styled-components";

const DefaultItemWrapper = styled.div` 
   height: 100%
   text-align: center;
   justify-content: center;
   align-items: center;
`;

export default DefaultItemWrapper;
