import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: [],
  errors: []
};

const layouts = (state = initialState, action) => {
  return produce(state, draft => {

    let layoutId = state.allIds.filter(id => id).map(id => state.byId[id]).filter(layout => layout).filter(layout => layout.pageId === action.pageId).map(layout => layout.layoutId);


    switch (action.type) {
      case Types.SET_COMPACTTYPE_OF_PAGE:
        if (layoutId.length > 0) {
          //Update
          draft.byId[layoutId] = {
            ...state.byId[layoutId],
            "compactType": action.compactType
          };
        }
        else {
          //Insert
          draft.byId = {
            ...state.byId, [action.layoutId]: {
              "layoutId": action.layoutId,
              "pageId": action.pageId,
              "compactType": action.compactType
            }
          };
          draft.allIds.push(action.layoutId);
        }
        break;

      case Types.SET_LAYOUT_OF_PAGE:
        if (layoutId.length > 0) {
          //Update          
          draft.byId[layoutId] = {
            ...state.byId[layoutId],
            "innerLayout": JSON.stringify(action.layout)
          };

        }
        else {
          //Insert
          draft.byId = {
            ...state.byId, [action.layoutId]: {
              "layoutId": action.layoutId,
              "pageId": action.pageId,
              "innerLayout": JSON.stringify(action.layout)
            }
          };
          draft.allIds.push(action.layoutId);
        }

        break;

      case Types.RESET_TO_INITIALSTATE:
        return initialState;
    }
  });
}

export default layouts