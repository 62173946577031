import styled from 'styled-components';

const ImageWrapper = styled.div`
    width: 100%;

    //Development
    //background: green;
`;

export default ImageWrapper;
