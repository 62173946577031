import styled from 'styled-components';

const MetaApprovalManagerContent = styled.div`
    position: absolute;
    top: calc(47.5pt + var(--offset-alvance-print-top));        
    left: calc(960pt + var(--offset-alvance-print-left));
    width: 136pt;
    height: 20pt;
    line-height: 13pt;

    font-size: 10pt;
    font-family: Arial;

    padding-top: 2.5pt;
    padding-left: 86pt;
    
    
`;

export default MetaApprovalManagerContent;
