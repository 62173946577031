import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./Loading";
import AuthenticationButton from "./AuthenticationButton";
import LogoutButton from "./LogoutButton";

const UserSidebar = () => {
    const { user, isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        return (
            isAuthenticated && (
                <div className="sidebar-header">
                    <div className="user-pic">
                        <img src={user.picture} alt={user.name} />
                    </div>
                    <div className="user-info">
                        <div className="user-name">{user.name}</div>
                    </div>
                    <LogoutButton />
                </div>
            ));
    }

    if (!isAuthenticated) {
        return (
            !isAuthenticated && (
                <div className="sidebar-header">
                    <AuthenticationButton />
                </div>
            ));
    }

};
export default UserSidebar;

/*
export default withAuthenticationRequired(UserSidebar, {
    onRedirecting: () => <Loading />,
  });
  */