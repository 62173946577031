import styled from 'styled-components';

const PageGridRow = styled.div`
    width: 100%;
    max-height: vh;
    display: flex;
    flex-wrap: wrap;    

    //display: table-cell;
    //height: 100%;
    //text-align: center;
    //width: 100%;
    vertical-align: middle;
    //padding: 5px;
    //margin: 5px;


    //Development
    background-color: green;
    //margin: 5px;
`;

export default PageGridRow;
