import styled from 'styled-components';

const MetaExecutorContent = styled.div`
    position: absolute;
    top: calc(76pt + var(--offset-alvance-print-top));        
    left: calc(647.5pt + var(--offset-alvance-print-left));
    width: 190.25pt;
    height: 20.25pt;
    line-height: 17pt;
    
    font-size: 10pt;
    font-family: Arial;
    
    padding-top: 2pt;
    padding-left: 73pt;
        
`;

export default MetaExecutorContent;
