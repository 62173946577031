import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import BoxHeaderV2 from '../../components/BoxHeaderV2';
import GoNoGoToggleButton from '../../components/GoNoGoToggleButton';
import PrintSinglePage from '../../Style/PrintSinglePage'


const MockBoxHeader = () => {
    const boxId1 = "mockBoxId3"
    const boxId2 = "mockBoxId4"
    const boxId3 = "c6688a1d-1b61-4999-97eb-d6a09447d46b"

    return (
        <ComponentWrapper>
            <PrintSinglePage >
                <BoxHeaderV2
                    key={boxId1}
                    boxId={boxId1}
                />
            </PrintSinglePage>
            <p></p>
            <PrintSinglePage>
                <BoxHeaderV2
                    key={boxId2}
                    boxId={boxId2}
                />
            </PrintSinglePage>
            <p></p>
            <PrintSinglePage>
                <BoxHeaderV2
                    key={boxId3}
                    boxId={boxId3}
                />
            </PrintSinglePage>
            <p></p>
            <PrintSinglePage>
                <GoNoGoToggleButton
                    key="1321"
                    boxId={boxId3}
                />
            </PrintSinglePage>
        </ComponentWrapper>
    );

}

export default MockBoxHeader;
