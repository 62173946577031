import styled from 'styled-components';

const MetaPlantContent = styled.div`
    position: absolute;
    top: calc(57pt + var(--offset-alvance-print-top));        
    left: calc(647.5pt + var(--offset-alvance-print-left));
    width: 190.25pt;
    height: 20pt;
    line-height: 17pt;
    
    font-size: 10pt;
    font-family: Arial;
    
    padding-top: 2pt;
    padding-left: 31.5pt;
    
    
`;

export default MetaPlantContent;
