import styled from 'styled-components';

const StartDateLabel = styled.div`
    position: absolute;
    top: calc(25pt + var(--offset-alvance-print-top));        
    left: calc(25pt + var(--offset-alvance-print-left));
    width: 477pt;
    height: 20pt;
    line-height: 17pt;

    font-size: 15pt;
    font-family: Arial;
    font-weight: bold;
    padding-top: 1pt;
    padding-left: 1.5pt;
    
    
`;

export default StartDateLabel;
