import styled from 'styled-components';

const ImageWrapper = styled.div`
    width: 200px;
    width: 120px;

    background-image: url('${props => props.src ? props.src : ""}');
    background-size: contain;
    background-repeat: repeat;
    background-position: center;

    //Development
    //background: green;
`;

export default ImageWrapper;
