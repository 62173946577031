import styled from 'styled-components';

const PageGridWrapper = styled.div`
    width: 100%;
    height: 94vh;
        text-align: center;    
    vertical-align: middle;
    margin: auto;

    //Development
    background-color: blue;
`;

export default PageGridWrapper;
