import { combineReducers } from 'redux'
import a3s from './a3s'
import a3 from './a3'
import todos from './todos'
import visibilityFilter from './visibilityFilter'
import auth from './auth'
import entitiesReducer from './entitiesReducer'
import current from './current'

export default combineReducers({
  auth,
  a3s,
  a3,
  todos,
  visibilityFilter,
  entities: entitiesReducer,
  current
})
