import styled from 'styled-components';

const MetaTitleContent = styled.div`
    position: absolute;
    top: calc(57pt + var(--offset-alvance-print-top));        
    left: calc(170pt + var(--offset-alvance-print-left));
    width: 478pt;
    height: 20pt;
    line-height: 17pt;

    font-size: 10pt;
    font-family: Arial;
    //font-weight: bold;
    padding-top: 2.25pt;
    padding-left: calc(0pt + (6 * 5pt));
    
    
`;

export default MetaTitleContent;
