import React from 'react';
import Carousel from 'react-elastic-carousel';
import CarrouselItem from './CarrouselItem';

//import boxCarouselData from "./BoxCarouselData.json";

import leftarrow from './img/left-arrow.png';
import rightarrow from './img/right-arrow.png';
import resize from './img/resize.png';
import addText from './img/addText.png';
import addImage from './img/addImage.png';
import removeImage from './img/removeImage.png';

import { withAuth0 } from '@auth0/auth0-react';

import PrintImageBasedOnID from './components/PrintImageBasedOnID';

//import plus from './img/plus.png';

const breakPoints = [
  { width: 1, itemsToShow: 1 }/*,
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },*/
];

class BoxCarousel extends React.Component {
  constructor(props) {
    super(props);

    //console.log("BoxCarousel - constructor called");

    this.activeIndex = 0;
    this.data = this.props.content;

    this.addCkEditorItemToCarrousel = this.addCkEditorItemToCarrousel.bind(this);
    this.handleCarrouselItemChange = this.handleCarrouselItemChange.bind(this);
    this.handleResizeBox = this.handleResizeBox.bind(this);
    this.removeFromCarrousel = this.removeFromCarrousel.bind(this);
    this.localImageUrl = "";
  }

  addCkEditorItemToCarrousel() {
    //console.log("BoxCarousel - addCkEditorItemToCarrousel called");
    //console.log(this.data);

    //var newData = this.data;
    //newData.splice(this.carousel.state.activePage, 0, { 'value': 'New box', 'type': 'editor' });
    let newData = [...this.data, { 'value': 'New box', 'type': 'editor' }]
    //newData.push({ 'value': 'New box', 'type': 'editor' });
    this.props.onBoxCarrouselChange(JSON.stringify(newData));
  }

  removeFromCarrousel() {
    //console.log("BoxCarousel - removeFromCarrousel called");
    //console.log(this.data);

    var newData = this.data;
    newData.splice(this.carousel.state.activePage, 1);
    this.props.onBoxCarrouselChange(JSON.stringify(newData));
  }


  handleCarrouselItemChange(value, i) {
    //console.log("BoxCarousel - handleCarrouselItemChange called");

    var newData = this.data;
    newData[i].type = "editor";
    newData[i].value = value;

    this.props.onBoxCarrouselChange(JSON.stringify(newData));
  }

  addImageItemToCarrousel() {
    //console.log("BoxCarousel - addImageItemToCarrousel called");

    this.refs.fileUploader.click();
    //this.props.onAddCkEditorItemToCarrousel("BoxCarousel - addCkEditorItemToCarrousel called");
  }

  onChangeFile(event) {
    //console.log("BoxCarousel - onChangeFile called");

    event.stopPropagation();
    event.preventDefault();

    var newData = this.data;
    var base64 = "";

    var file = event.target.files[0];

    this.localImageUrl = window.URL.createObjectURL(file);

    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      base64 = reader.result;


      //Also save the file to the API

      const { user } = this.props.auth0;
      const axios = require('axios');
      let uuid = "";
      let activePage = this.carousel.state.activePage;
      let onBoxCarrouselChange = this.props.onBoxCarrouselChange;

      if (user && user.email) {
        axios.post(process.env.REACT_APP_API_URL.concat('/api/setImage'), {
          email: user.email,
          image: base64,
        }, {
          'Access-Control-Allow-Origin': '*',
        })
          .then(function (response) {
            //console.log("Succesfull setImage call");   
            uuid = response.data.uuid;
            newData.splice(activePage, 0, { 'value': uuid, 'type': 'Base64ImageFromApi' });
            onBoxCarrouselChange(JSON.stringify(newData));
            //console.log(uuid);
          })
          .catch(function (error) {
            console.log(error);
            //newData.splice(activePage, 0, { 'value': base64, 'type': 'Base64Image' });
            //this.props.onBoxCarrouselChange(JSON.stringify(newData));
          })
          .then(function () {
            // always executed
          });
      }


    } // reader.onload
  }

  handleResizeBox() {
    //console.log("BoxCarousel - handleResizeBox called");

    this.props.onResizeBox();
  }

  handleLayoutChange(layout) {
    console.log("Layout change from BoxCarousel called!!");
    //setState({ layout: layout });
    let boxId = 1;
    let pageId = 1;


    //dispatch(storeLayout(layout, boxId, pageId));
  }


  render() {
    //console.log("BoxCarousel - render called");
    this.data = this.props.content;

    return (
      <div className="content">
        {
          //Show the header only when the showBoxHeader is true. The header should not be shown when printing
          !this.props.showBoxHeader ? <div></div> :
            <div>
              <img src={leftarrow} onClick={() => this.carousel.slidePrev()} height='35px' alt="Slide Carrousel left"></img>
              <img src={rightarrow} onClick={() => this.carousel.slideNext()} height='35px' alt="Slide Carrousel right"></img>
              <img src={resize} onClick={() => this.handleResizeBox()} height='35px' alt="Toggle resize box A3 view/fullscreen"></img>
              <img src={addText} onClick={() => this.addCkEditorItemToCarrousel()} height='35px' alt="Add a space to the carrousel"></img>
              <img src={addImage} onClick={() => this.addImageItemToCarrousel()} height='35px' alt="Add a visual to the carrousel"></img>
              <img src={removeImage} onClick={() => this.removeFromCarrousel()} height='35px' alt="Remove a visual from the carrousel"></img>
              <input type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={this.onChangeFile.bind(this)} />
              <hr />
            </div>
        }

        {!this.props.optimiseForPint ?
          <div>
            <Carousel key="1" ref={ref => (this.carousel = ref)} showArrows={false} breakPoints={breakPoints} >
              {this.data.map((content, i) => {
                return (
                  <CarrouselItem className="carrouselItem" key={i} i={i} focusOnBox={this.props.focusOnBox} type={content.type} data={content.value} id={i} onCarrouselItemChange={this.handleCarrouselItemChange} />
                )
              })}
            </Carousel>

          </div>
          :
          this.data.length > 0 && this.data[0] !== "" && this.data[0].value !== "" && this.data[0].type === "editor" ?
            <div className="printContent"><div className="printContentInner" dangerouslySetInnerHTML={{ __html: this.data[0].value }}></div></div>
            :
            this.data.length > 0 && this.data[0] !== "" && this.data[0].value !== "" && this.data[0].type === "Base64Image" ?
              <div className="printContent"><img alt="Alternative description" src={this.data[0].value} ></img></div>
              :
              this.data.length > 0 && this.data[0] !== "" && this.data[0].value !== "" && this.data[0].type === "Base64ImageFromApi" ?
                <PrintImageBasedOnID
                  imageId={this.data[0].value}
                /> : null
        }
        {
          /*this.localImageUrl && this.localImageUrl.length && this.localImageUrl.length > 0 ? <img src={this.localImageUrl}></img> : null*/
        }
      </div>
    )
  }
}

export default withAuth0(BoxCarousel);


/*



  render() {
    return (this.state.content !== null ?
      <div className="content">
        <img src={leftarrow} onClick={() => this.carousel.slidePrev()} height='35px'></img>
        <img src={rightarrow} onClick={() => this.carousel.slideNext()} height='35px'></img>
        <img src={resize} onClick={() => resizeBox()} height='35px'></img>
        <hr />
        <Carousel ref={ref => (this.carousel = ref)} showArrows={false}>
          {this.state.content.map((content, i) => {
            return <Item><CKEditor type="inline" data={content.value} id={i} /></Item>
          })}
        </Carousel>
      </div>
      :
      <div>BoxCarousel has an empty content</div>
    )
  }
  }


*/