import styled from 'styled-components';

const Wrapper = styled.div`
    height:94%;        
    overflow: hidden;
    padding: 5px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #dae136;
    background-size: 100% auto;
    margin: 0px 0px 16px 0px;
`;

export default Wrapper;