import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { setContentValue } from '../../actions';

import Item from './Item';
import EditorItem from './EditorItem';
import DefaultItem from './DefaultItem';
import PrintItem from './PrintItem';
import ImageWrapper from './ImageWrapper';
import PrintImage from './PrintImage';

const ItemSwitch = React.memo((props) => {
    //Console
    //console.log("ItemSwitch rendered");

    //Props
    const { multiplier, contentId, boxId, print } = props;

    //Use
    //const dispatch = useDispatch();

    //Selectors            
    const selectContent = state => state.entities.contents.byId[contentId];
    const selectContentType = createSelector(
        selectContent,
        content => {
            return content ? content.type : "";
        }
    );

    const selectContentUrl = createSelector(
        selectContent,
        content => {
            return content ? content.localUrl || content.remoteUrl : "";
        }
    );

    //Consts
    const { v4: uuidv4 } = require('uuid');
    const contentType = useSelector(selectContentType);
    const contentUrl = useSelector(selectContentUrl);

    //console.log("contentType", contentType)

    /*
    if (contentType === "image") {
        console.log("contentId", contentId)
        console.log("contentUrl", contentUrl)
    }
    */


    return (contentType === "editor" ?
        <EditorItem
            //key={uuidv4()}
            //key={contentId}
            multiplier={multiplier}
            contentId={contentId}
            print={print}
        />
        : contentType === "image" ?
            <Item multiplier={multiplier}>
                {print ?
                    <PrintImage>
                        <img src={contentUrl} height="205pt" />
                    </PrintImage>
                    :
                    <ImageWrapper
                        //key={uuidv4()}
                        src={contentUrl}
                    />
                }

            </Item>
            : contentType === "print" ?
                <PrintItem multiplier={multiplier}>
                    {contentUrl}
                </PrintItem>

                : contentType === "default" ?
                    <DefaultItem
                        //key={uuidv4()}
                        boxId={boxId}
                        multiplier={multiplier}
                    />
                    : null

    )
});

export default ItemSwitch;