import styled from 'styled-components';

const Logo = styled.div`
    position: absolute;    
    top: calc(57pt + var(--offset-alvance-print-top));
    left: calc(50pt + var(--offset-alvance-print-left));
    width: 122pt;
    height: 39.25pt;

    border-top: 1pt solid black;
    border-left: 1pt solid black;
    border-right: 1pt solid black;
    border-bottom: 1pt solid black;
     
    //background-color: #b52263; //Make sure this is configured before the background-image or the image will dissapear
    //background-color: black;
    background-image: url('${props => props.src ? props.src : "Alvance.png"}');
    background-size: contain;
    background-repeat: ${props => props.repeat ? "repeat" : "no-repeat"};
    background-position: center;    

    opacity: 1;
`;

export default Logo;
