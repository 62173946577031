import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from './Wrapper';
import { selectCurrent } from '../../Selectors';
import BoxGridV2 from '../BoxGridV2';


function PrintA3(props) {
    //Props
    const { a3Id } = props;

    //Selectors    
    const current = useSelector(selectCurrent);

    //Var & Const
    let a3IdToDisplay = a3Id ? a3Id : current.a3Id

    //Handlers    
    document.documentElement.style.setProperty("--myfontsize", current.fontSizeCKEditor)

    if (!a3IdToDisplay)
        return null;

    return (
        <Wrapper>
            <BoxGridV2
                a3Id={a3IdToDisplay}
                print={true}
            />
        </Wrapper>
    );

}
export default PrintA3;
