import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
import ImageWrapper from "../ImageWrapper";
import Image from "../Image";
import { CKEditor } from '@ckeditor/ckeditor5-react';

//import Context from '@ckeditor/ckeditor5-core/src/context';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const vertical = "vertical";
const horizontal = "horizontal";
const float = false;

export default class PageLayoutContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBreakpoint: "lg",
            compactType: this.props.compactType || vertical,
            mounted: false,
            items: [],
            /*
                        items: [0].map(function (i, key, list) {
                            return {
                                i: i.toString(),
                                x: i * 20,
                                y: 0,
                                w: 20,
                                h: 12,
                                add: i === (list.length - 1)
                            };
                        }),
                        */
            newCounter: 0,
            layouts: this.props.layouts || { lg: props.initialLayout }
            //layouts: { lg: props.initialLayout }
        };
        //console.log("layouts", this.props.layouts);

        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onNewLayout = this.onNewLayout.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onCompactTypeVertical = this.onCompactTypeVertical.bind(this);
        this.onCompactTypeHorizontal = this.onCompactTypeHorizontal.bind(this);
        this.onCompactTypeFloat = this.onCompactTypeFloat.bind(this);


    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    generateDOM(contentObject, contentComponents) {
        //console.log("this.state.layouts.lg", this.state.layouts.lg);
        //console.log("contentComponents", contentComponents);

        const removeStyle = {
            position: "absolute",
            right: "2px",
            top: 0,
            cursor: "pointer"
        };

        return _.map(this.state.layouts.lg, function (l, i) {
            //return _.map(this.state.items, function (l, i) {

            return (
                <div key={i} className={l.static ? "static" : ""}>
                    {contentComponents && contentComponents[i] ?
                        <div>{contentComponents[i]}</div> :
                        l.static ?
                            <span
                                className="text"
                                title={contentObject[i].value}
                            >
                                {contentObject[i].value}
                            </span> :
                            contentObject[i] && contentObject[i].type === "editor" ?
                                <CKEditor
                                    editor={InlineEditor}
                                    data={contentObject[i].value}
                                /> :
                                contentObject[i] && contentObject[i].type === "image" ?
                                    <ImageWrapper
                                        src={contentObject[i].value}
                                    /> : null
                    }

                </div>
            );
        });
    }

    onRemoveItem(i) {
        console.log("removing", i);
        this.setState({ items: _.reject(this.state.items, { i: i }) });
    }

    onBreakpointChange(breakpoint) {
        this.setState({
            currentBreakpoint: breakpoint
        });
    }

    onCompactTypeChange() {
        const { compactType: oldCompactType } = this.state;
        const compactType =
            oldCompactType === "horizontal"
                ? "vertical"
                : oldCompactType === "vertical"
                    ? null
                    : "horizontal";
        this.props.onCompactTypeChange(compactType);
        this.setState({ compactType });
    }

    onLayoutChange(layout, layouts) {
        //this.props.onLayoutChange(layout, layouts);
        this.setState({ layout: layout });
    }

    onNewLayout() {
        this.setState({
            layouts: { lg: gridLayout() }
        });
    }

    onAddItem() {
        /*eslint no-console: 0*/
        console.log("adding", "n" + this.state.newCounter);

        this.setState({
            // Add a new item. It must have a unique key!
            items: this.state.items.concat({
                i: this.state.newCounter.toString(),
                x: (this.state.items.length * 20) % (this.state.cols || 12),
                y: Infinity, // puts it at the bottom
                w: 20,
                h: 15
            }),
            // Increment the counter to ensure key is always unique.
            newCounter: this.state.newCounter + 1
        });

        console.log("New state", this.state.items)
    }

    createElement(el, contentObject, contentComponents) {
        const removeStyle = {
            position: "absolute",
            right: "2px",
            top: 0,
            cursor: "pointer"
        };
        const i = el.add ? "+" : el.i;

        //console.log("contentObject", contentObject);
        //console.log("contentComponents", contentComponents);
        //console.log("i", i);

        return (
            <div key={i} data-grid={el} >
                {el.add ? (
                    <span
                        className="add text"
                        onClick={this.onAddItem}
                        title="You can add an item by clicking here, too."
                    >
                        Add +
                    </span>
                ) : (

                    contentComponents && contentComponents[el.i] ?
                        <div>{contentComponents[el.i]}</div> :
                        contentObject[el.i] && contentObject[el.i].type === "editor" ?
                            <CKEditor
                                editor={InlineEditor}
                                data={contentObject[el.i].value}
                            /> :
                            contentObject[el.i] && contentObject[el.i].type === "image" ?
                                <ImageWrapper
                                    src={contentObject[el.i].value}
                                /> : null


                )}
                <span
                    className="remove"
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}
                >
                    x
        </span>
            </div>
        );

        /*
        return (
            <div key={i} data-grid={el}>
                {el.add ? (
                    <span
                        className="add text"
                        onClick={this.onAddItem}
                        title="You can add an item by clicking here, too."
                    >
                        Add +
                    </span>
                ) : (
                        <span className="text">{i}</span>
                    )}
                <span
                    className="remove"
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}
                >
                    x
        </span>
            </div>
        );

//////////////

            return (
                <div key={i} className={l.static ? "static" : ""}>
                    {contentComponents && contentComponents[i] ?
                        <div>{contentComponents[i]}</div> :
                        l.static ?
                            <span
                                className="text"
                                title={contentObject[i].value}
                            >
                                {contentObject[i].value}
                            </span> :
                            contentObject[i] && contentObject[i].type === "editor" ?
                                <CKEditor
                                    editor={BalloonEditor}
                                    data={contentObject[i].value}
                                /> :
                                contentObject[i] && contentObject[i].type === "image" ?
                                    <ImageWrapper
                                        src={contentObject[i].value}
                                    /> : null
                    }
                </div>
            );

        */
    }

    onCompactTypeVertical() {
        this.props.onCompactTypeChange(vertical);
        this.setState({ compactType: vertical });
    }

    onCompactTypeHorizontal() {
        this.props.onCompactTypeChange(horizontal);
        this.setState({ compactType: horizontal });
    }

    onCompactTypeFloat() {
        //this.props.onCompactTypeChange(float);
        this.setState({ compactType: float });
    }

    render() {
        return (
            <div>
                <button onClick={this.onAddItem}>Add Item</button>
                <button onClick={this.onCompactTypeVertical}>CompactType to Vertical</button>
                <button onClick={this.onCompactTypeHorizontal}>CompactType to Horizontal</button>
                <button onClick={this.onCompactTypeFloat}>CompactType to Float</button>
                <ResponsiveReactGridLayout
                    {...this.props}
                    //layouts={this.state.layouts}
                    //layouts={this.props.layouts}
                    onBreakpointChange={this.onBreakpointChange}
                    onLayoutChange={this.onLayoutChange}
                    // WidthProvider option
                    measureBeforeMount={false}
                    // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                    // and set `measureBeforeMount={true}`.
                    useCSSTransforms={this.state.mounted}
                    compactType={this.state.compactType}
                    preventCollision={!this.state.compactType}
                >

                    {_.map(this.state.items, el => this.createElement(el, this.props.contentObjects, this.props.contentComponents))}
                    {this.generateDOM(this.props.contentObjects, this.props.contentComponents)}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}

PageLayoutContent.propTypes = {
    onLayoutChange: PropTypes.func.isRequired
};

PageLayoutContent.defaultProps = {
    className: "layout",
    rowHeight: 10,
    onLayoutChange: function () { },
    cols: { lg: 140, md: 140, sm: 140, xs: 140, xxs: 140 },
    initialLayout: gridLayout()
};

function gridLayout() {
    return _.map(_.range(0, 4), function (item, i) {
        return {
            x: (i * 12) % 24,
            y: 0,
            w: 120,
            h: 90,
            i: i.toString(),
            static: false
        };
    });
}

/*
function generateLayout() {
    return _.map(_.range(0, 4), function (item, i) {
        var y = Math.ceil(Math.random() * 4) + 1;
        return {
            x: (_.random(0, 5) * 2) % 24,
            y: Math.floor(i / 6) * y,
            w: 2,
            h: y,
            i: i.toString(),
            static: Math.random() < 0.05
        };
    });
}
*/
/*
{this.generateDOM(this.props.contentObjects, this.props.contentComponents)}
*/