import React from 'react';

import ComponentWrapper from './ComponentWrapper';
import SettingsComponent from '../../components/Settings';

const Settings = () => {

    return (
        <ComponentWrapper>
            <SettingsComponent  />
        </ComponentWrapper>
    );

}

export default Settings;
