import produce from 'immer';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: []
};

const metas = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {

      case Types.SET_A3_NORMALIZED:
        //console.log("action.payload.metas", action.payload.metas);
        return produce(state, draft => {

          delete draft.byId;
          draft.byId = {
            ...draft.byId, ...action.payload.metas.byId
          };

          draft.allIds = [...new Set([...draft.allIds, ...action.payload.metas.allIds])];
        });

      case Types.SET_META_VALUE:
        draft.byId[action.id].value = action.value;
        break;

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

      default:
        break;
    }
  });
}

export default metas