import produce from 'immer';
import {
  SET_A3S,
  API_START,
  API_END,
  FETCH_A3S
} from "../actions/types";
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: [],
};

const a3s = (state = initialState, action) => {
  //console.log("actions from a3s");
  //console.log(action);

  if ((action.type === API_START || action.type === API_END) && action.payload !== FETCH_A3S)
    return state;

  switch (action.type) {
    case Types.SET_A3S_NORMALIZED:
      /*
      return produce(state, draft => {
        draft.byId = action.payload.byId;
        draft.allIds = action.payload.allIds;
      });
      */

      //Add to existing as3

      return produce(state, draft => {
        if (action.payload && action.payload.byId) {
          draft.byId = {
            //...draft.byId,
            ...action.payload.byId
          };
        }
        if (action.payload && action.payload.allIds) {
          draft.allIds = [...new Set([
            //...draft.allIds,
            ...action.payload.allIds
          ])];
        }
      });


    case 'ADD_A3':
      return [
        ...state,
        {
          a3id: action.a3id,
          title: action.title,
          thumbnailid: action.thumbnailid,
        }
      ];

    case 'ADD_A3S':
      return action.payload;

    case SET_A3S:
      return { data: action.payload };


    case API_START:
      if (action.payload === FETCH_A3S) {
        return {
          ...state,
          isLoadingData: true
        };
      }
      break;

    case API_END:
      if (action.payload === FETCH_A3S) {
        return {
          ...state,
          isLoadingData: false
        };
      }
      break;

    case Types.CREATE_EMPTY_A3:
      return produce(state, draft => {
        draft.byId = {
          ...draft.byId, ...action.a3,
        };

        draft.allIds = [...draft.allIds, action.a3Id];
      });

    case Types.REMOVE_A3_FROM_STATE:
      return produce(state, draft => {
        if (draft.byId[action.a3Id])
          delete draft.byId[action.a3Id]

        for (var i = 0; i < draft.allIds.length; i++) {
          if (draft.allIds[i] === action.a3Id) {
            draft.allIds.splice(i, 1);
            i--;
          }
        }

      });

    case Types.SET_A3_TITLE:
      return produce(state, draft => {
        if (draft.byId[action.a3Id])
          draft.byId[action.a3Id].title = action.title;
      });

    case Types.RESET_TO_INITIALSTATE:
      return initialState;

    default:
      return state;
  }
}

export default a3s