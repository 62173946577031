import styled from 'styled-components';

const BackgroundImage = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    //background-image: url("https://picsum.photos/500/500?random");
    background-image: url(${props => props.show ? "./A3-Aleris.jpg" : ""});    
    background-size: contain;
    background-repeat: no-repeat;    
    opacity: 0.25;
`;

export default BackgroundImage;
