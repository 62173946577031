import { Type } from 'ajv/dist/compile/subschema';
import produce from 'immer';
import { init } from 'logrocket';
import * as Types from "../actions/types";

const initialState = {
  byId: {},
  allIds: [],
  errors: []
};

const contents = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_VALUE_OF_CONTENT:
        if (draft.byId[action.contentId])
          draft.byId[action.contentId].value = action.value
        else {
          draft.errors.push({ reason: `Error in ${action.type}. ContentId "${action.contentId}" is unknown in state` })
        }
        break;

      case Types.CREATE_EMPTY_A3:
        draft.byId = {
          ...draft.byId, ...action.content1, ...action.content2, ...action.content3, ...action.content4, ...action.content5, ...action.content6, ...action.content7, ...action.content8, ...action.content9
        };

        draft.allIds = [...draft.allIds, ...action.allContentIds];
        break;

      case Types.ADD_CONTENT_TO_BOX:
      case Types.ADD_IMAGE_CONTENT_TO_BOX:
      case Types.ADD_RANDOMIMAGE_CONTENT_TO_BOX:
      case Types.ADD_EDITOR_CONTENT_TO_BOX:
        draft.byId = {
          ...draft.byId, ...action.content
        };

        draft.allIds = [...draft.allIds, action.contentId];
        break;

      case Types.REMOVE_CONTENT_FROM_BOX:
        if (draft.byId[action.contentId])
          delete draft.byId[action.contentId]

        for (var i = 0; i < draft.allIds.length; i++) {
          if (draft.allIds[i] === action.contentId) {
            draft.allIds.splice(i, 1);
            i--;
          }
        }
        break;

      case Types.SET_A3_NORMALIZED:
        return produce(state, draft => {
          draft.byId = {
            ...draft.byId, ...action.payload.contents.byId
          };
          draft.allIds = [...new Set([...draft.allIds, ...action.payload.contents.allIds])];
        });

      case Types.SET_CONTENT_VALUE:
        draft.byId[action.id].value = action.value;
        break;

      case Types.RESET_TO_INITIALSTATE:
        return initialState;

    }
  });
}

export default contents