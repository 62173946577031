import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    padding-right: 240px;
    //margin-right: 10px;
    //background-color: green;
`;

export default Wrapper;
