import styled from 'styled-components';

const BoxContent = styled.div`
    position: absolute;
    top: calc(${props => props.topOffset} + var(--offset-alvance-print-top));    
    left: calc(${props => props.leftOffset} + var(--offset-alvance-print-left));
    width: ${props => props.width};
    height: ${props => props.height};
    line-height: 7.5pt;

    font-size: 6.75pt;
    font-family: Arial;

    background: transparent;
    
    padding-left: 3pt;    
    padding-top: 10pt;    

    overflow: hidden; 

    p {
        font-size: 6.75pt;
        text-align: left;
        //font-weight: normal;
    }

    .rnd:focus{        
        border: 1pt dotted black;        
    }

    .rnd:hover{
        border: 1pt dotted black;        

    }

    .rndImage:focus{        
        background-color: lightgrey;        
    }

    .rndImage:hover{
        background-color: lightgrey;        
    }

    table{
        position: absolute;
        top: 2pt;
        left: 2pt;
        max-width: calc(${props => props.width} - 7.5pt);    
        max-height: calc(${props => props.height} - 14pt);  
        width: 100% !important;
        height: 100% !important;
        //zoom: 0.7;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transform: scaleX(1) scaleY(1);
    }

    tr:first-child td {
        border: 0.5pt solid black;
        //font-weight: 900;
        font-size: 7pt;
        text-align: center;        
        height: 1pt;  
        //background-color: yellow;           
    }

    tr{        
        border: 0.5pt solid black;        
    }

    td{        
        border: 0.5pt solid black;      
        
        margin: 0;
        text-align: left;    
        font-size: 6.75pt; 
        //background-color: green;   
        padding: 2pt;        
    }                 

    h4{
        font-size: 8pt;
        color: lightblue;        
        padding: 0;
        margin: 0pt;
        text-align: left;        
    }

`;

export default BoxContent;
