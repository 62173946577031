import React, { useState, useEffect } from "react";
//import { useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { SetBoxTitle } from '../../actions'

import Wrapper from "./Wrapper";

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import InputNumber from 'rc-input-number';
import BoxNotifier from "../BoxNotifier";

import { Icon, Label, Container, Segment, Grid, Header } from 'semantic-ui-react'

import { selectCurrent } from '../../Selectors';

import {
    setFontSizeCKEditor,
    resetFontSizeCKEditor,
    ResetToInitialState
} from '../../actions'

import Button from '@material-ui/core/Button';

//import '../node_modules/rc-input-number/dist/rc-input-number.css';
//import '../node_modules/rc-input-number/assets/index.css';

const SettingsComponent = React.memo((props) => {
    //Console
    //console.log("Settings rendered");

    //Props
    const { } = props;

    //Selectors    
    const current = useSelector(selectCurrent);

    //Selectors
    /*
    const selectBox = state => state.entities.boxes.byId[boxId];
    const selectBoxTitle = createSelector(
        selectBox,
        box => {
            return box ? box.title : "";
        }
    );
*/
    //Use
    const dispatch = useDispatch();
    //const history = useHistory();

    //Consts
    // const boxTitle = useSelector(selectBoxTitle);

    //State
    //const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(boxTitle || ""))));

    //Keep state in sync
    /*
    useEffect(() => {
        if (editorState !== boxTitle)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(boxTitle))));
    }, [boxTitle]);
    */

    //Handlers
    /*
    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        dispatch(SetBoxTitle(boxId, draftToHtml(convertToRaw(editorState.getCurrentContent()))));
    };
    */

    const style = {
        h1: {
            marginTop: '0.5em',
        },
        h2: {
            margin: '4em 0em 2em',
        },
        h3: {
            marginTop: '2em',
            padding: '2em 0em',
        },
        last: {
            marginBottom: '300px',
        },
    }

    const onDefaultFontSizeChange = (fontSize) => {
        //setEditorState(editorState);
        //dispatch(SetBoxTitle(boxId, draftToHtml(convertToRaw(editorState.getCurrentContent()))));
        console.log("Send the change: ", fontSize);
        dispatch(setFontSizeCKEditor(fontSize));
    };



    return (
        <Wrapper>
            <BoxNotifier />

            <Header as='h2' content='Font size' style={style.h1} textAlign='left' />

            <div>Editor: <InputNumber value={current.fontSizeCKEditor.replace("px", "")} style={{ width: '70px' }} onChange={e => onDefaultFontSizeChange(e)} />
                &nbsp;&nbsp;
                <Button variant="outlined" color="primary" size="small"
                    onClick={() => { dispatch(resetFontSizeCKEditor()) }}
                >
                    Reset
                </Button>
            </div>
            <br />

            <Header as='h2' content='App' style={style.h1} textAlign='left' />
            <div>
                <Button variant="outlined" color="secondary" size="small"
                    onClick={() => { dispatch(ResetToInitialState()) }}
                >
                    Clear cache
                </Button>
                &nbsp;&nbsp;
                This button is only needed when you want to give the app a good kick and start over from the inital state.
             <br />
            </div>
        </Wrapper>
    );
});

export default SettingsComponent;