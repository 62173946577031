import styled from 'styled-components';

const Box1Content = styled.div`
    position: absolute;
    top: calc(95.25pt + var(--offset-alvance-print-top));    
    left: calc(87pt + var(--offset-alvance-print-left));
    width: 216pt;
    height: 181pt;
    line-height: 8pt;    

    font-size: 6.75pt;
    font-family: Arial;
    
    padding-left: 3pt;    
    padding-top: 10pt;    

    p {
        font-size: 6.75pt;
    }

    table{
        //display: table; 
        max-width: 490pt;    
        max-height: 200pt;    
        table-layout:fixed;
        width: 100% !important;
        height: 100% !important;
        //zoom: 0.7;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transform: scaleX(1) scaleY(1);
    }

    tr:first-child td {
        border: 0.5pt solid black;
        font-weight: 900;
        font-size: 7pt;
        text-align: center;        
    }

    tr{        
        border: 0.5pt solid black;        
    }

    td{        
        border: 0.5pt solid black;        
    } 

`;

export default Box1Content;
