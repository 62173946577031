import styled from 'styled-components';

const Wrapper = styled.div`



`;

export default Wrapper;

/*
@media print {
    @page {
        size: A3 landscape
    }

    width: 1191pt;
    height: 842pt;
}
*/

/*
@media print {@page {size: A3 landscape} body { zoom: 90%; width:98%; height: 98%; overflow: hidden; font-size: 12pt; } h1 { font-size: 16pt; } h2 { font-size: 14pt; } }
*/