import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { setContentValue } from '../../actions';
import { setContentValueSaga } from '../../sagas';

import { CKEditor } from '@ckeditor/ckeditor5-react';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Item from './Item';
import Inner from './Inner';

const EditorItem = React.memo((props) => {
    //Props
    const { multiplier, contentId, print } = props;

    //Console
    //console.log("EditorItem rendered", contentId);

    //Use
    const dispatch = useDispatch();

    //Selectors            
    const selectContent = state => state.entities.contents.byId[contentId];
    const selectContentValue = createSelector(
        selectContent,
        content => {
            return content ? content.value : "";
        }
    );

    //Consts
    const { v4: uuidv4 } = require('uuid');
    const contentValue = useSelector(selectContentValue);

    //State
    const [stateValue, setStateValue] = useState(contentValue);


    //Handlers
    const handleEditorChange = (value, contentId) => {
        setStateValue(value);
        //dispatch(setContentValue(value, contentId));
        dispatch({
            type: 'SET_VALUE_OF_CONTENT_SAGA',
            contentId,
            value
        })
    }

    return (
        <Item multiplier={multiplier}>
            <Inner>
                {!print ?
                    <CKEditor
                        editor={InlineEditor}
                        data={stateValue}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            //console.log( 'Editor is ready to use!', editor );
                        }}
                        onChange={(event, editor) => {
                            handleEditorChange(editor.getData(), contentId)
                        }}
                        onBlur={(event, editor) => {
                            //console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                            //console.log( 'Focus.', editor );
                        }}
                    />
                    :
                    <div contentEditable='true' dangerouslySetInnerHTML={{ __html: stateValue }} />
                }
            </Inner>
        </Item>
    );
});

export default EditorItem;