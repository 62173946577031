import React from 'react';
import Box from "./Box";
import MetaHeader from "./MetaHeader";

class LayoutToPrint extends React.Component {
  constructor(props) {
    super(props);

    //console.log("LayoutToPrint - constructor called");    
    this.handleBoxChange = this.handleBoxChange.bind(this);
  }

  handleBoxChange() {
    //console.log("LayoutToPrint - handleBoxChange called");

  }

  render() {
    //console.log("LayoutToPrint - render called");    
    this.data = this.props.data;

    return (

      <div>
        <style type="text/css">
          {"@media print {@page {size: A3 landscape} body { zoom: 90%; width:98%; height: 98%; overflow: hidden; font-size: 12pt; } h1 { font-size: 16pt; } h2 { font-size: 14pt; } }"}
        </style>

        <MetaHeader key="metaHeader1"
          data={this.data}
          meta={this.data.meta}
          optimiseForPint={true}
        />

        <div className="row">
          <div id="column1" className="column">
            {
              this.data.box.map((box, i) => {
                return (i >= 0 && i < 3 ?
                  <div key={i}>
                    <Box key={i} i={i} box={box}
                      onBoxChange={this.handleBoxChange}
                      showBoxHeader={false}
                      optimiseForPint={true}
                    />
                  </div> : <div key={i}></div>
                );
              })
            }
          </div>
          <div id="column2" className="column">
            {
              this.data.box.map((box, i) => {
                return (i >= 3 && i < 6 ?
                  <div key={i}>
                    <Box key={i} i={i} box={box}
                      onBoxChange={this.handleBoxChange}
                      showBoxHeader={false}
                      optimiseForPint={true}
                    />
                  </div> : <div key={i}></div>
                );
              })
            }
          </div>
          <div id="column3" className="column">
            {
              this.data.box.map((box, i) => {
                return (i >= 6 && i < 9 ?
                  <div key={i}>
                    <Box key={i} i={i} box={box}
                      onBoxChange={this.handleBoxChange}
                      showBoxHeader={false}
                      optimiseForPint={true}
                    />
                  </div> : <div key={i}></div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutToPrint;