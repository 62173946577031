import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
    root: {
        width: 250,
    },
    input: {
        width: 58,
    },
});

export default function InputSlider() {
    const classes = useStyles();
    const min = 0;
    const max = 10000;

    const [minValue, setMinValue] = React.useState(min);
    const [maxValue, setMaxValue] = React.useState(max);
    const [value, setValue] = React.useState([minValue, maxValue]);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        setMinValue(newValue[0]);
        setMaxValue(newValue[1]);
    };

    const handleInputChangeMin = (event) => {
        setMinValue(event.target.value === '' ? '' : Number(event.target.value));
        setValue([minValue, maxValue]);
    };

    const handleInputChangeMax = (event) => {
        setMaxValue(event.target.value === '' ? '' : Number(event.target.value));
        setValue([minValue, maxValue]);
    };

    const handleBlurMin = () => {
        if (minValue < min) {
            setMinValue(min);
        } else if (minValue > max) {
            setMinValue(max);
        }
    };

    const handleBlurMax = () => {
        if (maxValue < min) {
            setMaxValue(min);
        } else if (maxValue > max) {
            setMaxValue(max);
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Input
                        className={classes.input}
                        value={minValue}
                        margin="dense"
                        onChange={handleInputChangeMin}
                        onBlur={handleBlurMin}
                        inputProps={{
                            step: 100,
                            min: min,
                            max: max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={value}
                        min={min}
                        max={max}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={classes.input}
                        value={maxValue}
                        margin="dense"
                        onChange={handleInputChangeMax}
                        onBlur={handleBlurMax}
                        inputProps={{
                            step: 100,
                            min: min,
                            max: max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
