// Needed for redux-saga es6 generator support
//import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
//import FontFaceObserver from 'fontfaceobserver';
//import history from 'utils/history';
import 'sanitize.css/sanitize.css';

//Legacy stuff
import LanguageProvider from './LanguageProvider';
import { BrowserRouter as Router } from "react-router-dom";
//import { Router } from "react-router-dom";
import './index.css';
//import { createStore } from 'redux'
//import configureStore from './configureStore';

// Import i18n messages
//import { translationMessages } from './i18n';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import configureStore from './configureStore';

const MOUNT_NODE = document.getElementById('root');
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Auth0ProviderWithHistory >
        <React.StrictMode>
          <LanguageProvider />
        </React.StrictMode>
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>,
  MOUNT_NODE
);

