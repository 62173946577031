import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SetGoToPage, AddEditorContentToBox, AddRandomImageContentToBox, AddImageContentToBox, SetGoToLastPage, RemoveContentFromBox, SetRemoveActivePage } from '../../actions';
import { makeSelectContentsSelector } from '../../Selectors';

import ButtonWrapper from './ButtonWrapper'

import { Button } from '@material-ui/core';
import DefaultItemWrapper from './DefaultItemWrapper';


const DefaultItem = React.memo((props) => {
    //Console
    //console.log("DefaultItem rendered");

    //Props
    const { boxId, multiplier } = props;
    const viewId = "";
    const pageId = "";
    const filterForImagesOnly = false;

    //Use
    const dispatch = useDispatch();

    const selectContentsSelector = useMemo(makeSelectContentsSelector, [boxId, viewId, pageId, filterForImagesOnly])
    const contents = useSelector((state) => selectContentsSelector(state, boxId, viewId, pageId, filterForImagesOnly))

    // Const & Var
    const { v4: uuidv4 } = require('uuid');

    let carousel;// = useRef();
    let fileUploader;
    //const fileInput = useRef(null);

    //Handlers
    const handleClickRemovePage = () => {
        dispatch(RemoveContentFromBox(boxId, contents[carousel.state.activePage].id))
    }

    const handleClickAddEditor = () => {
        dispatch(AddEditorContentToBox(boxId))
        dispatch(SetGoToLastPage(boxId, true));
    }

    const handleClickAddRandomImage = () => {
        dispatch(SetGoToLastPage(boxId, true));
        dispatch(AddRandomImageContentToBox(boxId))
    }

    const handleClickAddImage = () => {
        fileUploader.click();
    }

    const handleChangeFile = (event) => {
        //Retrieve the file
        event.stopPropagation();
        event.preventDefault();

        const file = event && event.target && event.target.files ? event.target.files[0] : false;

        if (file) {
            let localUrl = file ? window.URL.createObjectURL(file) : "";
            dispatch(AddImageContentToBox(boxId, localUrl))
            dispatch(SetGoToPage(boxId, "LAST"));
            //dispatch(SetGoToLastPage(boxId, true));
        }
    }

    return (
        <DefaultItemWrapper>
            <input type="file" id="file" ref={ref => (fileUploader = ref)} style={{ display: "none" }} onChange={(event) => handleChangeFile(event)} />

            <ButtonWrapper key={uuidv4()}>
                <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleClickAddEditor()}
                >Add text page</Button>
            </ButtonWrapper>
            <ButtonWrapper key={uuidv4()}>
                <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleClickAddImage()}
                >Add image</Button>
            </ButtonWrapper>
            <p></p>
            <ButtonWrapper key={uuidv4()}>
                <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleClickAddRandomImage()}
                >Add random image</Button>
            </ButtonWrapper>
            <ButtonWrapper key={uuidv4()}>
                <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleClickRemovePage()}
                >Remove</Button>
            </ButtonWrapper>

        </DefaultItemWrapper>
    );
});

export default DefaultItem;