import styled from "styled-components";

export default styled.div`

  //background-color: green;

@media screen {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //align-content: center;
  width: 100%;
  //height: 320px;
  //height: 100%;    
  //align-items: stretch;
  //height: 100vmax;

  //height: calc(calc(100vw - 270px)/3.5 * (595/842));
  //height: calc(23vw * ${props => props.multiplier} * (595/842));
  //height: calc(calc(100vw)/3 * ${props => props.multiplier} * (595/842))
  //height: calc(var(width * (595/842))
  //max-height: calc(10em * (9/16));

  padding-top: 70.66%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */

  //min-height: 235px;
  //max-height: 100%;
  //height: props.height;
  
  //color: #fff;
  //margin: 8px 8px 8px 8px;
  //font-size: 4em;

  //    align-items: center;
  //  justify-content: center;
  //  display: flex;

  //Development
  //background-color: lightgrey;
    
  display: flex;
  justify-content: center;
  align-items: center;  
  
}

`;

/*
@media print {
    width: 100%;
    height: 100%;
    background-color: lightgrey;

    @page {
        size: A4 landscape;
       }
*/