import styled from 'styled-components';

const PageGridImage = styled.image`
    //width:100%;
    //max-width: 100%; 
    //height: 200%; 

    //display: flex;
    //margin-left: auto;
    //margin-right: auto;
    width: 50%;

    display: flex;
    //height: 100%;
    text-align: center;    
    vertical-align: middle;
    //padding: 5px;
    //margin: 5px;

    max-width: 200%;
    max-height: 200%;
    //bottom: 0;
    //left: 0;
    margin: auto;
    //overflow: auto;
    //position: fixed;
    //right: 0;
    //top: 0;
    -o-object-fit: contain;
    object-fit: contain;

    //Development
    background-color: red;

`;

export default PageGridImage;
