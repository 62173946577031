import React, { Component } from 'react';
import Item from "./Item";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

//import Context from '@ckeditor/ckeditor5-core/src/context';
//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';


class CKEditorItem extends React.Component {
    constructor(props) {
        super(props);

        this.handleCKEditorChange = this.handleCKEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleCKEditorChange(evt) {
        //console.log(evt.editor.getData());        
        this.props.onCKEditorItemChange(evt.editor.getData());
    }

    handleChange(value) {
        this.props.onCKEditorItemChange(value);
    }

    render() {
        //console.log("CKEditorItem - render called");
        const ckeditorId = "CKEditor-".concat(this.props.id);

        return (
            <Item>
                <CKEditor
                    editor={InlineEditor}
                    data={this.props.data}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.props.onCKEditorItemChange(data);
                        //console.log( { event, editor, data } );
                    }}
                    onBlur={(event, editor) => {
                        //console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                        //console.log( 'Focus.', editor );
                    }}
                />
            </Item>
        )
    }
}

export default CKEditorItem;

/*
                                    <CKEditor
                name={ckeditorId}
                type="inline"
                data={this.props.data}
                placeholder={"Complete this box..."}
                id={ckeditorId}
                key={ckeditorId}
                onChange={this.handleCKEditorChange}
                //The config does not yet seem to work due to the inline config
                config={{
                    resize_enabled: true,
                    startupShowBorders: true,
                    toolbarCanCollapse : true,
                    toolbarLocation: 'bottom'
                    /*
                    toolbar: [
                    { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [  'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
    { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo' ] },
    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
    { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
    '/',
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'CopyFormatting', 'RemoveFormat' ] },
    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
    { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
    { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar' ] },
    '/',
    { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
    { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
    { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
    { name: 'others', items: [ '-' ] }]
}}

/>
*/