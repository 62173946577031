import styled from 'styled-components';

const Wrapper = styled.div`
//background-color: #dae136;
color: white;
text-shadow: 2px 2px 2px #2e3336;
font-size: 18px;
font-weight: bold;
width: auto;
padding: 4px 4px 4px 4px;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
height: 36px;
max-height: 36px;
`;

export default Wrapper;
