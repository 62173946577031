import styled from 'styled-components';

const ImageWrapper = styled.div`
    width: 50%;    
    height: 50%;        
    
    background-image: url("${props => props.src}");    
    background-size: contain;
    background-repeat: no-repeat;        
    background-position: center center;        
    //position:absolute;     
    //float: center;
    //bottom:0;
    //right:0;       

        display: block;
        position: fixed;
        top: 0pt;
        left: 0pt;
        right: 0pt;        
        
  //      text-align: right;
  //*/
  @media print {
  body {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
    }
  }
`;

export default ImageWrapper;
