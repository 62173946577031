import React from "react";
import PageGridWrapper from "./PageGridWrapper";
import PageGridRow from "./PageGridRow";
import PageGridImage from "./PageGridImage";
import PageGridColumn from "./PageGridColumn";

function PageGrid(props) {
    //const { } = props;

    return (
        <PageGridWrapper>
            <PageGridRow>
                <PageGridColumn>
                    <PageGridImage>
                        <img alt="Number 1" src="https://picsum.photos/300/300" />
                    </PageGridImage>
                </PageGridColumn>
                <PageGridColumn>
                    <PageGridImage>
                        <img alt="Number 2" src="https://picsum.photos/300/300" />
                    </PageGridImage>
                </PageGridColumn>
            </PageGridRow>
            <PageGridRow>
                <PageGridColumn>
                    <PageGridImage>
                        <img alt="Number 3" src="https://picsum.photos/300/300" />
                    </PageGridImage>
                </PageGridColumn>
                <PageGridColumn>
                    <PageGridImage>
                        <img alt="Number 4" src="https://picsum.photos/300/300" />
                    </PageGridImage>
                </PageGridColumn>
            </PageGridRow>
        </PageGridWrapper>
    );
}


export default PageGrid;