export default {
  en: {
    a3overview: 'A3 Overview',
    currenta3: 'Current A3',
    title: 'Think A3',
    sidebarTitle: 'Think A3',
    description:
      'Think A3 App',
    dashboard: 'Dashboard',
    components: 'Components',
    dropdown: 'Dropdown',
    submenu: 'Submenu',
    multiLevel: 'Multi Level',
    collapsed: 'Collapsed',
    rtl: 'RTL',
    image: 'Background image',
    new: 'NEW',
    withPrefix: 'With Prefix',
    withSuffix: 'With Suffix',
    viewSource: 'View Source',
    todo: 'Todo',
  },
};
