import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Wrapper from './Wrapper';
import { selectCurrent } from '../../Selectors';

import FishboneChart from 'fishbone-chart'

const data = {
    'Bad coffee': {
        'Procedures': ['Too much water', 'Too many grounds', 'Lack of training'],
        'Equipment': ['Dirty cups', 'Coffee not hot enough', 'Dirty basket'],
        'Material': ['Bad sugar', 'Lids do not fit cup', 'Bad cream'],
        'People': ['Wrong fee', 'No training', 'Rude'],
        'Machine': ['Not working', 'Deregulated', 'Dirty']
    },
    'Scrum not working': {
        'Principles': ['Functional software (SW) is not released'],
        'Product Owner (PO)': ['No authority to prioritize', 'Poor interaction with the team'],
        'Sprint': ['SW not released for validation', 'Sprint speed is not measured', 'Team is controlled from outside'],
        'Planning': ['PO does not explain the backlog'],
        'Development Team': ['Members dedicated to specific roles', 'Does not deliver what was promised'],
    },
    'Security Incident': {
        'Technology': ['Weak encryption', 'No technology for remote data destruction'],
        'Process': ['No process for reporting incident'],
        'People': ['Worker lost laptop', 'Distraction was a factor'],
        'Controls': ['Week password policy', 'No audit trail of the laptop information'],
        'Procedure': ['No procedure for securing laptop at public locations'],
        'Environment': ['No place to secure laptop overnight at workplace']
    },
    'Security Incident2': {
        'Technology': ['Weak encryption', 'No technology for remote data destruction'],
        'Process': ['No process for reporting incident'],
        'People': ['Worker lost laptop', 'Distraction was a factor'],
        'Controls': ['Week password policy', 'No audit trail of the laptop information'],
        'Procedure': ['No procedure for securing laptop at public locations'],
        'Environment': ['No place to secure laptop overnight at workplace']
    }
}

const odata = {
    'Bad coffee': {
        'Equipment': ['Dirty cups', 'Coffee not hot enough', 'Dirty basket'],
        'Extra node': [],
        'Material': ['Bad sugar', 'Lids do not fit cup', 'Bad cream'],
        'People': ['Wrong fee', 'No training', 'Rude'],
        'Machine': ['Not working', 'Deregulated', 'Dirty']
    }
}

function Fishbone(props) {
    //Props
    //const { a3Id, screenWidth, screenHeight } = props;

    //Use
    const dispatch = useDispatch();
    const history = useHistory();

    //Selectors    
    const current = useSelector(selectCurrent);

    //Var & Const
    //let a3IdToDisplay = a3Id ? a3Id : current.a3Id

    //Handlers    
    //document.documentElement.style.setProperty("--myfontsize", current.fontSizeCKEditor)

    //Check
    /*
    console.log("A3V2 called");
    console.log("a3Id", a3Id);
    console.log("current.a3Id", current.a3Id);
    console.log("a3IdToDisplay", a3IdToDisplay);
    */

    return (
        <Wrapper>
            <FishboneChart data={data} />
        </Wrapper>
    );

}
export default Fishbone;
