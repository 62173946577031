import styled from 'styled-components';

const BackgroundContentImage = styled.div`    
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;    
    background-image: url('${props => props.src ? props.src : "./A3-Aleris.jpg"}');    
    //background-color: green;
    background-size: contain;
    background-repeat: no-repeat;    
    //opacity: 1;
`;

export default BackgroundContentImage;
